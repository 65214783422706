import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import {
    PopupSubTitle,
    PopupParagraph,
} from 'src/domains/players/state/accountState/DepositLimitsUpdatePopups/DepositLimitUpdatePopup/DepositLimitUpdatePopup.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useCommon } from 'src/domains/common/Common';
import {
    renderLimitApiMessage,
    TopUpLimitsProcedureItem,
    ButtonSubmit,
} from 'src/domains/players/webview/components/Account/limitsTab/rollingNetDepositLimit/RollingNetDepositLimit';
import { RollingNetDepositLimitState } from 'src/domains/players/webview/components/Account/limitsTab/rollingNetDepositLimit/RollingNetDepositLimit.state';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';

interface PropsType {
    configComponents: ConfigComponents;
}
export const UpdateLimitsComponent = observer(
    'UpdateLimitsComponent',
    ({ configComponents }: PropsType): JSX.Element | null => {
        const {
            appPlayersState: { usersState },
        } = useAppStateContext();
        const common = useCommon();
        const [updateState] = useState(
            () => new RollingNetDepositLimitState(common.trpcClient, configComponents, usersState)
        );

        return (
            <>
                <PopupSubTitle>Net Deposit Limit</PopupSubTitle>
                {updateState.rollingNetDepositLimitStatus === 'loading' ? (
                    <PopupParagraph>
                        <I18n
                            langKey='account.contact-preferences.loading'
                            defaultText='Loading...'
                        />
                    </PopupParagraph>
                ) : updateState.rollingNetDepositLimitStatus === 'error' ? (
                    renderLimitApiMessage('error')
                ) : (
                    <form
                        onSubmit={(e): Promise<void> => {
                            return updateState.onSubmit(e);
                        }}
                    >
                        <TopUpLimitsProcedureItem
                            state={updateState.daily}
                            version='beta'
                            depositLimitTime='daily'
                        />
                        <TopUpLimitsProcedureItem
                            state={updateState.weekly}
                            version='beta'
                            depositLimitTime='weekly'
                        />
                        <TopUpLimitsProcedureItem
                            state={updateState.monthly}
                            version='beta'
                            depositLimitTime='monthly'
                        />
                        {renderLimitApiMessage(updateState.infoMessage)}
                        <ButtonSubmit
                            type='submit'
                            size='medium'
                            disabled={updateState.isDisabledButton}
                            dataTest='save-button'
                        >
                            <I18n
                                langKey='account.net-deposit-limits.save.label'
                                defaultText='Save'
                            />
                        </ButtonSubmit>
                    </form>
                )}
            </>
        );
    }
);
