import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { LayoutSignUp, SignUpState } from 'src/domains/players/webview/components/SignUp';
import { RealexJourney } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/realexProvider/RealexJourney';
import {
    SkipButton,
    SkipButtonContainer,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/depositSignupPage/Deposit.style';
import { InfoPage } from 'src/domains/players/webview/components/WithdrawAndDeposit/components/temporaryInfo/InfoPage';
import { PaysafeJourney } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/paysafeProvider/PaysafeJourney';
import { OperatorTurnOffDeposits } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/depositFailure/OperatorTurnOffDeposits';

interface DepositSignupLayoutPropsType {
    children: React.ReactElement;
    skipAction: () => void;
    signUpState: SignUpState;
}

export const DepositSignupLayout = observer(
    'DepositSignupLayout',
    ({ children, skipAction, signUpState }: DepositSignupLayoutPropsType): JSX.Element => {
        const { appLayoutsState } = useAppStateContext();
        const { googleTagManager } = appLayoutsState;

        const handleSkipAction = (): void => {
            skipAction();
            googleTagManager.gtmSignUpStepFour(0);
        };

        return (
            <LayoutSignUp
                stepsCounter={true}
                footer={true}
                signUpState={signUpState}
            >
                <>
                    {children}
                    <SkipButtonContainer>
                        <SkipButton
                            version='secondary-dark'
                            size='medium'
                            onClick={handleSkipAction}
                            dataTest='skip-button'
                        >
                            <I18n
                                langKey='signup-journey.deposit.skip-button'
                                defaultText='Skip'
                            />
                        </SkipButton>
                    </SkipButtonContainer>
                </>
            </LayoutSignUp>
        );
    }
);

interface PropsType {
    signUpState: SignUpState;
}

export const DepositSignupProvider = observer('DepositSignupProvider', ({ signUpState }: PropsType): JSX.Element => {
    const {
        config: {
            usePaymentFormForUniverse,
            temporaryDepositPage,
            accountHelperMail,
            depositsTurnOffForLeavingOperator,
        },
    } = useAppStateContext();

    if (depositsTurnOffForLeavingOperator.enabled) {
        return (
            <OperatorTurnOffDeposits
                operatorName={depositsTurnOffForLeavingOperator.operatorName}
                isSignup={true}
                date={depositsTurnOffForLeavingOperator.date}
            />
        );
    }

    if (temporaryDepositPage) {
        return (
            <DepositSignupLayout
                skipAction={signUpState.toggleAside}
                signUpState={signUpState}
            >
                <InfoPage
                    infoPageType='deposit'
                    accountHelperMail={accountHelperMail}
                />
            </DepositSignupLayout>
        );
    }

    switch (usePaymentFormForUniverse.type) {
        case 'realex':
            return (
                <DepositSignupLayout
                    skipAction={signUpState.toggleAside}
                    signUpState={signUpState}
                >
                    <RealexJourney isSignup={true} />
                </DepositSignupLayout>
            );
        case 'paysafe':
            return (
                <DepositSignupLayout
                    skipAction={signUpState.toggleAside}
                    signUpState={signUpState}
                >
                    <PaysafeJourney
                        isSignup={true}
                        paysafePaymentProvider={usePaymentFormForUniverse}
                    />
                </DepositSignupLayout>
            );
    }
});
