import { RaceSummaryCaption, RunnerCell, RunnerCaption, SelectionCaption, RaceSummaryRow, PlaceCaption, SilkCaption } from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/RaceSummary.style';
import { SelectionCast } from 'src/domains/sportsbook/webview/components/selectionCast/SelectionCast';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const EachWayExtraRaceSummaryCaption = withConfig(theme => styled(RaceSummaryCaption, { label: 'EachWayExtraRaceSummaryCaption' })`
    height: 32px;
    border: 0;
    border-bottom: 0;
    border-left: 1px solid ${theme.star.selection.borderColor};
    width: 100%;
`);

export const EachWayExtraRunnerCell = styled(RunnerCell, { label: 'EachWayExtraRunnerCell' })`
    border-right: 0;
`;

export const EachWayExtraRunnerCaption = withConfig(theme => styled(RunnerCaption, { label: 'EachWayExtraRunnerCaption' })`
    border-right: 0;
    border-bottom: 1px solid ${theme.star.raceSummary.borderColor};
`);

export const EachWayExtraPlaceCaption = withConfig(theme => styled(PlaceCaption, { label: 'EachWayExtraPlaceCaption' })`
    border-bottom: 1px solid ${theme.star.raceSummary.borderColor};
`);

export const EachWayExtraSilkCaption = withConfig(theme => styled(SilkCaption, { label: 'EachWayExtraSilkCaption' })`
    border-bottom: 1px solid ${theme.star.raceSummary.borderColor};
`);

export const EachWayExtraSelectionCaption = withConfig(theme => styled(SelectionCaption, { label: 'EachWayExtraSelectionCaption' })`
    flex: 0 0 64px;
    border-bottom: 1px solid ${theme.star.raceSummary.borderColor};
`);

export const EachWayExtraSelectionCast = withConfig(theme => styled(SelectionCast, { label: 'EachWayExtraSelectionCast' })`
    flex: 0 0 64px;
    border-bottom: 1px solid ${theme.star.raceSummary.borderColor};
`);

export const EachWayExtraRaceSummaryRowHeader = styled(RaceSummaryRow, { label: 'EachWayExtraRaceSummaryRowHeader' })`
    border-right: 0;
`;
