import { Amount } from 'src_common/common/amount/Amount';
import { computed, makeObservable } from 'mobx';
import { MobxValue } from 'src_common/common/mobx-utils/MobxValue';
import { OpenapiProxyCustomerDepositLimitsResponse200Type } from 'src/api_openapi/generated/openapi_proxy_customer_deposit_limits';
import { AppState } from 'src/appState/AppState';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { DateTime } from 'src_common/utils/time/time';
import { TrpcClient } from 'src/appState/TrpcClient';

export type DepositLimitType = NonNullable<OpenapiProxyCustomerDepositLimitsResponse200Type['daily']>;

class CurrentTime {
    public connect(value: MobxValue<DateTime>): NodeJS.Timeout {
        return setInterval(() => {
            value.setValue(DateTime.current());
        }, 1000);
    }

    public dispose(interval: NodeJS.Timeout): void {
        clearInterval(interval);
    }

    public static create(): MobxValue<DateTime> {
        return MobxValue.create({
            initValue: DateTime.current(),
            connect: new CurrentTime(),
        });
    }
}

export class DepositLimitsPopupItemState {
    public constructor(
        private readonly period: 'daily' | 'weekly' | 'monthly',
        private readonly currentTime: MobxValue<DateTime>,
        private readonly configComponents: ConfigComponents,
        private readonly trpcClient: TrpcClient,
        public readonly getLimits: () => DepositLimitType | undefined | null
    ) {
        makeObservable(this);
    }

    @computed public get active(): Amount | undefined {
        const activeOldValue = this.getLimits()?.active?.amount;

        if (activeOldValue === undefined) {
            return activeOldValue;
        }

        return this.configComponents.precision.newFromOld(activeOldValue);
    }

    @computed public get returnActiveState(): FormInputState<string, string> {
        const pendingLimitOldFormat = this.getLimits()?.pending?.amount;
        const activeLimitOldFormat = this.getLimits()?.active?.amount;
        const currLimitOldFormat = pendingLimitOldFormat === undefined ? activeLimitOldFormat : pendingLimitOldFormat;

        const currLimit =
            currLimitOldFormat === undefined
                ? undefined
                : this.configComponents.precision.newFromOld(currLimitOldFormat);
        const state = FormInputState.new(currLimit?.value ?? '');
        return state;
    }

    @computed public get pending(): Amount | undefined {
        const pendingOldValue = this.getLimits()?.pending?.amount;

        if (pendingOldValue === undefined) {
            return pendingOldValue;
        }

        return this.configComponents.precision.newFromOld(pendingOldValue);
    }

    @computed public get limitExceededDeadline(): boolean {
        const lastUpdateDate = this.getLimits()?.pending?.lastUpdateDate;
        if (lastUpdateDate === undefined) {
            return false;
        }

        const current = this.currentTime.getValue();
        const lastUpdate = DateTime.from(lastUpdateDate)?.addDays(1);
        if (lastUpdate === undefined) {
            return false;
        }
        if (current.isAfter(lastUpdate)) {
            return true;
        }

        return false;
    }

    public async amend(): Promise<void> {
        if (this.limitExceededDeadline === false) {
            return;
        }

        const amountFinal = this.active;

        if (amountFinal === undefined) {
            console.error(`limits -> amend -> ${this.period} -> expected amount`);
            return;
        }

        await this.trpcClient.client.accounts.changeDepositLimits.mutate({
            period: this.period,
            body: {
                active: {
                    amount: this.configComponents.precision.valueOldFormat(amountFinal),
                },
            },
        });
    }

    public async confirm(): Promise<void> {
        if (this.limitExceededDeadline === false) {
            return;
        }

        const amountFinal = this.pending;
        if (amountFinal === undefined) {
            console.error(`limits -> confirm -> ${this.period} -> expected amount`);
            return;
        }

        await this.trpcClient.client.accounts.changeDepositLimits.mutate({
            period: this.period,
            body: {
                active: {
                    amount: this.configComponents.precision.valueOldFormat(amountFinal),
                },
                pending: null,
            },
        });
    }
}

export class DepositLimitsPopupState {
    public readonly daily: DepositLimitsPopupItemState;
    public readonly weekly: DepositLimitsPopupItemState;
    public readonly monthly: DepositLimitsPopupItemState;
    private readonly configComponents: ConfigComponents;

    public constructor(private readonly appState: AppState) {
        makeObservable(this);
        const currentTime = CurrentTime.create();
        this.configComponents = ConfigComponents.get(appState.common);
        const getDepositLimitsData = (): OpenapiProxyCustomerDepositLimitsResponse200Type | null => {
            return this.appState.appPlayersState.usersState.depositLimitsDataOld.valueReady;
        };

        this.daily = new DepositLimitsPopupItemState(
            'daily',
            currentTime,
            this.configComponents,
            this.appState.common.trpcClient,
            () => getDepositLimitsData()?.daily
        );

        this.weekly = new DepositLimitsPopupItemState(
            'weekly',
            currentTime,
            this.configComponents,
            this.appState.common.trpcClient,
            () => getDepositLimitsData()?.weekly
        );

        this.monthly = new DepositLimitsPopupItemState(
            'monthly',
            currentTime,
            this.appState.appLayoutsState.configComponents,
            this.appState.common.trpcClient,
            () => getDepositLimitsData()?.monthly
        );
    }

    @computed public get isActive(): boolean {
        return (
            this.daily.limitExceededDeadline || this.weekly.limitExceededDeadline || this.monthly.limitExceededDeadline
        );
    }

    private async refreshLimits(): Promise<void> {
        if (this.configComponents.config.rollingNetDepositLimit === true) {
            await this.appState.appPlayersState.usersState.depositLimitsData.refreshAndWait();
        } else {
            await this.appState.appPlayersState.usersState.depositLimitsDataOld.refreshAndWait();
        }
    }

    public onAmend = async (): Promise<void> => {
        try {
            await this.daily.amend();
            await this.weekly.amend();
            await this.monthly.amend();
            await this.refreshLimits();
        } catch (err) {
            console.error(err);
            await this.refreshLimits();
        }
    };

    public onConfirm = async (): Promise<void> => {
        try {
            await this.daily.confirm();
            await this.weekly.confirm();
            await this.monthly.confirm();
            await this.refreshLimits();
        } catch (err) {
            await this.refreshLimits();
        }
    };
}
