import { css, SerializedStyles } from '@emotion/react';

import { FiltersSkinType } from 'src/domains/layouts/webview/components/filters/FiltersSkin';

import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';

import styled from '@emotion/styled';



const controlWidth = 125;



const setColor = (theme: EmotionTheme, isActive: boolean, filterVersion: 'primary' | 'secondary' | 'tertiary' ): SerializedStyles => {

    if (filterVersion === 'primary') {

        if (isActive) {

            return css`

                border-color: ${theme.star.filtersColors.primary.borderColor};

                &.custom-dropdown-select .react-select__control {

                    background: ${theme.star.filtersColors.primary.bgColor};

                    color: ${theme.star.filtersColors.primary.txtColorSecondary};

                }

                &.custom-dropdown-select .react-select__placeholder,

                &.custom-dropdown-select .react-select__option {

                    color: ${theme.star.filtersColors.primary.txtColor};

                }

            `;

        }

        return css`

            border-color: ${theme.star.filtersColors.primary.borderColorSecondary};

            &.custom-dropdown-select .react-select__control {

                color: ${theme.star.filtersColors.primary.txtColor};

                background-color: transparent;

            }

            &.custom-dropdown-select .react-select__placeholder,

            &.custom-dropdown-select .react-select__option {

                color: ${theme.star.filtersColors.primary.txtColor};

            }

        `;

    }



    if (filterVersion === 'secondary') {

        if (isActive) {

            return css`

                border-color: ${theme.star.filtersColors.secondary.borderColor};

                &.custom-dropdown-select .react-select__control {

                    background: ${theme.star.filtersColors.secondary.bgColor};

                    color: ${ theme.star.filtersColors.secondary.txtColorSecondary};

                }

                &.custom-dropdown-select .react-select__placeholder,

                &.custom-dropdown-select .react-select__option {

                    color: ${theme.star.filtersColors.secondary.txtColor};

                }

            `;

        }

        return css`

            border-color: ${theme.star.filtersColors.secondary.borderColor};

            &.custom-dropdown-select .react-select__control {

                color: ${theme.star.filtersColors.secondary.txtColor};

                background-color: transparent;

            }

            &.custom-dropdown-select .react-select__placeholder,

            &.custom-dropdown-select .react-select__option {

                color: ${theme.star.filtersColors.secondary.txtColor};

            }

        `;

    }



    if (isActive) {

        return css`

            border-color: ${theme.star.filtersColors.tertiary.borderColor};

            &.custom-dropdown-select .react-select__control {

                background: ${theme.star.filtersColors.tertiary.bgColor};

                color: ${ theme.star.filtersColors.tertiary.txtColorSecondary};

            }

            &.custom-dropdown-select .react-select__placeholder,

            &.custom-dropdown-select .react-select__option {

                color: ${ theme.star.filtersColors.tertiary.txtColor};

            }

        `;

    }

    return css`

        border-color: ${theme.star.filtersColors.tertiary.borderColor};

        &.custom-dropdown-select .react-select__control {

            color: ${theme.star.filtersColors.tertiary.txtColor};

            background-color: transparent;

        }

        &.custom-dropdown-select .react-select__placeholder,

        &.custom-dropdown-select .react-select__option {

            color: ${ theme.star.filtersColors.tertiary.txtColor};

        }

    `;

};



export const customFilterDropdown = (theme: EmotionTheme, isActive: boolean, filterVersion: FiltersSkinType): SerializedStyles => css`

    ${setColor(theme, isActive, filterVersion)};

    &.custom-dropdown-select {

        margin-right: 4px;

        position: relative;

        width: ${controlWidth}px;

        flex: 0 0 ${controlWidth}px;



        &:not(:only-of-type) {

            margin-bottom: 4px;

        }

        &:last-of-type {

            margin-bottom: 0;

        }

        @media ${theme.star.mediaQuery.tablet} {

            display: inline-block;



            &:not(:only-of-type) {

                margin-bottom: 0;

            }

        }

    }



    &.custom-dropdown-select .react-select__placeholder {

        background-color: transparent;

    }



    &.custom-dropdown-select .react-select__control {

        cursor: pointer;

        border: 0;

        border-radius: 0;

        font-size: ${theme.star.fontSize.small};

        font-weight: ${theme.star.fontWeight.medium};

        min-height: initial;

        height: 100%;

        line-height: 1.3333;

        position: relative;

    }



    &.custom-dropdown-select .react-select__control--is-focused {

        box-shadow: none;

    }



    &.custom-dropdown-select .react-select__indicator-separator {

        display: none;

        margin: 0;

    }



    &.custom-dropdown-select .react-select__menu {

        background-color: ${theme.star.filtersColors.dropdown.bgColor};

        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.32);

        margin-top: 1px;

        right: -1px;

        width: fit-content;

    }



    // scrollable element

    &.custom-dropdown-select .react-select__menu-list {

        max-height: 160px;

        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {

            background-color: rgba(0, 0, 0, .1);

            width: 4px;

        }

        &::-webkit-scrollbar-thumb {

            background-color: rgba(255, 255, 255, 0.2);

            border-radius: 4px;

        }

        scrollbar-color: rgba(255, 255, 255, 0.2) rgba(0, 0, 0, 0.1);

        scrollbar-width: thin;

    }



    &.custom-dropdown-select .react-select__option,

    &.custom-dropdown-select .react-select__menu-notice--no-options {

        cursor: pointer;

        font-size: ${theme.star.fontSize.xSmall};

        height: 32px;

        line-height: 1.4;

        overflow: hidden;

        padding: 9px 9px 9px 12px;

        position: relative;

        text-overflow: ellipsis;

        white-space: nowrap;

    }



    &.custom-dropdown-select .react-select__option:active {

        background-color: transparent;

    }



    &.custom-dropdown-select .react-select__option--is-selected,

    &.custom-dropdown-select .react-select__option--is-focused  {

        color: ${theme.star.filtersColors.dropdown.txtColor};

        background-color: ${theme.star.filtersColors.dropdown.bgColorSecondary};

    }



    &.custom-dropdown-select .react-select__option--is-selected {

        display: flex;

        flex-flow: row nowrap;

        justify-content: space-between;

    }



    &.custom-dropdown-select .react-select__option::before {

        background-color: ${theme.star.filtersColors.dropdown.bgColorSecondary};

        bottom: 0;

        content: '';

        left: 0;

        opacity: 0;

        position: absolute;

        right: 0;

        top: 0;

        transition: opacity .2s ease;

        z-index: ${theme.star.zIndexGlobal.below};

    }



    &.custom-dropdown-select .react-select__option--is-focused::before {

        opacity: 1;

    }

`;



interface DropdownIndicatorPropsType {

    isOpened: boolean;

}



export const DropdownIndicator = styled('div', { label: 'DropdownIndicator' })<DropdownIndicatorPropsType>`

    border-color: currentcolor transparent transparent transparent;

    border-style: solid;

    border-width: 4px 4px 0 4px;

    margin: 0 12px 0 0;

    display: block;

    height: 0;

    width: 0;

    transition: transform .2s ease;

    transform: ${({ isOpened }): string => isOpened ? 'scaleY(-1)' : 'scaleY(1)'};

`;

