import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';
import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import { InfoIcon } from 'src/domains/layouts/shared/assets/icons/InfoIcon';

export const RollingDepositLimitForm = styled('form', { label: 'RollingDepositLimitForm' })`
    margin: 0;
`;

export const TextSection = withConfig(theme => styled('p', { label: 'TextSection' })`
    font-size: ${theme.star.fontSize.small};
    line-height: 1.33;
    margin: 8px 0 0 0;
`);

interface SectionHeaderType {
    isUppercase: boolean;
}

export const SectionHeader = withConfig(theme => styled('h4', { label: 'SectionHeader' }) <SectionHeaderType>`
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.bold};
    margin: 26px 0 0 0;
    text-transform: ${({ isUppercase }): string => isUppercase ? 'uppercase' : 'none'};
`);

export const SectionHeadline = styled('h4', { label: 'SectionHeadline' })`
    display: inline-block;
    margin: 0 5px 0 0;
`;

export const FaqLink = withConfig(theme => styled(Link, { label: 'FaqLink' })`
    display: inline;
    color: ${theme.star.signUp.txtColorSecondary};
    font-weight: bold;
    text-decoration: none;
`);

export const InfoIconWrapper = withConfig(theme => styled(InfoIcon, { label: 'InfoIconWrapper' })`
    position: relative;
    width: 16px;
    top: 3px;
    fill: ${theme.star.signUp.bgColorQuaternary};
    cursor: pointer;
`);
