import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { DepositLimitUpdatePopupState } from './DepositLimitUpdatePopup.state';
import {
    DepositLimitUpdateContainer,
    DepositLimitUpdateOverlay,
    PopupTitle,
    PopupParagraph,
    PopupParagraphSmall,
    BoldSpan,
    BoxWrapper,
    BoxTitle,
    LinkWrapper,
    SubmitButton,
} from './DepositLimitUpdatePopup.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useCommon } from 'src/domains/common/Common';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { PopupView } from 'src/domains/players/state/accountState/DepositLimitsUpdatePopups/DepositLimitUpdatePopupWithInput/PopupView/PopupView';

export const DepositLimitUpdatePopup = observer('DepositLimitUpdatePopup', (): JSX.Element | null => {
    const {
        appPlayersState: { usersState },
    } = useAppStateContext();
    const common = useCommon();
    const configComponents = ConfigComponents.get(common);
    const {
        config: { rollingNetDepositLimitFirstTimePopupType, accountHelperMail },
    } = configComponents;

    const [state] = useState(() => new DepositLimitUpdatePopupState(common));
    if (state.showDepositLimitPopup && usersState.isSrgModalOpen) {
        if (rollingNetDepositLimitFirstTimePopupType === 'secondary') {
            return (
                <DepositLimitUpdateOverlay>
                    <DepositLimitUpdateContainer>
                        <PopupTitle>Deposit Limit Update</PopupTitle>
                        <PopupParagraph>Hi {state.userBasicData?.firstName},</PopupParagraph>
                        <PopupParagraph>
                            We’re just letting you know that we’ve updated the way our deposit limits work.
                        </PopupParagraph>
                        <PopupParagraph>
                            From now on, your chosen deposit limit will be a
                            <BoldSpan> Rolling Net Deposit Limits</BoldSpan>. This means you’ll be able to deposit your
                            set amount, plus any withdrawals you’ve made, over a rolling period (instead of a fixed
                            calendar period).
                        </PopupParagraph>
                        <BoxWrapper>
                            <BoxTitle>Example</BoxTitle>
                            <PopupParagraphSmall>You set a deposit limit of £100 per week.</PopupParagraphSmall>
                            <PopupParagraphSmall>
                                You deposit £60 which leaves you £40 to deposit for the next 7 days.
                            </PopupParagraphSmall>
                            <PopupParagraphSmall>
                                If you withdraw £10, you’ll now be able to deposit £50 in the next 7 days.
                            </PopupParagraphSmall>
                        </BoxWrapper>
                        <PopupParagraph>
                            The good news is that you don’t need to do anything. Any limits you’ve already set will stay
                            at the same amount. If you haven’t set a deposit limit yet, you can do so using the
                            Responsible Gambling section of your account.
                        </PopupParagraph>
                        <PopupParagraph>
                            If you have any questions, please visit our FAQ page or drop our team an email at{' '}
                            <LinkWrapper to={`mailto:${accountHelperMail}`}>
                                <I18n
                                    langKey='account.deposit.info-page.email'
                                    defaultText=' {accountHelperMail}'
                                    params={{ accountHelperMail }}
                                />
                            </LinkWrapper>
                            .
                        </PopupParagraph>
                        <PopupParagraph>Thanks for reading!</PopupParagraph>

                        <SubmitButton
                            size='large'
                            onClick={state.onSubmitRequest}
                            isLoading={state.isLoading}
                            disabled={state.isLoading}
                        >
                            <I18n
                                langKey='deposit-limit-update.button.submit'
                                defaultText='Continue'
                            />
                        </SubmitButton>
                    </DepositLimitUpdateContainer>
                </DepositLimitUpdateOverlay>
            );
        } else if (rollingNetDepositLimitFirstTimePopupType === 'tertiary') {
            return <PopupView />;
        } else {
            return (
                <DepositLimitUpdateOverlay>
                    <DepositLimitUpdateContainer>
                        <PopupTitle>Deposit Limit Update</PopupTitle>
                        <PopupParagraph>Hi {state.userBasicData?.firstName},</PopupParagraph>
                        <PopupParagraph>
                            We're updating our deposit limits to help you stay in control of your gambling.
                        </PopupParagraph>
                        <PopupParagraph>
                            Moving forward, your chosen Deposit Limits will be calculated using
                            <BoldSpan> Rolling Net Deposit Limits</BoldSpan>. This means you'll be able to deposit your
                            set amount plus any withdrawals you've made, and it will be calculated over rolling periods
                            instead of calendar-based periods.
                        </PopupParagraph>
                        <BoxWrapper>
                            <BoxTitle>Example</BoxTitle>
                            <PopupParagraphSmall>Set deposit limit: £100 a week</PopupParagraphSmall>
                            <PopupParagraphSmall>
                                Deposit £60 → £40 remaining of your deposit limit.
                            </PopupParagraphSmall>
                            <PopupParagraphSmall>
                                Withdraw £10 → £50 remaining of your deposit limit.
                            </PopupParagraphSmall>
                        </BoxWrapper>
                        <PopupParagraph>
                            No action is needed from you. Your current limit amount stays the same. If you haven’t yet
                            set a Deposit Limit, we advise you to consider this option.
                        </PopupParagraph>
                        <PopupParagraph>
                            For more information, please visit our FAQ and Responsible Gambling pages.
                        </PopupParagraph>

                        <SubmitButton
                            size='large'
                            onClick={state.onSubmitRequest}
                            isLoading={state.isLoading}
                            disabled={state.isLoading}
                        >
                            <I18n
                                langKey='deposit-limit-update.button.submit'
                                defaultText='Continue'
                            />
                        </SubmitButton>
                    </DepositLimitUpdateContainer>
                </DepositLimitUpdateOverlay>
            );
        }
    }

    return null;
});
