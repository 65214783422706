import { SerializedStyles, css } from '@emotion/react';
import styled from '@emotion/styled';
import { fadeIn } from 'src/domains/layouts/shared/Animation.style';
import { CheckSingleIcon } from 'src/domains/layouts/shared/assets/icons/CheckSingleIcon';
import { InfoIcon } from 'src/domains/layouts/shared/assets/icons/InfoIcon';
import { WarningIcon } from 'src/domains/layouts/shared/assets/icons/WarningIcon';
import { EmotionTheme } from 'src/domains/layouts/state/environmentState/EnvironmentState';
import { withConfig } from 'src/withConfig';

const setMessageContainer = (type: 'error' | 'info' | 'success', theme: EmotionTheme): SerializedStyles => {
    if (type === 'info') {
        return css`
            background: ${theme.star.statuses.info.bgColor};
            color: ${theme.star.statuses.info.txtColor};
        `;
    }

    if (type === 'success') {
        return css`
            background: ${theme.star.statuses.success.bgColor};
            color: ${theme.star.statuses.success.txtColor};
        `;
    }

    return css`
        background: ${theme.star.statuses.error.bgColor};
        color: ${theme.star.statuses.error.txtColor};
    `;
};

interface MessagesPropsType {
    hasIcon?: boolean;
    marginBottom?: string;
    marginTop?: string;
    type: 'error' | 'info' | 'success';
}

export const MessageWrapper = withConfig(theme => styled('div', { label: 'MessageWrapper' })<MessagesPropsType>`
    animation: ${fadeIn} .2s ease forwards;
    border-radius: 0;
    display: flex;
    flex-flow: row nowrap;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.regular};
    line-height: 1.3334;
    margin-bottom: ${({ marginBottom }): string => marginBottom === undefined ? '0' : marginBottom};
    margin-top: ${({ marginTop }): string => marginTop === undefined ? '0' : marginTop};
    padding: 4px 8px;
    position: relative;
    text-align: left;
    ${({ type }): SerializedStyles => setMessageContainer(type, theme)};
`);

export const MessageContent = styled('div', { label: 'MessageContent' })`
    display: flex;
    flex-flow: column nowrap;
    margin: 0;
`;

const setIcon = css`
    align-self: center;
    fill: currentcolor;
    flex: 0 0 16px;
    height: 16px;
    margin-right: 8px;
    width: 16px;
`;

export const InfoIconWrapper = styled(InfoIcon, { label: 'InfoIconWrapper' })`
    ${setIcon};
`;

export const WarningIconWrapper = styled(WarningIcon, { label: 'WarningIconWrapper' })`
    ${setIcon};
`;

export const SuccessIconWrapper = styled(CheckSingleIcon, { label: 'SuccessIconWrapper' })`
    ${setIcon};
`;
