import { css, SerializedStyles } from '@emotion/react';

import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';

import { baseDropdownStyle } from 'src/domains/layouts/webview/components/styles/baseDropdownStyle.style';



export const paymentDropDown = (theme: EmotionTheme): SerializedStyles => css`

    ${baseDropdownStyle()}



    &.custom-dropdown-select .react-select__control {

        font-size: ${theme.star.fontSize.xRegular};

        background: ${theme.star.form.light.bgColor};

        padding: 10px 12px;

        height: 40px;

    }



    &.custom-dropdown-select .react-select__control--is-focused {

        box-shadow: none;

        border: none;

    }



    &.custom-dropdown-select .react-select__control,

    &.custom-dropdown-select .react-select__option,

    &.custom-dropdown-select .react-select__placeholder {

        color: ${theme.star.form.txtColor};

        cursor: pointer;

    }



    &.custom-dropdown-select .react-select__option {

        border-top: none;

        font-size: ${theme.star.fontSize.xRegular};

        width: 100%;

        display: flex;

        justify-content: space-between;

        align-items: center;

        color: ${theme.star.paymentMethodDropdown.txtColor};

        padding: 8px 16px 8px 12px;

        background-color: ${theme.star.paymentMethodDropdown.bgColor};



        :hover {

            color: ${theme.star.paymentMethodDropdown.txtColorSecondary};

            background-color: ${theme.star.paymentMethodDropdown.bgColorSecondary};

        }

    }



    &.custom-dropdown-select .react-select__option--is-disabled {

        color: ${theme.star.paymentMethodDropdown.txtColorTertiary};

    }



    &.custom-dropdown-select .react-select__control--menu-is-open {

        background: ${theme.star.paymentMethodDropdown.bgColor};

        border: 2px solid ${theme.star.paymentMethodDropdown.bgColorSecondary};

        border-width: 2px 2px 0;

    }



    &.custom-dropdown-select .react-select__control--menu-is-open > .react-select__indicators {

        transform: rotate(180deg);

    }



    &.custom-dropdown-select .react-select__menu {

        border: 2px solid ${theme.star.paymentMethodDropdown.bgColorSecondary};

        border-width: 0 2px 2px;

    }



    &.custom-dropdown-select .react-select__option--is-selected,

    &.custom-dropdown-select .react-select__option--is-focused {

        background-color: ${theme.star.form.dark.bgColor};

    }

`;

