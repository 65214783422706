import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { SignUpButtons } from 'src/domains/layouts/webview/modules/layoutSkeleton/Header/quickHeader/SignupButtons';
import { BetsButton } from 'src/domains/layouts/webview/modules/layoutSkeleton/Header/quickHeader/BetsButton';
import { ChatLink } from 'src/domains/layouts/webview/modules/layoutSkeleton/Header/quickHeader/ChatButton';
import { LoginButton } from 'src/domains/layouts/webview/modules/layoutSkeleton/Header/quickHeader/LoginButton';
import { RewardsLink } from 'src/domains/layouts/webview/modules/layoutSkeleton/Header/quickHeader/RewardsButton';
import { QuickHeaderWrapper } from './QuickHeader.style';
import { useAppStateContext } from 'src/appState/AppState';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { UsersState } from 'src/domains/players/state/UsersState';
import { ConfigType } from 'src/domains/layouts/config/features/types';
import { computed, makeObservable } from 'mobx';
import { RouteViewType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { RightHandSideViewType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';
import { MobxMapAutoNew } from 'src_common/common/mobx-utils/MobxMapAutoNew';

class QuickHeaderState {
    private config: ConfigType;

    public constructor(
        private usersState: UsersState,
        private configComponents: ConfigComponents,
        private isAnyCasino: boolean,
        private readonly currentView: RouteViewType,
        private readonly accountView: string | null
    ) {
        makeObservable(this);
        this.config = this.configComponents.config;
    }

    public renderQuickHeaderLinks = (): Array<React.ReactElement> => {
        const out: Array<React.ReactElement> = [];
        const isUserAuthorized = this.usersState.session.isAuthorized;
        if (isUserAuthorized === false) {
            out.push(<SignUpButtons key='signUpBtn' />);
        }

        if (
            // isUserAuthorized &&
            this.config.traderChat.isOn &&
            this.isChatEnabledforUser
        ) {
            out.push(<ChatLink key='chatLink' />);
        }

        out.push(<LoginButton key='login' />);
        if (this.isAnyCasino === false) {
            out.push(
                <BetsButton
                    key='bets'
                    accountView={this.accountView}
                    currentView={this.currentView}
                />
            );
        }

        return out;
    };

    @computed private get isChatEnabledforUser(): boolean {
        const basicData = this.usersState.basicData.get();
        return (basicData.type === 'ready' && basicData.value?.chatEnabled) ?? false;
    }
}
class State {
    private readonly quickHeaderStateMap: MobxMapAutoNew<string | null, QuickHeaderState>;

    public constructor(
        private usersState: UsersState,
        private configComponents: ConfigComponents,
        private isAnyCasino: boolean,
        private readonly currentView: RouteViewType
    ) {
        // makeObservable(this);

        this.quickHeaderStateMap = new MobxMapAutoNew(
            (accountType: string | null) =>
                new QuickHeaderState(
                    this.usersState,
                    this.configComponents,
                    this.isAnyCasino,
                    this.currentView,
                    accountType
                )
        );
    }

    public getQuickHeaderState = (accountType: string | null): QuickHeaderState => {
        return this.quickHeaderStateMap.get(accountType);
    };
}

interface PropsType {
    isAnyCasino: boolean;
    currentView: RouteViewType;
    accountView: RightHandSideViewType | null;
}

export const QuickHeader = observer('QuickHeader', ({ isAnyCasino, accountView, currentView }: PropsType) => {
    const { appLayoutsState, appPlayersState } = useAppStateContext();
    const { usersState } = appPlayersState;
    const { configComponents } = appLayoutsState;
    const { lifeSpanActive } = configComponents.config;

    const accountRouteType = accountView?.account ?? null;
    const [localState] = useState(new State(usersState, configComponents, isAnyCasino, currentView));
    const state = localState.getQuickHeaderState(accountRouteType);

    return (
        <QuickHeaderWrapper>
            {usersState.session.isAuthorized === true && lifeSpanActive === true ? <RewardsLink /> : null}
            {state.renderQuickHeaderLinks()}
        </QuickHeaderWrapper>
    );
});
