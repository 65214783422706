import { UniverseType, UniverseZod } from 'src_common/common/universe';
import { LangDetailsMapType, importLanguages } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { computed, makeAutoObservable } from 'mobx';
import { ConfigFeaturesType, ConfigFeaturesZod } from 'src/domains/common/configFeatures/ConfigFeatures';
import { z } from 'zod';

export const EnvZod = z.object({
    universe: UniverseZod,
    api_universe: z.union([UniverseZod, z.null()]),
    mode: z.union([z.literal('prod'), z.literal('dev')]),
    websocket_casino_host: z.string(),
    websocket_casino_rt_lobby_host: z.union([z.string(), z.null()]),
    websocket_casino_jackpots_host: z.union([z.string(), z.null()]),
    websocket_host: z.string(),
    gatewayApiHost: z.union([z.string(), z.null()]),
    placebetNewVersion: z.boolean(),
    websocket_api_host_newtrading: z.string(),
    websocket_host_v2_newtrading: z.string(),

    mobileWrapperType: z.union([z.string(), z.null()]),
    mobileWrapperVersion: z.union([z.string(), z.null()]),
    allTranslations: z.record(
        z.string(),
        z.object({
            description: z.string(),
            properties: z.record(z.string(), z.string()),
        })
    ),
    userLang: z.union([z.string(), z.null()]),
    sentry: z.union([z.string(), z.null()]),
    cacheApiCall: z.record(z.string(), z.string()),
    country: z.union([z.string(), z.null()]),
    intercomChatKey: z.union([z.string(), z.null()]),
    features: ConfigFeaturesZod,
});

export type EnvType = z.TypeOf<typeof EnvZod>;

export class EnvVariables {
    public constructor(private readonly env: EnvType) {
        makeAutoObservable(this);
    }

    public stringify(cacheApiCall: Record<string, string>): string {
        const env = { ...this.env, cacheApiCall };
        return JSON.stringify(env);
    }

    public get universe(): UniverseType {
        return this.env.universe;
    }

    public get apiUniverse(): UniverseType | null {
        return this.env.api_universe;
    }

    /**
     * @deprecated - Please do not use this getter. This getter can be used only in file src/domains/common/universes.tsx and nowhere else
     */
    public get isProdMode(): boolean {
        return this.env.mode === 'prod';
    }

    public get websocket_api_host(): string {
        return this.env.websocket_api_host_newtrading;
    }

    public get websocket_casino_host(): string {
        return this.env.websocket_casino_host;
    }

    public get websocket_casino_rt_lobby_host(): string | null {
        return this.env.websocket_casino_rt_lobby_host;
    }

    public get websocket_casino_jackpots_host(): string | null {
        return this.env.websocket_casino_jackpots_host;
    }

    public get websocket_host(): string {
        return this.env.websocket_host;
    }

    public get websocket_host_v2(): string {
        return this.env.websocket_host_v2_newtrading;
    }

    public get mobileWrapperType(): string | null | undefined {
        return this.env.mobileWrapperType;
    }

    public get mobileWrapperVersion(): string | null | undefined {
        return this.env.mobileWrapperVersion;
    }

    @computed public get allTranslations(): Map<string, LangDetailsMapType> {
        return importLanguages(this.env.allTranslations);
    }

    public get userLang(): string | null {
        return this.env.userLang;
    }

    public get sentry(): string | null {
        return this.env.sentry;
    }

    public get gatewayApiHost(): string | null {
        return this.env.gatewayApiHost;
    }

    public get isPlacebetNewVersion(): boolean {
        return this.env.gatewayApiHost !== null && this.env.placebetNewVersion;
    }

    public get intercomChatKey(): string | null {
        return this.env.intercomChatKey;
    }

    public get cacheApiCall(): Record<string, string> {
        return this.env.cacheApiCall;
    }

    public get features(): ConfigFeaturesType {
        return this.env.features;
    }

    public static createForStorybook(universe: UniverseType): EnvVariables {
        const defaultFeatures = ConfigFeaturesZod.parse({});

        return new EnvVariables({
            universe,
            api_universe: universe,
            mode: 'dev',
            websocket_casino_host: '',
            websocket_casino_rt_lobby_host: '',
            websocket_casino_jackpots_host: '',
            websocket_host: '',
            websocket_api_host_newtrading: '',
            websocket_host_v2_newtrading: '',
            mobileWrapperType: null,
            mobileWrapperVersion: null,
            allTranslations: {},
            userLang: null,
            sentry: null,
            cacheApiCall: {},
            country: null,
            gatewayApiHost: null,
            placebetNewVersion: false,
            intercomChatKey: '',
            features: defaultFeatures,
        });
    }

    public get country(): string | null {
        return this.env.country;
    }
}
