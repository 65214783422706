import { action, computed, observable, makeObservable } from 'mobx';
import { MobxMapAutoNew } from 'src_common/common/mobx-utils/MobxMapAutoNew';
import { SingleBetItemState } from 'src/domains/sportsbook/betting/betSlipState/betsState/SingleBetItemState';
import {
    AccountCashOutErrorModelType,
    CashOutDataModelType,
    CashOutItemType,
    CashOutMainDataModelType,
} from 'src/api/config/betting/cashOutsDataDecode';
import { CashoutDataState } from 'src/domains/sportsbook/betting/state/BetsState/CashoutDataState';
import { FilterType } from 'src/domains/layouts/webview/components/filters/Filters.state';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import {
    BetsListType,
    BetListType,
    BetLegType,
    BetsListState,
} from 'src/domains/sportsbook/betting/state/BetsListState';
import { OpenapiProxyCustomerAllBetsResponse200Type } from 'src/api_openapi/generated/openapi_proxy_customer_all_bets';
import { Common } from 'src/domains/common/Common';
import { BasicDataModel } from 'src/domains/players/state/BasicDataModel';

export interface BetsStateParamsType {
    getTranslation: (key: string, defaultText: string) => string;
    getOddsFormat: () => 'f' | 'd';
}

export enum BetsFilter {
    ALL = 'ALL',
    OPEN = 'OPEN',
    SETTLED = 'SETTLED',
    CASH_OUT = 'CASH_OUT',
}

export class BetsState {
    private readonly betsListState: BetsListState;
    public readonly allCashOutsData: CashoutDataState;
    public singleBetItem: MobxMapAutoNew<number, SingleBetItemState>;

    @observable public selectedFilter: string = BetsFilter.OPEN;

    public constructor(
        // public readonly sdkCustomer: SdkCustomer,
        private readonly configComponents: ConfigComponents,
        private readonly params: BetsStateParamsType,
        private readonly common: Common
    ) {
        makeObservable(this);

        this.betsListState = BetsListState.get(common);
        this.allCashOutsData = CashoutDataState.get(this.common);

        this.singleBetItem = new MobxMapAutoNew((betId: number) => {
            return new SingleBetItemState(
                this.configComponents,
                this.common,
                () => this.getBetById(betId),
                () => this.getCashOutById(betId)
            );
        });
    }

    @computed public get openBetsCount(): number {
        return this.betsList.filter((elem) => elem.status === 'open' || elem.status === 'parked').length;
    }

    @computed public get getLabelForOpenTab(): string {
        const count = this.betsListOpen.slice(0, 250).length;
        const baseName = this.params.getTranslation('betslip.tabs.open', 'Open');

        if (count > 0) {
            return `${baseName} (${count})`;
        }

        return baseName;
    }

    @computed public get filters(): FilterType[] {
        const basicData = BasicDataModel.get(this.common).basicData;
        const { getTranslation } = this.params;
        const { hasCashoutEnabled } = this.configComponents.config;
        const hasUserCashoutEnabled = basicData.valueReady?.cashoutEnabled ?? false;
        const isCashoutEnabled = hasCashoutEnabled && hasUserCashoutEnabled;

        return [
            {
                id: BetsFilter.ALL,
                key: BetsFilter.ALL,
                label: getTranslation('betslip.tabs.all', 'All'),
            },
            {
                id: BetsFilter.OPEN,
                key: BetsFilter.OPEN,
                label: this.getLabelForOpenTab,
            },
            ...(isCashoutEnabled
                ? [
                      {
                          id: BetsFilter.CASH_OUT,
                          key: BetsFilter.CASH_OUT,
                          label: getTranslation('betslip.tabs.cash-out', 'Cash Out'),
                      },
                  ]
                : []),
            {
                id: BetsFilter.SETTLED,
                key: BetsFilter.SETTLED,
                label: getTranslation('betslip.tabs.settled', 'Settled'),
            },
        ];
    }

    public getBetItemById = (id: number): SingleBetItemState => {
        return this.singleBetItem.get(id);
    };

    @computed public get filteredBySelectedFilter(): BetsListType {
        if (this.selectedFilter === BetsFilter.OPEN) {
            return this.betsListOpen;
        }

        if (this.selectedFilter === BetsFilter.SETTLED) {
            return this.betsList.filter((elem) => elem.status === 'settled');
        }

        if (this.selectedFilter === BetsFilter.CASH_OUT) {
            const cashOutsListValues: BetsListType = [];
            const cashOutsList = this.cashOutsList?.cashouts ?? null;
            if (cashOutsList !== null) {
                for (const bet of this.betsList) {
                    const cashOut = this.getCashOutById(bet.id);
                    //@ts-expect-error
                    if (cashOut !== null && cashOut.enabled && cashOut.value > 0) {
                        cashOutsListValues.push(bet);
                        continue;
                    }

                    if (this.getBetItemById(bet.id).isShowSuccessCashOut) {
                        cashOutsListValues.push(bet);
                        continue;
                    }
                }
            }

            return cashOutsListValues;
        }

        return this.betsList;
    }

    @computed public get betsListForView(): Array<number> {
        const filteredList = this.filteredBySelectedFilter;
        return filteredList.map((elem) => elem.id).slice(0, 250);
    }

    @action public changeSelectedFilter = (filterName: string | null): void => {
        this.selectedFilter = filterName ?? BetsFilter.ALL;
    };

    @computed public get allBetsResource(): OpenapiProxyCustomerAllBetsResponse200Type | null {
        if (this.common.session.userId !== null) {
            return this.betsListState.list;
        }
        return null;
    }

    @computed public get betsList(): BetsListType {
        if (this.allBetsResource !== null) {
            return this.allBetsResource.results;
        }
        return [];
    }

    @computed public get betsListOpen(): BetsListType {
        if (this.common.session.userId === null) {
            return [];
        }

        return this.betsListState.listOpen?.results ?? [];
    }

    @computed public get betsListMap(): Map<number, BetListType> {
        const betsListMap: Map<number, BetListType> = new Map();

        for (const bet of [...this.betsList, ...this.betsListOpen]) {
            betsListMap.set(bet.id, bet);
        }

        return betsListMap;
    }

    public getBetById = (id: number): BetListType | null => {
        const bet = this.betsListMap.get(id);
        return bet ?? null;
    };

    @computed public get allCashOutsResource(): CashOutMainDataModelType | AccountCashOutErrorModelType | null {
        const basicData = BasicDataModel.get(this.common).basicData;
        if (basicData.valueReady !== null && basicData.valueReady.cashoutEnabled === true) {
            return this.allCashOutsData.get();
        }
        return null;
    }

    @computed public get cashOutsList(): CashOutDataModelType | null {
        const allCashOutsResource = this.allCashOutsResource;
        if (allCashOutsResource === null) {
            return null;
        }

        if (allCashOutsResource.type === 'success') {
            return allCashOutsResource.data;
        }

        return null;
    }

    public getCashOutById = (id: number): CashOutItemType | null => {
        const cashOuts = this.cashOutsList?.cashouts ?? null;
        if (cashOuts !== null) {
            const cashOut = cashOuts[id];
            return cashOut ?? null;
        }
        return null;
    };

    @computed public get isBogEnabled(): boolean {
        const basicData = BasicDataModel.get(this.common).basicData;
        return basicData.valueReady === null ? false : basicData.valueReady.bpEnabled;
    }

    public getLegPrice = (leg: BetLegType, type?: string): string => {
        const spPrice = leg.spPrice ?? null;
        const price = leg.price ?? null;

        if (leg.priceType === 'sp' && spPrice === null) {
            return 'SP';
        }

        const oddsFormat = this.params.getOddsFormat();

        if (spPrice !== null && (type === 'sp' || leg.priceType === 'bp' || leg.priceType === 'sp')) {
            const isBP = leg.priceType === 'bp';
            const spPriceD = spPrice.d ?? null;
            const spPriceF = spPrice.f ?? null;
            const priceD = price?.d ?? null;
            const priceF = price?.f ?? null;

            if (type === 'sp') {
                if (isBP && spPriceD !== null && spPriceF !== null) {
                    return oddsFormat === 'd' ? spPriceD.toFixed(2) : spPriceF;
                }
                return '';
            }

            if (priceD !== null && priceF !== null && spPriceD !== null && isBP && spPriceD < priceD) {
                return oddsFormat === 'd' ? priceD.toFixed(2) : priceF;
            }

            if (isBP && priceD !== null && priceF !== null) {
                return oddsFormat === 'd' ? priceD.toFixed(2) : priceF;
            }

            return oddsFormat === 'd' && spPriceD !== null ? spPriceD.toFixed(2) : spPriceF === null ? 'n/a' : spPriceF;
        }

        const priceD = price?.d ?? null;
        const priceF = price?.f ?? null;
        if (oddsFormat === 'd' && priceD !== null) {
            return priceD.toFixed(2);
        }

        return priceF ?? 'n/a';
    };

    public getIsBetSP = (legs: Array<BetLegType>): boolean => {
        return legs.some((leg) => leg.priceType === 'sp');
    };
}
