import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    FooterCopyrightText,
    LinkWrapper,
    MobileAppVersion,
    EmptyLinkWrapper,
    FooterCopyrightWrapper,
    ValidationScriptIconWrapper,
    ValidationScriptImgWrapper,
} from './FooterCopyright.style';
import { useAppStateContext } from 'src/appState/AppState';
import { FooterCopyrightState } from 'src/domains/layouts/webview/modules/layoutSkeleton/footer/copyright/FooterCopyright.state';
import { LanguageTokenType } from 'src/domains/layouts/state/languagesState/LanguagesUtils';
import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import { useCommon } from 'src/domains/common/Common';

const TermsAndConditions = observer('TermsAndConditions', () => {
    const { appLayoutsState } = useAppStateContext();
    const { languagesState, configComponents } = appLayoutsState;

    if (configComponents.config.hasFooterCopyrightTCLink) {
        return languagesState.translateTokens(
            languagesState.getTranslation(
                'footer.copyright.general-terms-and-conditions',
                'In order to register for this website, the user is required to accept the [generalTermsAndConditions]. In the event the General Terms and Conditions are updated, existing users may choose to discontinue using the products and services before the said update shall become effective, which is a minimum of two weeks after it has been announced.'
            ),

            (singleParam: LanguageTokenType) => {
                if (singleParam.tag === 'generalTermsAndConditions') {
                    return (
                        <LinkWrapper
                            to={{ account: 'static', static: 'terms-and-conditions' }}
                            dataTest='terms-and-conditions'
                        >
                            General Terms and Conditions
                        </LinkWrapper>
                    );
                }
            }
        );
    } else {
        return null;
    }
});
const CookiePolicy = observer('CookiePolicy', () => {
    const { appLayoutsState } = useAppStateContext();
    const { languagesState } = appLayoutsState;

    return languagesState.translateTokens(
        languagesState.getTranslation(
            'footer.copyright.cookie-policy',
            'This site uses cookies, for more information please see our [cookiePolicy].'
        ),

        (singleParam: LanguageTokenType) => {
            if (singleParam.tag === 'cookiePolicy') {
                return (
                    <LinkWrapper
                        to={{ account: 'static', static: 'cookie-policy' }}
                        dataTest='cookie-policy'
                    >
                        cookie policy
                    </LinkWrapper>
                );
            }
        }
    );
});

export const FooterCopyrightDefault = observer('FooterCopyrightDefault', () => {
    const appState = useAppStateContext();
    const common = useCommon();

    const [state] = useState(() => new FooterCopyrightState(appState.config, common.trpcClient));

    const licenceNumber = (): JSX.Element | string => {
        if (state.licenceUrl === null || state.licenceUrl === '') {
            return <EmptyLinkWrapper data-test='licence-number-link'>{state.licenceNumber}</EmptyLinkWrapper>;
        }

        return (
            <LinkWrapper
                to={state.licenceUrl}
                dataTest='licence-number'
            >
                {state.licenceNumber}
            </LinkWrapper>
        );
    };

    const copyrightText = state.copyrightDescription;

    if (Array.isArray(copyrightText)) {
        const copyrightFirstPart = state.copyrightDescription[0] ?? '';
        const copyrightLastPart = state.copyrightDescription[1] ?? '';

        return (
            <>
                <TermsAndConditions /> {copyrightFirstPart}
                {licenceNumber()}
                {copyrightLastPart} <CookiePolicy />
            </>
        );
    }

    return (
        <>
            <TermsAndConditions /> {copyrightText} <CookiePolicy />
        </>
    );
});

const FooterAntillephoneScript = observer(
    'FooterAntillephoneScript',
    ({ antillephoneId }: { antillephoneId: string }) => {
        React.useEffect(() => {
            setTimeout(() => {
                const widgetScript = document.createElement('script');
                widgetScript.type = 'text/javascript';

                widgetScript.src = `https://${antillephoneId}.snippet.antillephone.com/apg-seal.js`;

                document.body.appendChild(widgetScript);
            }, 0);
        }, []);

        return (
            <ValidationScriptIconWrapper>
                <div
                    data-apg-image-size='256'
                    data-apg-image-type='basic-light-large'
                    data-apg-seal-id={antillephoneId}
                    id={`apg-${antillephoneId}`}
                />
            </ValidationScriptIconWrapper>
        );
    }
);
const FooterXCMScript = observer('FooterXCMScript', () => {
    React.useEffect(() => {
        setTimeout(() => {
            const widgetScript = document.createElement('script');
            widgetScript.type = 'text/javascript';

            widgetScript.src = 'https://3c678503-1155-48cd-a7e7-b55eae46489e.seals-xcm.certria.com/xcm-seal.js';

            document.body.appendChild(widgetScript);
        }, 0);
    }, []);

    return (
        <ValidationScriptIconWrapper>
            <div
                id='xcm-3c678503-1155-48cd-a7e7-b55eae46489e'
                data-xcm-seal-id='3c678503-1155-48cd-a7e7-b55eae46489e'
                data-xcm-image-size='128'
                data-xcm-image-type='basic-small'
            />
        </ValidationScriptIconWrapper>
    );
});

interface FooterCEGScriptPropsType {
    links: {
        iconUrl: string;
        verificationUrl: string;
    };
}

const FooterCEGScript = observer('FooterCEGScript', (props: FooterCEGScriptPropsType) => {
    return (
        <Link to={props.links.verificationUrl}>
            <ValidationScriptImgWrapper
                src={props.links.iconUrl}
                alt='Licence Seal'
            />
        </Link>
    );
});

export const FooterCopyright = observer('FooterCopyright', () => {
    const { appLayoutsState } = useAppStateContext();
    const {
        configComponents: { config },
    } = appLayoutsState;
    const { mobileWrapperType, mobileWrapperVersion } = appLayoutsState.env.envVariables;

    const version = mobileWrapperVersion ?? null;
    const antillephoneId = config.antillephoneId;
    return (
        <>
            <FooterCopyrightWrapper>
                {antillephoneId === null || config.antillephoneDisplaySector !== 'license' ? null : (
                    <FooterAntillephoneScript antillephoneId={antillephoneId} />
                )}
                {config.footerCEGLinks === null ? null : <FooterCEGScript links={config.footerCEGLinks} />}
                {config.hasXcmScript === false ? null : <FooterXCMScript />}
                <FooterCopyrightText data-test='FooterCopyright'>
                    <FooterCopyrightDefault />
                </FooterCopyrightText>
            </FooterCopyrightWrapper>

            {mobileWrapperType === null || version === null ? null : (
                <MobileAppVersion>Mobile app version: {mobileWrapperVersion}</MobileAppVersion>
            )}
        </>
    );
});
