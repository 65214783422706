import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { DepositLimit } from './depositLimit/DepositLimit';
import { RealityCheck } from './realityCheck/RealityCheck';
import { SkipButton } from 'src/domains/players/webview/components/WithdrawAndDeposit';
import { PageSectionWrapper } from 'src/domains/players/webview/components/SignUp/signupParts/Signup.style';
import { LayoutSignUp } from 'src/domains/players/webview/components/SignUp/signupPrimary/SignupLayout';
import { ResponsibleGamblingState } from 'src/domains/players/webview/components/SignUp/signupPrimary/signupState/stepsStructure/responsibleGambling/ResponsibleGambling.state';
import { ResponsibleGamblingHeader, MainDescription, NextButtonWrapper } from './ResponsibleGambling.style';
import { SignUpState } from 'src/domains/players/webview/components/SignUp/signupPrimary/signupState/SignUp.state';
import { NetDepositLimitRolling } from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/responsibleGambling/rollingNetDepositLimit/RollingNetDepositLimit';
import { NetDepositLimitCalendar } from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/responsibleGambling/rollingNetDepositLimit/NetDepositLimitCalendar';

interface ResponsibleGamblingPagePropsType {
    state: ResponsibleGamblingState;
    signUpState: SignUpState;
}

export const ResponsibleGamblingPage = observer(
    'ResponsibleGamblingPage',
    ({ state, signUpState }: ResponsibleGamblingPagePropsType) => {
        const { depositLimits, userCanChangeRealityCheck, rollingNetDepositLimit, depositLimitsType } =
            signUpState.params.config.config;

        return (
            <LayoutSignUp
                stepsCounter={true}
                footer={true}
                signUpState={signUpState}
            >
                <PageSectionWrapper>
                    <ResponsibleGamblingHeader data-test='responsible-gambling-header'>
                        {depositLimitsType === 'calendarType' ? (
                            <I18n
                                langKey='signup-journey.safe-gambling.title'
                                defaultText='Safer Gambling'
                            />
                        ) : (
                            <I18n
                                langKey='signup-journey.responsible-gambling.title'
                                defaultText='Responsible Gambling'
                            />
                        )}
                    </ResponsibleGamblingHeader>
                    <MainDescription data-test='main-description'>
                        {depositLimitsType === 'calendarType' ? (
                            <I18n
                                langKey='signup-journey.safe-gambling.description'
                                defaultText='We take safer gambling seriously. The tools below can be used to manage your betting activity.'
                            />
                        ) : (
                            <I18n
                                langKey='signup-journey.responsible-gambling.description'
                                defaultText='We take responsible gambling seriously. The tools below can be used to manage your betting activity.'
                            />
                        )}
                    </MainDescription>
                    {depositLimits === true ? <DepositLimit state={state.depositLimitState} /> : null}
                    {rollingNetDepositLimit === true ? (
                        depositLimitsType === 'calendarType' ? (
                            <NetDepositLimitCalendar state={state.rollingNetDepositLimitState} />
                        ) : (
                            <NetDepositLimitRolling state={state.rollingNetDepositLimitState} />
                        )
                    ) : null}
                    {userCanChangeRealityCheck === true ? <RealityCheck state={state.realityCheckState} /> : null}
                    <NextButtonWrapper
                        size='large'
                        onClick={state.onSubmitAndNext}
                        disabled={state.isButtonDisabled}
                        dataTest='next-button'
                    >
                        <I18n
                            langKey='signup-journey.responsible-gambling.button.next'
                            defaultText='Next'
                        />
                    </NextButtonWrapper>

                    <SkipButton
                        version='secondary-dark'
                        size='medium'
                        onClick={(): void => state.onMoveToNextStep('skipped')}
                        dataTest='skip-button'
                    >
                        <I18n
                            langKey='signup-journey.responsible-gambling.button.skip'
                            defaultText='Skip'
                        />
                    </SkipButton>
                </PageSectionWrapper>
            </LayoutSignUp>
        );
    }
);
