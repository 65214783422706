import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { OffersSettings } from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/createAccount/yourDetails/offersSettings/OffersSettings';
import { TermsAndConditions } from 'src/domains/players/webview/components/SignUp/signupParts/termsAndConditions/TermsAndConditions';
import { Input } from 'src/domains/players/webview/components/form/input/Input';
import { TitleSection } from './titleSection/TitleSection';
import {
    Select,
    convertArrayOfStringForSelectComponent,
} from 'src/domains/players/webview/components/form/select/Select';
import { DateOfBirth } from 'src/domains/players/webview/components/SignUp/signupParts/dateOfBirth/DateOfBirth';
import { PhoneNumber } from 'src/domains/players/webview/components/SignUp/signupParts/phoneNumber/PhoneNumber';
import { PostCodeSection } from './postCodeSection/PostCodeSection';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { LayoutSignUp } from 'src/domains/players/webview/components/SignUp/signupPrimary/SignupLayout';
import { CreateAccountStep } from 'src/domains/players/webview/components/SignUp/signupPrimary/signupState/stepsStructure/createAccount/CreateAccount.state';
import { OffersSettingsHeader } from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/createAccount/yourDetails/offersSettings/OffersSettings.style';
import {
    WizardForm,
    WizardFormWrapper,
    WizardFormTitle,
    WizardWrapper,
    AgreeButtonWrapper,
    AddressesInputsWrapper,
    ClearAddressForm,
    EnterManually,
} from './YourDetails.style';
import { PostCodeOnlyInput } from './postCodeSection/PostCodeOnlyInput';
import { useAppStateContext } from 'src/appState/AppState';
import { SignUpState } from 'src/domains/players/webview/components/SignUp/signupPrimary/signupState/SignUp.state';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

interface YourDetailsPagePropsType {
    state: CreateAccountStep;
    signUpState: SignUpState;
}

export const YourDetailsPage = observer('YourDetailsPage', ({ state, signUpState }: YourDetailsPagePropsType) => {
    const { yourDetailsState } = state;
    const {
        appLayoutsState: {
            configComponents: { config },
        },
    } = useAppStateContext();

    // prettier-ignore
    return (
        <LayoutSignUp stepsCounter={true} footer={true} signUpState={signUpState}>
            <WizardWrapper>
                <WizardForm>
                    <WizardFormWrapper>
                        <WizardFormTitle data-test='wizard-form-title'>
                            <I18n langKey='signup.step-two.legend' defaultText='Your Details' />
                        </WizardFormTitle>
                        {config.signUpTitle ?
                            <TitleSection state={yourDetailsState.genderTitle} />
                            : null}
                        <Input
                            label={<I18n langKey='signup.your-details.first-name' defaultText='First name' />}
                            state={yourDetailsState.firstNameState}
                            name='first-name'
                            type='text'
                            placeholder=''
                            asideElement={yourDetailsState.customKeyboard.getAsideElement}
                            showSuccessIcon={true}
                            dataTest='first-name-input'
                        />
                        <Input
                            label={<I18n langKey='signup.your-details.last-name' defaultText='Last name' />}
                            state={yourDetailsState.lastNameState}
                            name='last-name'
                            type='text'
                            placeholder=''
                            asideElement={yourDetailsState.customKeyboard.getAsideElement}
                            showSuccessIcon={true}
                            dataTest='last-name-input'
                        />
                        <DateOfBirth dateState={yourDetailsState.dateOfBirth} />

                        <PhoneNumber onBlur={config.zipCodeAndCountryCheck === true ? yourDetailsState.handleWasTouched : undefined} state={yourDetailsState.phoneNumber} hasPrefixes={config.prefixMultiPhoneNumber} />

                        {/* Address and Currency Part */}
                        {
                            yourDetailsState.isShownFullAddressForm === true || config.signUpAddressSearchByPostCode === false ?
                                <AddressesInputsWrapper key='full-address-wrapper'>
                                    <Input
                                        state={yourDetailsState.addressLineOneState}
                                        label={config.signUpSecondAddressLine ?
                                            <I18n langKey='signup.address-procedure.address-line-1.signle.label' defaultText='Address line 1' /> :
                                            <I18n langKey='signup.address-procedure.address-line-1.label' defaultText='Address line' />}
                                        asideElement={yourDetailsState.customKeyboard.getAsideElement}
                                        showSuccessIcon={true}
                                        dataTest='address-line-1-input'
                                    />
                                    {config.signUpSecondAddressLine ?
                                        <Input
                                            state={yourDetailsState.addressLineTwoState}
                                            label={<I18n langKey='signup.address-procedure.address-line-2.label' defaultText='Address line 2 (optional)' />}
                                            asideElement={yourDetailsState.customKeyboard.getAsideElement}
                                            showSuccessIcon={true}
                                            dataTest='address-line-2-input'
                                        />
                                        : null
                                    }
                                    <Input
                                        state={yourDetailsState.cityState}
                                        label={<I18n langKey='signup.address-procedure.city.label' defaultText='Town / City' />}
                                        asideElement={yourDetailsState.customKeyboard.getAsideElement}
                                        showSuccessIcon={true}
                                        dataTest='town-city-input'
                                    />

                                    <Select
                                        state={yourDetailsState.country}
                                        options={config.countryOnlyDefault === true ? [] : yourDetailsState.formattedCountries()}
                                        labelText={<I18n langKey='signup.address-procedure.country.label' defaultText='Country' />}
                                        dataTest='country-dropdown'
                                        isSearchable={true}
                                        hasPrefixes={config.countryOnlyDefault === false}
                                        onBlur={config.zipCodeAndCountryCheck === true ? yourDetailsState.handleWasTouched : undefined}
                                    />

                                    {config.signUpAddressSearchByPostCode === true ?
                                        <ClearAddressForm
                                            onClick={yourDetailsState.clearAddressForm}
                                        >
                                            <I18n langKey='signup.address-procedure.address-enter-manually.clear' defaultText='Clear' />
                                        </ClearAddressForm>
                                        : null}
                                </AddressesInputsWrapper>
                                : null
                        }

                        {config.signUpAddressSearchByPostCode === true ? <PostCodeSection yourDetailsState={yourDetailsState} /> : <PostCodeOnlyInput yourDetailsState={yourDetailsState} />}

                        {
                            yourDetailsState.isShownFullAddressForm === false && config.signUpAddressSearchByPostCode === true ?
                                <EnterManually
                                    onClick={yourDetailsState.showFullAddressFormHandler}
                                    key='show-full-form-button'
                                    data-test='enter-manually-button'
                                >
                                    <I18n langKey='signup.address-procedure.or-enter-manually' defaultText='Or Enter Manually' />
                                </EnterManually>
                                : null
                        }

                        <Select
                            state={yourDetailsState.currency}
                            options={config.currencyOnlyDefault ? [] : convertArrayOfStringForSelectComponent(config.currencySignUpList)}
                            labelText={<I18n langKey='signup.currency-procedure.label' defaultText='Currency' />}
                            dataTest='currency-dropdown'
                            hasPrefixes={config.currencyOnlyDefault === false}
                            changeToInput={true}
                        />

                        <OffersSettingsHeader data-test='offers-settings-header'>
                            <I18n langKey='signup.step-two.offers-settings-title' defaultText='How would you like to receive offers?' />
                        </OffersSettingsHeader>
                        <OffersSettings groupState={yourDetailsState.contactPreferences} skin='dark' />

                        <TermsAndConditions
                            signUpCheckboxTC={config.signUpCheckboxTC}
                            termsAndConditionsCheckboxState={yourDetailsState.termsAndConditionsCheckboxState}
                        />
                        <AgreeButtonWrapper
                            type='button'
                            size='large'
                            onClick={state.createAccountAndMoveToNextStep}
                            isLoading={yourDetailsState.loadingCreateAccountReq}
                            disabled={!yourDetailsState.termsAndConditionsCheckboxState.checkBoxesTermsAndConditions || config.signUpDisableButtonYourDetails}
                            dataTest='agree-and-join-button'
                        >
                            <I18n langKey='signup.step-two.agree-join' defaultText='Agree & Join' />
                        </AgreeButtonWrapper>
                        {
                            state.backendCreateAccountError === null ? null :
                                <Messages type='error' marginBottom='16px' message={state.backendCreateAccountError} />
                        }
                    </WizardFormWrapper>
                </WizardForm>
            </WizardWrapper>
        </LayoutSignUp>
    );
});
