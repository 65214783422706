import { action, makeObservable, observable } from 'mobx';
import { Common } from 'src/domains/common/Common';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';

export type RemovePaymentMethodCardPopupPropType = { isOpen: false } | { isOpen: true; id: string; label: string };

export class DeleteCardPopupState {
    @observable public removePaymentMethodCardPopup: RemovePaymentMethodCardPopupPropType = { isOpen: false };
    @observable public isLoading: boolean = false;

    public constructor(
        private readonly common: Common,
        private readonly refreshPaymentMethods: () => Promise<void>
    ) {
        makeObservable(this);
    }

    @action public handleOpenRemoveCardPopup = (id: string, label: string): void => {
        this.removePaymentMethodCardPopup = { isOpen: true, id, label };
    };

    @action public handleClosePopup = (): void => {
        this.removePaymentMethodCardPopup = { isOpen: false };
    };

    @action public handleRemoveCard = async (): Promise<void> => {
        this.isLoading = true;
        try {
            const configComponents = ConfigComponents.get(this.common);

            if (this.removePaymentMethodCardPopup.isOpen === false) {
                return;
            }

            if (configComponents.config.usePaymentFormForUniverse.type === 'paysafe') {
                await this.common.trpcClient.client.paysafeRouter.deleteCardPaysafe.mutate({
                    cardId: this.removePaymentMethodCardPopup.id,
                });
            }

            if (configComponents.config.usePaymentFormForUniverse.type === 'realex') {
                await this.common.trpcClient.client.realex.deleteCard.mutate({
                    cardId: this.removePaymentMethodCardPopup.id,
                });
            }

            await this.refreshPaymentMethods();
            this.removePaymentMethodCardPopup = { isOpen: false };
        } catch (e) {
            console.error('Error in handleRemoveCard');
        } finally {
            this.isLoading = false;
        }
    };
}
