/* stylelint-disable */
import { css, SerializedStyles } from '@emotion/react';

export const intercomChatStyle = (isCasino: boolean): SerializedStyles => css`
    .intercom-lightweight-app-launcher, .intercom-namespace .intercom-with-namespace-52k34s {
        bottom: ${isCasino ? '60px !important' : '100px !important'};
        z-index: 1 !important;

        @media(min-width: 1024px){
            bottom: 20px !important;
            z-index: 11 !important;
        }
    }

    .intercom-lightweight-app {
        z-index: 1 !important;

        @media(min-width: 1024px){
            z-index: 11 !important;
        }
    }
`;
