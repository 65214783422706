import { Amount } from './Amount';
import { convertNumberToString, convertStringToNumber } from './convert';

export class AmountPrecision {
    private readonly precision: number;

    public constructor(precision: number) {
        this.precision = precision;
    }

    public get getPrecistion(): number {
        return this.precision;
    }

    public valueOldFormat(amount: Amount): number {
        return convertStringToNumber(this.precision, amount.value);
    }

    public newFromOld(value: number): Amount {
        const newFormat = convertNumberToString(this.precision, value);
        return new Amount(newFormat);
    }

    public newFromAnything(value: number | string): Amount {
        if (typeof value === 'string') {
            return new Amount(value);
        }

        return this.newFromOld(value);
    }

    public newFromOldAndFloor(value: number | string): Amount {
        if (typeof value === 'string') {
            return new Amount(value);
        }

        if (Math.floor(value) !== value) {
            console.error(`Amount.newFromOldAndFloor: Integer was expected value=${value}`);
        }

        return this.newFromOld(Math.floor(value));
    }

    /**
     * Be careful - `newFromPragmaticNumber` method is not recommended in most cases.
     * Pragmatic sends float (min/max bet) as number so convert it by newFromOld will cause distortions.
     * In this exceptional case we need force `toString` to avoid it.
     *
     * TODO: In the future the method should include BTC for LK
     */
    public newFromPragmaticNumber(value: number): Amount {
        return new Amount(value.toString());
    }
}

