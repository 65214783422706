import { computed, makeObservable } from 'mobx';
import { Amount } from 'src_common/common/amount/Amount';
import { getDepositLimits } from 'src_server/trpc/types/responsibleGambling';
import { Resource } from 'src_common/common/mobx-utils/Resource';
import { RollingNetDepositLimitDataType, UsersState } from 'src/domains/players/state/UsersState';
import { Common } from 'src/domains/common/Common';
import { AmountPrecision } from 'src_common/common/amount/AmountPrecision';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';

type RollingDayType = 1 | 7 | 30 | 90 | 365;

const rollingDays: RollingDayType[] = [1, 7, 30, 90, 365];

const calculateRemainingLimits = (
    rollingNetDepositLimitData: RollingNetDepositLimitDataType,
    depositLimits: getDepositLimits.SuccessResponseType['limits'],
    amountPrecision: AmountPrecision
): Array<Amount | undefined> => {
    return rollingDays
        .map((limitDayNumber) => {
            const rollingNetDeposit = rollingNetDepositLimitData.get(limitDayNumber);

            if (rollingNetDeposit === undefined) {
                return undefined;
            }

            const usedDepositLimit = depositLimits.find((itemFind) => {
                return itemFind.windowLengthInDays === limitDayNumber;
            })?.used;

            if (usedDepositLimit === undefined) {
                return undefined;
            }

            const rollingLimit = amountPrecision.newFromOld(rollingNetDeposit.activeLimitValue);
            const usedLimit = amountPrecision.newFromOld(usedDepositLimit);

            return rollingLimit.sub(usedLimit);
        })
        .filter((limit) => limit !== undefined);
};

const getLowestAmountValue = (amounts: Array<Amount | undefined>): Amount | undefined => {
    if (amounts.length === 0) {
        return undefined;
    }

    return amounts.reduce((prev, curr) => {
        if (prev === undefined || curr === undefined) {
            return undefined;
        }

        return prev.isLessThan(curr) ? prev : curr;
    });
};

export class RemainingLimitState {
    public readonly depositLimitsDataResource: Resource<getDepositLimits.ResponseType>;

    public constructor(
        private readonly common: Common,
        private readonly usersState: UsersState
    ) {
        makeObservable(this);

        this.usersState.rollingNetDepositLimitData.refresh();
        this.depositLimitsDataResource = new Resource(async (): Promise<getDepositLimits.ResponseType> => {
            const response = await this.common.trpcClient.client.responsibleGamblingRouter.getDepositLimits.query();
            return response;
        });
    }

    @computed public get getRemainingLimit(): Amount | undefined {
        const rollingNetDepositLimitData = this.usersState.rollingNetDepositLimitData.valueReady;
        const depositLimits = this.getDepositLimitsData;

        if (rollingNetDepositLimitData === null || depositLimits === null) {
            return undefined;
        }

        const remainingLimits = calculateRemainingLimits(
            rollingNetDepositLimitData,
            depositLimits,
            ConfigComponents.get(this.common).precision
        );
        return getLowestAmountValue(remainingLimits);
    }

    @computed private get getDepositLimitsData(): getDepositLimits.SuccessResponseType['limits'] | null {
        const depositLimit = this.depositLimitsDataResource.getReady();

        if (depositLimit === null) {
            return null;
        }

        if (depositLimit.responseStatus === 'apiError') {
            return null;
        }

        return depositLimit.data.limits;
    }
}
