import React, { useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useAsObservableSource } from 'mobx-react-lite';
import { observer } from 'src/utils/mobx-react';
import {
    ButtonsContainer,
    ButtonWrapper,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/paymentsAdditions/PaymentsAdditions.style';
import { CurrencySymbolType } from 'src_common/common/amount/website-money/currency';
import { Amount } from 'src_common/common/amount/Amount';

class ButtonAmountState {
    public constructor(private readonly props: ButtonAmountPropsType) {}

    public handleChange = (): void => {
        const { value, onChange, amount } = this.props;
        const convertedAmount = new Amount(value === '' ? '0' : value);
        onChange(convertedAmount.add(new Amount(amount)));
    };
}

interface ButtonAmountPropsType {
    value: string;
    amount: string;
    currency: CurrencySymbolType;
    onChange: (amount: Amount) => void;
    dataTest?: string;
}

const ButtonAmount = observer('ButtonAmount', (propsIn: ButtonAmountPropsType) => {
    const props = useAsObservableSource(propsIn);
    const [state] = useState(() => new ButtonAmountState(props));
    const { currency, amount } = props;

    return (
        <ButtonWrapper
            onClick={state.handleChange}
            size='small'
            dataTest={`button-amount-${amount}`}
        >
            {currency}
            {amount}
        </ButtonWrapper>
    );
});

interface PropsType {
    value: string;
    options?: Array<string>;
    currency: CurrencySymbolType;
    onChange: (amount: Amount) => void;
}

export const PaymentAdditions = observer('PaymentAdditions', (propsIn: PropsType) => {
    const props = useAsObservableSource(propsIn);
    const defOptions = ['10', '20', '50', '100'];

    const { options = defOptions, currency, onChange, value } = props;

    return (
        <ButtonsContainer>
            {options.map((amount) => (
                <ButtonAmount
                    key={amount}
                    value={value}
                    onChange={onChange}
                    amount={amount}
                    currency={currency}
                />
            ))}
        </ButtonsContainer>
    );
});
