import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { assertNever } from 'src_common/common/assertNever';
import { useAppStateContext } from 'src/appState/AppState';
import { SignUpWelcomePage } from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/createAccount/signupWelcome/SignupWelcomePage';
import { YourDetailsPage } from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/createAccount/yourDetails/YourDetailsPage';
import { ResponsibleGamblingPage } from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/responsibleGambling/ResponsibleGamblingPage';
import { DepositSignupProvider } from 'src/domains/players/webview/components/WithdrawAndDeposit';
import {
    SignUpWrapper,
    SignUpWrapperOld,
    SignupOverlay,
} from 'src/domains/players/webview/components/SignUp/signupParts/Signup.style';
import { LoadingSpinner } from 'src/domains/layouts/webview/components/loaders/loadingSpinner/LoadingSpinner';
import { SignupPopupContainer } from 'src/domains/players/webview/components/SignUp/signupPrimary/signupPopups/SignupPopupContainer';
import { VerificationFailedPage } from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/profileVerification/verificationFailed/VerificationFailedPage';
import { GamStopAccountFailedPage } from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/profileVerification/verificationFailed/GamStopAccountFailedPage';
import { VerificationFailedDetailedPage } from './signUpViews/profileVerification/verificationFailed/VerificationFailedDetailedPage';
import { SignupType } from 'src/domains/players/webview/components/SignUp';

interface SignUpPrimaryStepsContainerPropsType {
    signUpState: SignupType;
}

export const SignUpPrimaryStepsContainer = observer(
    'SignUpStepsContainer',
    ({ signUpState }: SignUpPrimaryStepsContainerPropsType) => {
        const { appPlayersState, appLayoutsState } = useAppStateContext();
        const {
            configComponents: { config },
        } = appLayoutsState;
        const { customKeyboard, signupPopupState } = appPlayersState;

        const { step } = signUpState.state.currentView;

        const renderContent = (): JSX.Element => {
            switch (step.type) {
                case 'create-account':
                    switch (step.state.currentStage) {
                        case 'welcome':
                            return (
                                <SignUpWelcomePage
                                    signUpState={signUpState}
                                    state={step.state.welcomeState}
                                />
                            );
                        case 'your-details':
                            return (
                                <YourDetailsPage
                                    signUpState={signUpState.state}
                                    state={step.state}
                                />
                            );
                    }
                    return assertNever('create-account step', step.state.currentStage);
                case 'responsible-gambling':
                    return (
                        <ResponsibleGamblingPage
                            signUpState={signUpState.state}
                            state={step.state}
                        />
                    );
                case 'deposit':
                    return <DepositSignupProvider signUpState={signUpState.state} />;
                case 'verification-failed':
                    if (config.failedVerificationDetailed === true) {
                        return <VerificationFailedDetailedPage signUpState={signUpState.state} />;
                    }

                    return <VerificationFailedPage signUpState={signUpState} />;
                case 'gamstop-account-failed':
                    return <GamStopAccountFailedPage signUpState={signUpState} />;
            }

            return assertNever('Steps in signUp', step);
        };

        const renderOverlay = (): JSX.Element | null => {
            if (signupPopupState.isAnyPopup === true) {
                return <SignupOverlay />;
            }
            return null;
        };

        if (config.accountDrawerNew) {
            return (
                <SignUpWrapper
                    ref={customKeyboard.setAsideElementRef}
                    data-test='SignUpStepsContainer'
                >
                    {signUpState.state.isLoading === false ? <>{renderContent()}</> : <LoadingSpinner />}
                    {renderOverlay()}
                    <SignupPopupContainer />
                </SignUpWrapper>
            );
        }

        return (
            <SignUpWrapperOld
                ref={customKeyboard.setAsideElementRef}
                shouldBeMoved={config.noBetslip === false}
                data-test='SignUpStepsContainer'
            >
                {signUpState.state.isLoading === false ? <>{renderContent()}</> : <LoadingSpinner />}
                {renderOverlay()}
                <SignupPopupContainer />
            </SignUpWrapperOld>
        );
    }
);
