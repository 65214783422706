import { EnvironmentState } from 'src/domains/layouts/state/environmentState/EnvironmentState';
import { autorun, computed, makeObservable } from 'mobx';
import { ExternalApi } from 'src/domains/layouts/state/externalApi/ExternalApi';
import { LocalStorageState } from 'src/domains/layouts/state/localStorage/LocalStorageState';
import { StreamViewDetails } from 'src/domains/sportsbook/shared/Types';
import { createContext } from 'src/utils/createContext';
import { Session } from 'src_common/sdk/session';
import { AppSportsBookState } from 'src/domains/sportsbook/state/AppSportsBook.state';
import { AppLayoutsState, LayoutsCallbacksType } from 'src/domains/layouts/state/AppLayouts.state';
import { AppCasinoState } from 'src/domains/casino/state/AppCasino.state';
import { GamePlayTagType, RedirectCasinoCallbackType } from 'src/domains/casino/utils/callbackTypes';
import { AppPlayersState } from 'src/domains/players/state/AppPlayers.state';
import { SportModel } from 'src_common/common/websocket2/models/SportModel/SportModel';
import { EventsCollectionList } from 'src/domains/sportsbook/state/eventsCollection/EventsCollectionList';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { CurrencyType } from 'src_common/common/amount/website-money/currency';
import { CustomerFreeBetsType, ResourceCustomer, WalletDataTypeAmountType } from 'src/domains/players/state/UsersState';
import { GameSharedModel } from 'src/domains/casino/shared/Types';
import { EventsCollectionQueryFilterMarketType } from 'src_common/common/websocket2/modelsApi/EventsCollectionQuery';
import { slug } from 'src/utils/deburr';
import { OpenapiProxyCustomerRealityCheckResponse200Type } from 'src/api_openapi/generated/openapi_proxy_customer_reality_check';
import {
    OpenapiProxyCustomerSetRealityCheckParamsType,
    OpenapiProxyCustomerSetRealityCheckResponse200Type,
} from 'src/api_openapi/generated/openapi_proxy_customer_set_reality_check';
import { UserAllPopupsTypes } from 'src/domains/layouts/state/popupState/PopupState';
import { CompetitionId } from 'src_common/common/websocket2/id/WebsocketId';
import { TrpcClient } from './TrpcClient';
import { ConfigType } from 'src/domains/layouts/config/features/types';
import { ActiveSpecialSportsType } from 'src/domains/sportsbook/state/specialSportsState/SpecialSportsState';
import { Common } from 'src/domains/common/Common';
import { UniverseType } from 'src_common/common/universe';
import { UniverseModuleType } from 'src/domains/common/universes.type';
import { EnvVariables } from 'src/domains/common/contextStore/EnvVariables';
import { AccountTabsBuilderState } from 'src/domains/players/webview/components/Account/accountDrawer/AccountTabsBuilder.state';

export class AppState {
    /** !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */
    /** * Please do not move, External API should stay in AppState directly */
    /** !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */
    public readonly externalApi: ExternalApi; //layouts
    /** !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */
    /** !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */
    /** !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */

    /** * Please do not move until new version of Mobile App - contact Layouts team LT-848 */
    public readonly env: EnvironmentState; // moved

    public readonly appSportsBookState: AppSportsBookState;
    public readonly appLayoutsState: AppLayoutsState;
    public readonly appCasinoState: AppCasinoState;
    public readonly appPlayersState: AppPlayersState;

    public readonly accountTabsBuilderState: AccountTabsBuilderState;

    public constructor(public readonly common: Common) {
        makeObservable(this);
        this.externalApi = ExternalApi.get(common);

        // --------------- appLayoutsState ---------------

        const appLayoutsStateCallbacks: LayoutsCallbacksType = {
            getUserId: (): string | null => this.userId,
            getActiveSpecialSportsForView: (): Array<ActiveSpecialSportsType> => this.activeSpecialSportsForView,
            getSportsAndEventsListAllowed: (): Map<string, number> =>
                this.appSportsBookState.eventsCollection.sportsAndEventsListAllowed,
            listOfSport: (sport: string, filterMarket?: EventsCollectionQueryFilterMarketType): EventsCollectionList =>
                this.appSportsBookState.eventsCollection.listOfSport(sport, filterMarket),
            getGameById: (gameId: number): GameSharedModel | null =>
                this.appCasinoState.gameStoreExt.getGameModel(gameId)?.gameSharedModel ?? null,
            isGameExists: (gameId: number): boolean => this.appCasinoState.gameStoreExt.isGameExists(gameId),
            getSport: this.getSport,
            getSelection: this.getSelection,
            getIsCasinoMiniMobileOverlayOpen: (): boolean => this.isCasinoMiniMobileOverlayOpen,
            getSpecialEventsLandingPageShouldDisplay: (): boolean => this.specialEventsLandingPageShouldDisplay,
            getSearchStateIsShow: (): boolean => this.searchStateIsShow,
            getCurrency: (): CurrencyType => this.appPlayersState.usersState.currency,
            getWalletData: (): ResourceCustomer<WalletDataTypeAmountType> => this.appPlayersState.usersState.walletData,
            getStreamViewDetails: (): StreamViewDetails => this.appSportsBookState.streamingState.streamViewDetails,
            onRoutingAccountChange: (route: string): void =>
                this.appLayoutsState.googleTagManager.routingAccountChange(route),
            getListOfSportAndCompetition: (sport: string, competition: CompetitionId): EventsCollectionList =>
                this.appSportsBookState.eventsCollection.listOfSportAndCompetition(sport, competition.toOldId()),
            getListOfSport: (
                sport: string,
                filterMarket?: EventsCollectionQueryFilterMarketType
            ): EventsCollectionList => this.appSportsBookState.eventsCollection.listOfSport(sport, filterMarket),
            getAllSportsLength: (): number => this.appSportsBookState.eventsCollection.allSportsLength,
            getRealityCheckFrequency: (): OpenapiProxyCustomerRealityCheckResponse200Type | null =>
                this.appPlayersState.usersState.realityCheckData.valueReady ?? null,
            handleSetRealityCheckFrequency: (
                params: OpenapiProxyCustomerSetRealityCheckParamsType
            ): Promise<OpenapiProxyCustomerSetRealityCheckResponse200Type> =>
                this.appPlayersState.usersState.setRealityCheck(params),
            realityCheckPopupForCasino: (): boolean => this.appCasinoState.gameModalState.realityCheckPopupForCasino,
        };

        this.appLayoutsState = new AppLayoutsState(
            common,
            appLayoutsStateCallbacks,
            () => this.appPlayersState.marketingNotificationsState
        );

        //Please do not move until new version of Mobile App - contact Layouts team LT-848
        this.env = this.appLayoutsState.env;

        // --------------- end appLayoutsState ---------------

        // --------------- appCasinoState --------------->

        const casinoCallbacks: RedirectCasinoCallbackType = {
            onGoogleTagManagerGamePlayTag: (params: GamePlayTagType): void => {
                this.appLayoutsState.googleTagManager.gamePlayTag(params.gameId, params.gameName, params.gameType);
            },
            getCurrency: (): CurrencyType => this.appPlayersState.usersState.currency,
            onRoutingAccountChange: (route: string): void =>
                this.appLayoutsState.googleTagManager.routingAccountChange(route),
            getWebsocketCasinoHost: (): string => common.envVariables.websocket_casino_host,
        };

        this.appCasinoState = new AppCasinoState(
            this.appLayoutsState.configComponents,
            this.appLayoutsState.popupState,
            this.appLayoutsState.breakpointsState,
            this.env,
            casinoCallbacks,
            this.appLayoutsState.starRouter,
            this.appLayoutsState.languagesState,
            this.common
        );
        // <--------------- end appCasinoState ---------------

        // --------------- appPlayersState ---------------
        this.appPlayersState = new AppPlayersState(
            common,
            this.appLayoutsState.googleTagManager,
            this.appLayoutsState.starRouter,
            this.appLayoutsState.configComponents,
            this.appLayoutsState.languagesState,
            {
                isShowQuickBet: (): boolean => this.isShowQuickBet,
                largeDesktopIsBiggerOrEq: (): boolean | null => this.largeDesktopIsBiggerOrEq,
                whenUserLogin: (): void => {
                    this.appCasinoState.gameModalState.continueStartingGame();
                    LocalStorageState.get(common).realityCheckTime.setValue(null);
                },
                streamViewDetails: (): StreamViewDetails => this.appSportsBookState.streamingState.streamViewDetails,
                getIsBrowser: (): boolean => this.env.isBrowser,
                shopPopup: this.shopPopup,
            },
            this.env,
            this.appLayoutsState.geolocalization,
            this.appLayoutsState.bannersBoxState
        );
        // --------------- end appPlayersState ---------------

        // --------------- appSportsBookState ---------------
        this.appSportsBookState = new AppSportsBookState(
            common,
            this.appLayoutsState.languagesState,
            this.appLayoutsState.configComponents,
            this.appLayoutsState.lifeSpanState,
            this.appLayoutsState.starRouter,
            {
                getTranslation: this.appLayoutsState.languagesState.getTranslation,
                onRedirectToLogin: (): void => this.appLayoutsState.starRouter.redirectToLogin(),
                onRedirectToBetslip: (): void => this.appLayoutsState.starRouter.redirectToBetslip(),
                onRedirectToPromoTermsAndConditions: (): void =>
                    this.appLayoutsState.starRouter.showPromoTermsAndConditions(),
                getOddsFormat: (): 'f' | 'd' => this.appPlayersState.usersState.oddsFormatShort,
                getCurrency: (): CurrencyType => this.appPlayersState.usersState.currency,
                getFreeBetsData: (): ResourceCustomer<CustomerFreeBetsType | null> =>
                    this.appPlayersState.usersState.freeBetsData,
                getWalletData: (): ResourceCustomer<WalletDataTypeAmountType> =>
                    this.appPlayersState.usersState.walletData,
                getIsRabFeatureOn: (): boolean => this.appLayoutsState.featureState.rabFeature,
                onRoutingAccountChange: (route: string): void =>
                    this.appLayoutsState.googleTagManager.routingAccountChange(route),
                getIsBrowser: (): boolean => this.env.isBrowser,
                eventViewTag: this.appLayoutsState.googleTagManager.eventViewTag,
            }
        );
        this.fireAppSportsBookEmmiters(this.appSportsBookState);
        // --------------- end appSportsBookState ---------------

        this.appLayoutsState.starRouter.onChangeCurrentView((_prevCurrentView, nextCurrentView) => {
            if (nextCurrentView?.name === 'event') {
                const eventId = nextCurrentView.id;

                autorun((dispose) => {
                    const eventModel = this.appSportsBookState.models.getEvent(eventId);

                    if (eventModel !== null) {
                        dispose.dispose();

                        const currentView = this.appLayoutsState.starRouter.currentView;

                        //if the user is still on the event page
                        if (currentView?.name === 'event' && currentView.id === eventId) {
                            //if the event is a race, redirect to the race page
                            if (eventModel.sport === 'horseracing' || eventModel.sport === 'greyhoundracing') {
                                if (eventModel.antePost) {
                                    this.appLayoutsState.starRouter.redirectTo({
                                        name: 'sport',
                                        nameType: 'races',
                                        id: eventModel.sport,
                                        type: 'ante-post',
                                        event: {
                                            id: eventId,
                                            slug: slug(eventModel.name),
                                        },
                                    });
                                } else {
                                    this.appLayoutsState.starRouter.redirectToRaceCard(
                                        eventModel.id2,
                                        eventModel.sport,
                                        eventModel.competition
                                    );
                                }
                            }
                        }
                    }
                });
            }

            if (nextCurrentView?.name === 'racecard' && nextCurrentView.selected !== null) {
                const eventId = nextCurrentView.selected;

                autorun((dispose) => {
                    const eventModel = this.appSportsBookState.models.getEvent(eventId);

                    if (eventModel !== null) {
                        dispose.dispose();

                        const currentView = this.appLayoutsState.starRouter.currentView;

                        //if the user is still on the racecard page
                        if (currentView?.name === 'racecard' && currentView.selected === eventId) {
                            //if the event is a race, redirect to the race page
                            if (eventModel.sport === 'horseracing' || eventModel.sport === 'greyhoundracing') {
                                if (eventModel.antePost) {
                                    this.appLayoutsState.starRouter.redirectTo({
                                        name: 'sport',
                                        nameType: 'races',
                                        id: eventModel.sport,
                                        type: 'ante-post',
                                        event: {
                                            id: eventId,
                                            slug: slug(eventModel.name),
                                        },
                                    });
                                }
                            }
                        }
                    }
                });
            }
        });

        this.accountTabsBuilderState = new AccountTabsBuilderState(
            this.appLayoutsState.configComponents,
            this.appLayoutsState.languagesState,
            this.appCasinoState,
            this.appPlayersState.usersState,
            this.common
        );
    }

    @computed public get userId(): string | null {
        return this.appPlayersState.accountState.account?.userID ?? null;
    }

    @computed public get activeSpecialSportsForView(): Array<ActiveSpecialSportsType> {
        return this.appSportsBookState.specialSportsListState.activeSpecialSportsForView;
    }

    @computed public get isShowQuickBet(): boolean {
        return this.appSportsBookState.betSlipState.quickBetState.isShowQuickBet;
    }

    @computed public get largeDesktopIsBiggerOrEq(): boolean | null {
        return this.appLayoutsState.breakpointsState.largeDesktop.isBiggerOrEq;
    }

    @computed public get isCasinoMiniMobileOverlayOpen(): boolean {
        return this.appCasinoState.miniGamesListState.isOverlayOpenForCasinoMiniMobile;
    }

    @computed public get specialEventsLandingPageShouldDisplay(): boolean {
        return this.appPlayersState.specialEvents.getLandingPageState(
            this.appLayoutsState.starRouter.freeParams.promo ?? 'homepage'
        ).shouldDisplay;
    }

    @computed public get searchStateIsShow(): boolean {
        return this.appLayoutsState.searchState.isShow;
    }

    public getSelection = (selectionId: number): SelectionModel | null => {
        return this.appSportsBookState.models.getSelection(selectionId);
    };

    public getSport = (id: string): SportModel | null => {
        return this.appSportsBookState.models.getSport(id);
    };

    public shopPopup = (popup: UserAllPopupsTypes): void => {
        this.appLayoutsState.popupState.show(popup);
    };

    // --> Sportsbook
    private fireAppSportsBookEmmiters = (appSportsBookState: AppSportsBookState): void => {
        appSportsBookState.betSlipState.legsState.betslipData.onGoogleTagManagerBetAddedTag.on((params) => {
            this.appLayoutsState.googleTagManager.betAddedTag({
                selectionPrice: params.selectionPrice,
                marketId: params.marketId,
                marketName: params.marketName,
                selectionId: params.selectionId,
                selectionName: params.selectionName,
                betEventId: params.betEventId,
                betEventName: params.betEventName,
                type: params.type,
                sportId: params.sportId,
                sportName: params.sportName,
            });
        });
        appSportsBookState.betSlipState.betSlipSummaryState.onGoogleTagManagerBetPlacedTag.on((params) => {
            this.appLayoutsState.googleTagManager.betPlacedTag(params);
        });
    };

    @computed public get config(): ConfigType {
        return this.appLayoutsState.configComponents.config;
    }

    public static createForStorybook(universe: UniverseType, universeModule: UniverseModuleType): AppState {
        const { themeColors, features } = universeModule;
        const envVariables = EnvVariables.createForStorybook(universe);
        const session = Session.startFakeSession();
        const websiteBaseUrl = '';
        const startingUrl = '';

        const isBrowser = true;

        const common = new Common(
            startingUrl,
            envVariables,
            session,
            isBrowser,
            new TrpcClient(isBrowser, session),
            themeColors,
            features,
            '',
            null,
            websiteBaseUrl
        );
        return new AppState(common);
    }
}

const { AppContext, useContext } = createContext<AppState>('appState');

/**
 * @deprecated - Don't use context. This way is old because we want to move to microfrontends
 */
export const Provider = AppContext.Provider;

export const useAppStateContext = (): AppState => {
    return useContext();
};
