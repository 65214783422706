import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const AccountTabContentWrapper = withConfig(theme => styled('div', { label: 'AccountTabContentWrapper' })`
    background-color: ${theme.star.accountDrawer.bgColorSecondary};
    font-size: ${theme.star.fontSize.small};
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior-y: contain;
`);
