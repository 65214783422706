import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import { VirtualsIcon } from 'src/domains/layouts/shared/assets/icons/VirtualsIcon';
import { InPlayIcon } from 'src/domains/layouts/shared/assets/icons/InPlayIcon';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import styled from '@emotion/styled';
import { FootballIcon } from 'src/domains/layouts/shared/assets/icons/FootballIcon';
import { StatsIcon } from 'src/domains/layouts/shared/assets/icons/StatsIcon';
import { CasinoTableGamesIcon } from 'src/domains/layouts/shared/assets/icons/CasinoTableGamesIcon';
import { NewsIcon } from 'src/domains/layouts/shared/assets/icons/NewsIcon';
import { PromotionsIcon } from 'src/domains/layouts/shared/assets/icons/PromotionsIcon';
import { CasinoLucky6Icon } from 'src/domains/layouts/shared/assets/icons/CasinoLucky6Icon';
import { CasinoIcon } from 'src/domains/layouts/shared/assets/icons/CasinoIcon';
import { css, SerializedStyles } from '@emotion/react';
import { withConfig } from 'src/withConfig';

interface HeaderMainLinksPropsType {
    source?: string;
    isActive: boolean;
}

interface HeaderMainLinkPropsType {
    isSpecialLink: boolean;
    isLargeDesktop: boolean;
}

export const HeaderMainLinkWrapper = withConfig(theme => styled('nav', { label: 'HeaderMainLinkWrapper' }) <HeaderMainLinkPropsType>`
    background-color: ${({ isLargeDesktop }): string => isLargeDesktop ? theme.star.headerMain.bgColor : theme.star.headerMain.bgColorQuaternary};
    padding: ${({ isSpecialLink }): string => isSpecialLink ? '8px 0 16px' : '0 0 16px'};
    @media ${theme.star.mediaQuery.largeDesktop} {
        align-items: center;
        display: flex;
        height: 100%;
        overflow: hidden;
        padding: 0;
    }
`);


const setLink = (theme: EmotionTheme, isActive?: boolean): SerializedStyles => {
    return css`
        align-items: center;
        cursor: pointer;
        display: flex;
        font-size: ${theme.star.fontSize.regular};
        font-weight: ${theme.star.fontWeight.bold};
        line-height: 1.5;
        padding: 8px 16px;
        text-decoration: none;
        text-transform: uppercase;
        transition: color .2s ease;
        color: ${isActive === true ? theme.star.headerMain.txtColorSecondary : theme.star.headerMain.txtColorTertiary};
        svg {
            transition: fill .2s ease;
        }
        &:hover {
            color: ${theme.star.headerMain.txtColorSecondary};
        }
        @media ${theme.star.mediaQuery.largeDesktop} {
            color: ${isActive === true ? theme.star.headerMain.txtColorSecondary : theme.star.headerMain.txtColor};
            flex-flow: row nowrap;
            white-space: nowrap;
            padding: 0 8px;
        }
    `;
};

export const HeaderStyledLink = withConfig(theme => styled(Link, { label: 'HeaderStyledLink' }) <HeaderMainLinksPropsType>`
    ${({ isActive }): SerializedStyles => setLink(theme, isActive)};
`);


export const HeaderStyledStaticLink = withConfig(theme => styled(Link, { label: 'HeaderStyledStaticLink' })`
    ${(): SerializedStyles => setLink(theme)};
`);

export const HeaderStyledStaticOnClick = withConfig(theme => styled('button', { label: 'HeaderStyledStaticOnClick' })`
    ${(): SerializedStyles => setLink(theme)};
    background: none;
    border: none;
    cursor: pointer;
`);

const setIconInMenu = (): SerializedStyles => {
    return css`
        fill: currentcolor;
        flex: 0 0 16px;
        margin: 0 8px 0 0;
        height: 16px;
        width: 16px;
    `;
};

interface HeaderIconsPropsType {
    source?: string;
}

export const SpecialsIconWrapper = styled('div', { label: 'SpecialsIconWrapper' }) <HeaderIconsPropsType>`
    ${(): SerializedStyles => setIconInMenu()};
    background: currentcolor;
    ${({ source }): string => source === undefined ? '' : `mask: url(${source}) no-repeat center`};
    mask-size: contain;
`;

export const CherryIconWrapper = styled(CasinoIcon, { label: 'CherryIconWrapper' })`
    ${(): SerializedStyles => setIconInMenu()};
`;

export const FootballIconWrapper = styled(FootballIcon, { label: 'FootballIconWrapper' })`
    ${(): SerializedStyles => setIconInMenu()};
`;
export const StatsIconWrapper = styled(StatsIcon, { label: 'StatsIconWrapper' })`
    ${(): SerializedStyles => setIconInMenu()};
`;

export const InPlayIconWrapper = styled(InPlayIcon, { label: 'InPlayIconWrapper' })`
    ${(): SerializedStyles => setIconInMenu()};
`;

export const VirtualSportsIconWrapper = styled(VirtualsIcon, { label: 'VirtualSportsIconWrapper' })`
    ${(): SerializedStyles => setIconInMenu()};
`;

export const NewsIconWrapper = styled(NewsIcon, { label: 'NewsIconWrapper' })`
    ${setIconInMenu()};
`;

export const PromotionsIconWrapper = styled(PromotionsIcon, { label: 'PromotionsIconWrapper' })`
    ${setIconInMenu()};
`;

export const LiveCasinoIconWrapper = styled(CasinoTableGamesIcon, { label: 'LiveCasinoIconWrapper' })`
    ${(): SerializedStyles => setIconInMenu()};
`;

export const CasinoLucky6IconWrapper = styled(CasinoLucky6Icon, { label: 'CasinoLucky6IconWrapper' })`
    ${(): SerializedStyles => setIconInMenu()};
`;

export const BingoIconWrapper = styled(CasinoLucky6Icon, { label: 'BingoIconWrapper' })`
    ${(): SerializedStyles => setIconInMenu()};
`;
