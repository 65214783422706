import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useCommon } from 'src/domains/common/Common';
import { RaceSportType } from 'src/domains/sportsbook/webview/components/racesSlider/RacesSlider.state';
import { SportRacesTypeParamType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';
import { NewSportRacingContent } from 'src/domains/sportsbook/webview/components/racing/NewSportRacingContent';
import { SportRacingContent } from 'src/domains/sportsbook/webview/components/racing/SportRacingContent';

interface PropsType {
    sport: RaceSportType;
    filterType: SportRacesTypeParamType | null;
    eventId: EventId | null;
}

export const NewRacingPage = observer('NewRacingPage', (props: PropsType) => {
    const { sport, eventId, filterType } = props;

    const common = useCommon();
    const config = ConfigComponents.get(common);

    if (config.config.nextRacesNew === true) {
        return (
            <NewSportRacingContent
                sport={sport}
                eventId={eventId}
                filterType={filterType}
            />
        );
    }
    return (
        <SportRacingContent
            sport={sport}
            eventId={eventId}
            filterType={filterType}
        />
    );
});
