import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { AppState } from 'src/appState/AppState';
import {
    TableWrapper,
    TableRow,
    TableCol,
    GroupWrapper,
    GroupHeader,
    Button,
} from 'src/domains/layouts/webview/components/debugPanel/DebugPanel.style';
import { CollectionType } from 'src_common/playwright/types/casino/categoriesAndGamesResponse';

interface PropsType {
    appState: AppState;
    collection: CollectionType | null;
    tabId: string | null;
}

export const GamesInspector = observer('GamesInspector', ({ appState, collection, tabId }: PropsType) => {
    if (collection === null) {
        return <span>Open collection page (virtuals | casino | live-casino).</span>;
    }

    const casinoPageState = appState.appCasinoState.casinoPageState.get(collection).get(tabId);

    if (collection !== 'virtuals' && casinoPageState.navigationState.isHomeActive) {
        return <span>Casino Home. Open any category.</span>;
    }

    const gameModels =
        collection === 'virtuals'
            ? casinoPageState.navigationState.gamesListToDisplayNonCategorized
            : casinoPageState.navigationState.gamesListToDisplay;

    if (gameModels === 'loading') {
        return <span>Loading...</span>;
    }

    return (
        <>
            <GroupWrapper>
                <GroupHeader>
                    Collection: {collection} | TabId: {tabId}
                </GroupHeader>
            </GroupWrapper>

            <TableWrapper>
                <TableRow isHeadline={true}>
                    <TableCol>ID</TableCol>
                    <TableCol>Name</TableCol>
                    <TableCol>Provider</TableCol>
                    <TableCol>Launch Game ID</TableCol>
                    <TableCol>Studio ID</TableCol>
                    <TableCol>Studio Name</TableCol>
                    <TableCol>Is Favourite?</TableCol>
                    <TableCol>Action</TableCol>
                </TableRow>
                {gameModels.map((gameModel) => {
                    return (
                        <TableRow key={gameModel.id}>
                            <TableCol>{gameModel.id}</TableCol>
                            <TableCol>{gameModel.name}</TableCol>
                            <TableCol>{gameModel.provider}</TableCol>
                            <TableCol>{gameModel.launchGameId}</TableCol>
                            <TableCol>{gameModel.studioId}</TableCol>
                            <TableCol>{gameModel.studioName}</TableCol>
                            <TableCol>{gameModel.isFavourite ? 'y' : 'n'}</TableCol>
                            <TableCol>
                                <Button
                                    type='button'
                                    onClick={(): void => gameModel.startGame()}
                                >
                                    Run
                                </Button>
                                <Button
                                    type='button'
                                    onClick={(): void => gameModel.handleLikeAction()}
                                >
                                    Fav
                                </Button>
                            </TableCol>
                        </TableRow>
                    );
                })}
            </TableWrapper>
        </>
    );
});
