import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { WithdrawJourney, InfoPage } from 'src/domains/players/webview/components/WithdrawAndDeposit';
import { useAppStateContext } from 'src/appState/AppState';
import { WithdrawPaysafeJourney } from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdrawPaysafe/WithdrawPaysafeJourney';

export const WithdrawTab = observer('WithdrawProcedure', () => {
    const {
        appLayoutsState: {
            configComponents: { config },
        },
    } = useAppStateContext();
    const { temporaryWithdrawalPage, usePaymentFormForUniverse, accountHelperMail } = config;

    if (temporaryWithdrawalPage) {
        return (
            <InfoPage
                accountHelperMail={accountHelperMail}
                infoPageType='withdrawal'
            />
        );
    }

    switch (usePaymentFormForUniverse.type) {
        case 'realex':
            return <WithdrawJourney />;
        case 'paysafe':
            return <WithdrawPaysafeJourney />;
    }
});
