import { AccountModel } from './AccountModel';
import { computed, action, makeObservable } from 'mobx';
import { MobxMapAutoNew } from 'src_common/common/mobx-utils/MobxMapAutoNew';
import { FeatureState } from 'src/domains/layouts/config/features/featureState/FeatureState';
import { loginUser } from 'src/domains/players/state/accountState/loginUser';
import { GoogleTagManagerState } from 'src/domains/layouts/state/googleState/GoogleTagManagerState';
import { LoginResponseType, Session } from 'src_common/sdk/session';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { UsersState } from 'src/domains/players/state/UsersState';
import { WithdrawalsListState } from 'src/domains/players/state/WithdrawalsListState';
import { TrpcClient } from 'src/appState/TrpcClient';
import { AmountPrecision } from 'src_common/common/amount/AmountPrecision';
import { setCookie } from 'src/domains/layouts/config/config';
import { Common } from 'src/domains/common/Common';
import { ExternalApi } from 'src/domains/layouts/state/externalApi/ExternalApi';

export const loginUserWithGeo = (
    common: Common,
    email: string,
    password: string,
    type: 'login' | 'registration'
): Promise<LoginResponseType> => {
    const featureState = FeatureState.get(common);
    const googleTagManager = GoogleTagManagerState.get(common);

    const disable_geo = featureState.allowCasinoInIOSWrapperNew ? undefined : true;
    return loginUser(common.session, disable_geo, email, password, type, googleTagManager);
};

export class AccountState {
    //Please do not move until new version of Mobile App - contact Layouts team LT-848
    private readonly session: Session;

    private ref: HTMLElement | null = null;
    private readonly accountMap: MobxMapAutoNew<number, AccountModel>;
    private readonly trpcClient: TrpcClient;
    private readonly starRouter: StarRouter;

    public constructor(
        private readonly common: Common,
        public readonly usersState: UsersState,
        withdrawalsListState: WithdrawalsListState,
        private readonly amountPrecision: AmountPrecision
    ) {
        makeObservable(this);
        this.session = common.session;
        this.starRouter = StarRouter.get(common);

        this.accountMap = new MobxMapAutoNew(
            (userId: number) =>
                new AccountModel(
                    usersState,
                    userId,
                    this.starRouter,
                    withdrawalsListState,
                    this.trpcClient,
                    this.amountPrecision,
                    this.common
                )
        );
        this.trpcClient = common.trpcClient;
    }

    /**
     * @deprecated - please remove this method
     */
    @computed public get account(): AccountModel | null {
        const userId = this.session.userId;
        if (userId === null) {
            return null;
        }

        return this.accountMap.get(userId);
    }

    @computed public get ipUser(): string | null {
        return this.session.ipUser;
    }

    @action public setRef = (ref: HTMLElement | null): void => {
        this.ref = ref;
    };

    @action public scrollAccountTab = (): void => {
        if (this.ref !== null) {
            this.ref.scrollTo(0, 0);
        }
    };

    @action public loginUser = (
        email: string,
        password: string,
        type: 'login' | 'registration'
    ): Promise<LoginResponseType> => {
        return loginUserWithGeo(this.common, email, password, type);
    };

    //Please do not move until new version of Mobile App - contact Layouts team LT-848
    @action public handleLogout = async (): Promise<void> => {
        const resp = await this.trpcClient.client.signIn.logOut.mutate();

        if (resp.responseStatus === 'error' || resp.responseStatus === 'error-from-be') {
            console.error(resp.body);
            return;
        }
        setCookie('website.sid', resp.newJwt, 120);
        this.starRouter.emitEventLogoutClick();
        // For mobile app don't do reload - app is reloading differently
        if (ExternalApi.get(this.common).isMobileAppLogout === false) {
            window.location.reload();
        }
    };
}
