import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { Switch } from 'src/domains/layouts/webview/components/Switch';
import { FormNotification, InputsWrapper, SingleInput } from './DepositLimit.style';
import { PopupInfo, SignupPopupState } from 'src/domains/players/state/SignupPopupState';
import { DepositLimitState, ErrorMessageType } from './DepositLimit.state';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import {
    SectionWrapper,
    Description,
    HeadlineWrapper,
    HeadlineIconWrapper,
    SectionHeadline,
    InfoIconWrapper,
} from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/responsibleGambling/ResponsibleGambling.style';
import { useAppStateContext } from 'src/appState/AppState';
import { Amount } from 'src_common/common/amount/Amount';
import { useCommon } from 'src/domains/common/Common';

interface DepositLimitFormPropsType {
    state: DepositLimitState;
}

class DepositLimitPopupState {
    public constructor(
        private readonly signupPopupState: SignupPopupState,
        private readonly language: LanguagesState
    ) {}

    public renderDepositLimitPopup = (): void => {
        const popUpTitle = this.language.getTranslation(
            'signup-journey.popups.deposit-limit-popup-title',
            'Deposit Limit'
        );
        const popUpText1 = this.language.getTranslation(
            'signup-journey.popups.deposit-limit-popup-text-line1',
            'If you wish, you can limit how much we allow you to top up per day, per week or per month.'
        );
        const popUpText2 = this.language.getTranslation(
            'signup-journey.popups.deposit-limit-popup-text-line2',
            'Whichever limit is reached first in any given period will bring the top up restriction into effect.'
        );

        const popup = new PopupInfo(popUpTitle, false, popUpText1, popUpText2);
        this.signupPopupState.show(popup);
    };
}

const renderErrorMessage = (
    errorMessage: ErrorMessageType | null,
    maxMonthlyDepositLimit?: Amount | null
): JSX.Element | null => {
    if (errorMessage === 'unknown-error') {
        return (
            <FormNotification
                status='error'
                data-test='message-error'
            >
                <I18n
                    langKey='signup-journey.deposit-limit-error-message'
                    defaultText='Sorry, we seem to have a problem. Please try again.'
                />
            </FormNotification>
        );
    } else if (errorMessage === 'monthly-max-deposit-limit') {
        return (
            <FormNotification
                status='error'
                data-test='message-error'
            >
                <I18n
                    langKey='signup-journey.deposit-limit-error-message-max-deposit-limit'
                    defaultText='Your maximum monthly limit is {month}, Please enter a lower value'
                    params={{ month: maxMonthlyDepositLimit?.value ?? '0' }}
                />
            </FormNotification>
        );
    } else if (errorMessage === 'empty') {
        return (
            <FormNotification
                status='error'
                data-test='message-error'
            >
                <I18n
                    langKey='signup-journey.deposit-limits-error-message-empty-deposit'
                    defaultText='You have to choose at least one deposit limit to save.'
                />
            </FormNotification>
        );
    }
    return null;
};

const renderLimitDescription = (
    moneySymbol: string,
    dailyMaxDepositLimit: Amount | null,
    monthlyMaxDepositLimit: Amount | null
): JSX.Element | null => {
    if (monthlyMaxDepositLimit !== null && dailyMaxDepositLimit !== null) {
        return (
            <I18n
                langKey='signup-journey.deposit-max-limit.description'
                defaultText='Your maximum daily and monthly limits are set to {moneySymbol}{maxDailyDepositLimit} and {moneySymbol}{maxMonthlyDepositLimit} respectively. You can set your weekly and lower daily and monthly limits below. More information about limits and options to increase them can be found in our FAQ.'
                params={{
                    moneySymbol,
                    maxMonthlyDepositLimit: monthlyMaxDepositLimit.value,
                    maxDailyDepositLimit: dailyMaxDepositLimit.value,
                }}
            />
        );
    } else if (monthlyMaxDepositLimit !== null && dailyMaxDepositLimit === null) {
        return (
            <I18n
                langKey='signup-journey.monthlu-deposit-max-limit.description'
                defaultText='Your maximum monthly limit is set to {moneySymbol}{maxMonthlyDepositLimit}, you can set your daily, weekly, and lower monthly limit below. More information about limits and options to increase them can be found in our FAQ'
                params={{ moneySymbol, maxMonthlyDepositLimit: monthlyMaxDepositLimit.value }}
            />
        );
    } else {
        return (
            <I18n
                langKey='signup-journey.deposit-limit.description'
                defaultText='Set daily, weekly and monthly deposit limits.'
            />
        );
    }
};

export const DepositLimit = observer('DepositLimit', (props: DepositLimitFormPropsType) => {
    const common = useCommon();
    const languagesState = LanguagesState.get(common);

    const { appPlayersState } = useAppStateContext();
    const { signupPopupState, usersState } = appPlayersState;
    const [popupState] = React.useState(() => new DepositLimitPopupState(signupPopupState, languagesState));
    const { state } = props;

    const moneySymbol = usersState.moneySymbol;

    return (
        <SectionWrapper data-test='deposit-limit'>
            <HeadlineWrapper>
                <HeadlineIconWrapper>
                    <SectionHeadline data-test='section-headline'>
                        <I18n
                            langKey='signup-journey.deposit-limit.title'
                            defaultText='Turn on deposit limit'
                        />
                    </SectionHeadline>
                    <InfoIconWrapper onClick={popupState.renderDepositLimitPopup} />
                </HeadlineIconWrapper>
                <Switch
                    switched={state.isSwitched}
                    onChange={state.onSwitch}
                    version='secondary'
                />
            </HeadlineWrapper>
            <Description data-test='description'>
                {renderLimitDescription(moneySymbol, state.dailyMaxDepositLimit(), state.monthlyMaxDepositLimit)}
            </Description>
            {state.isSwitched ? (
                <InputsWrapper>
                    <SingleInput
                        type='text'
                        currency={moneySymbol}
                        placeholder={state.dailyMaxDepositLimit()?.value ?? '0.00'}
                        label={
                            <I18n
                                langKey='signup-journey.top-up-limits.daily-limit.label'
                                defaultText='Daily'
                            />
                        }
                        state={state.dailyValueState}
                        onChange={state.onChangeDaily}
                        onBlur={state.setDailyValue}
                        inputRef={state.setDailyInputRef}
                        onClick={state.handleClickDaily}
                        dataTest='deposit-limit-daily'
                    />
                    <SingleInput
                        type='text'
                        currency={moneySymbol}
                        placeholder='0.00'
                        label={
                            <I18n
                                langKey='signup-journey.top-up-limits.weekly-limit.label'
                                defaultText='Weekly'
                                data-test='weekly-label'
                            />
                        }
                        state={state.weeklyValueState}
                        onChange={state.onChangeWeekly}
                        onBlur={state.setWeeklyValue}
                        inputRef={state.setWeeklyInputRef}
                        onClick={state.handleClickWeekly}
                        dataTest='deposit-limit-weekly'
                    />
                    <SingleInput
                        type='text'
                        currency={moneySymbol}
                        placeholder={state.monthlyMaxDepositLimit?.value ?? '0.00'}
                        label={
                            <I18n
                                langKey='signup-journey.top-up-limits.monthly-limit.label'
                                defaultText='Monthly'
                            />
                        }
                        state={state.monthlyValueState}
                        onChange={state.onChangeMonthly}
                        onBlur={state.setMonthlyValue}
                        inputRef={state.setMonthlyInputRef}
                        onClick={state.handleClickMonthly}
                        dataTest='deposit-limit-monthly'
                    />
                </InputsWrapper>
            ) : null}
            {renderErrorMessage(state.errorMessage, state.monthlyMaxDepositLimit)}
        </SectionWrapper>
    );
});
