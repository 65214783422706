/* stylelint-disable */
import * as React from 'react';
import { Global, css, SerializedStyles } from '@emotion/react';
import { EmotionTheme } from 'src/domains/layouts/state/environmentState/EnvironmentState';
import { observer } from 'src/utils/mobx-react';
import { cookieBotWidget } from 'src/domains/layouts/ui/cookieBot.style';
import { setCustomScrollbar } from 'src/components/scroll/vertical';
import { isNewDesign } from 'src/utils/new-design';
import { intercomChatStyle } from './intercomChat.style';
import { RouteViewType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';

const emotionReset = (theme: EmotionTheme, isCasino: boolean): SerializedStyles => css`
    :root {
        --fontSize: 10px;
        color-scheme: light only;
    }

    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    *:focus {
        outline: none;
    }

    html {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        -webkit-text-size-adjust: 100%;
        font-size: var(--fontSize);
        text-rendering: optimizeLegibility;
        ${isNewDesign() ? setCustomScrollbar(theme, 'light') : null};
    }

    .is-static {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
    }

    body {
        background-color: ${theme.star.layoutGlobal.bgColor};
        color: ${theme.star.layoutGlobal.txtColor};
        font-family: ${theme.star.fontFamily.fontFamily};
        font-size: ${theme.star.fontSize.regular};
        font-weight: ${theme.star.fontWeight.regular};
        line-height: 1.5em;
        margin: 0;
        overflow-x: hidden;
        overscroll-behavior: none;
        padding: 0;
    }

    html,
    body,
    #root {
        height: 100%;
    }

    #root {
        position: relative; /* Probably to remove */
    }

    a {
        color: ${theme.star.layoutGlobal.linkColor};
        text-decoration: underline; /* To check */
        &:hover {
            text-decoration: none; /* To check */
        }
    }

    ol,
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    input[type='search']::-webkit-search-cancel-button {
        display: none;
    }

    b,
    strong {
        font-weight: ${theme.star.fontWeight.bold};
    }

    p {
        margin: 8px 0;
    }

    button,
    input,
    optgroup,
    select,
    textarea {
        font-family: inherit;
    }

    input, /* maybe we can remove it -> please check input number */
    select {
        appearance: none;
    }

    /* COOKIE BOT STYLES */
    ${cookieBotWidget(theme)}

    /* INTERCOM CHAT STYLES */
    ${intercomChatStyle(isCasino)}
`;

interface GlobalWrapperPropsType {
    theme: EmotionTheme;
    currentView: RouteViewType | null;
}

export const GlobalWrapper = observer('GlobalWrapper', (props: GlobalWrapperPropsType) => {
    const { theme, currentView } = props;
    const isCasino = currentView?.name === 'casino';

    return <Global styles={emotionReset(theme, isCasino)} />;
});
