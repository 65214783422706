import * as React from 'react';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { observer } from 'src/utils/mobx-react';
import { getTeamLogo } from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/NFLTeamLogos';
import { slug } from 'src/utils/deburr';
import bgPatternHexagon from 'src/domains/layouts/shared/assets/backgrounds/sports/bgPatternHexagon.webp';
import { DateTime } from 'src_common/utils/time/time';
import { getTeamName } from 'src/domains/sportsbook/webview/components/matchLead/PreMatch';
import {
    At,
    NFLIconWrapper,
    NflTeamLogotype,
    PreMatchParticipantNameNfl,
    PreMatchWrapperNfl,
} from 'src/domains/sportsbook/webview/components/matchLead/PreMatchNFL.style';
import {
    PreMatchDate,
    PreMatchParticipants,
    PreMatchTime,
} from 'src/domains/sportsbook/webview/components/matchLead/PreMatch.style';

const getTeamSlugName = (name: string | null | undefined): string => {
    if (name !== null && name !== undefined) {
        return slug(name);
    }

    return '';
};

interface PreMatchNFLPropsType {
    event: EventModel;
}

export const PreMatchNFL = observer('PreMatchNFL', ({ event }: PreMatchNFLPropsType) => {
    const startTime = DateTime.from(event.timeSettingsStartTime);

    const home = getTeamName(event, 'home');
    const away = getTeamName(event, 'away');

    const eventDateStart = startTime?.format('ddd Do MMM');
    const eventTimeStart = startTime?.format('HH:mm');

    return (
        <PreMatchWrapperNfl bgImage={bgPatternHexagon}>
            <PreMatchDate>
                {eventDateStart}
                <PreMatchTime>{eventTimeStart}</PreMatchTime>
            </PreMatchDate>

            <PreMatchParticipants>
                <PreMatchParticipantNameNfl>
                    {away === undefined ? '-' : away.name}
                    <NflTeamLogotype
                        src={getTeamLogo(getTeamSlugName(away?.name))}
                        alt={getTeamSlugName(away?.name)}
                    />
                </PreMatchParticipantNameNfl>
                <At>@</At>
                <PreMatchParticipantNameNfl>
                    <NflTeamLogotype
                        src={getTeamLogo(getTeamSlugName(home?.name))}
                        alt={getTeamSlugName(home?.name)}
                    />
                    {home === undefined ? '-' : home.name}
                </PreMatchParticipantNameNfl>
            </PreMatchParticipants>

            <NFLIconWrapper />
        </PreMatchWrapperNfl>
    );
});
