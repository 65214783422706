import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { action, makeObservable } from 'mobx';
import { useAppStateContext } from 'src/appState/AppState';
import {
    CenteredVerifyHeadline,
    VerifyInfo,
    HomeButton,
} from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/profileVerification/ProfileVerification.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    VerificationFailedWrapper,
    EmailLink,
    WarningTriangle,
    WarningTriangleIconWrapper,
} from './VerificationFailed.style';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { SignupType } from 'src/domains/players/webview/components/SignUp';
import { ModalState } from 'src/domains/layouts/webview/components/modals/Modal.state';
import { useCommon } from 'src/domains/common/Common';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';

export class VerificationFailedPageState {
    public constructor(
        public readonly signUpState: SignupType,
        public readonly router: StarRouter,
        public readonly modalState: ModalState
    ) {
        makeObservable(this);
    }

    @action public onHomeClick = async (): Promise<void> => {
        this.signUpState.state.rebuildState();
        this.router.redirectToHomepage();
        await this.modalState.closeAll();
    };
}

interface PropsType {
    signUpState: SignupType; // SignUpState;
}

export const VerificationFailedPage = observer('VerificationFailedPage', ({ signUpState }: PropsType) => {
    const common = useCommon();
    const { appPlayersState } = useAppStateContext();
    const { modalState } = appPlayersState;
    const config = ConfigComponents.get(common).config;
    const router = StarRouter.get(common);

    const [state] = useState(() => new VerificationFailedPageState(signUpState, router, modalState));
    const { signUpFooterMail } = config;

    return (
        <VerificationFailedWrapper>
            <WarningTriangle>
                <WarningTriangleIconWrapper />
            </WarningTriangle>
            <CenteredVerifyHeadline data-test='centered-verify-headline'>
                <I18n
                    langKey='signup-journey.verification-failed.headline'
                    defaultText='Sorry we are not able to verify you at this time'
                />
            </CenteredVerifyHeadline>
            <VerifyInfo data-test='verify-info'>
                <I18n
                    langKey='signup-journey.verification-failed.info1'
                    defaultText='Your account has been suspended pending verification.'
                />
            </VerifyInfo>
            <VerifyInfo data-test='verify-info'>
                <I18n
                    langKey='signup-journey.verification-failed.info2'
                    defaultText='Please contact customer services on'
                />
                <EmailLink href={`mailto: ${signUpFooterMail}`}> {signUpFooterMail}</EmailLink>
            </VerifyInfo>
            <HomeButton
                size='large'
                onClick={state.onHomeClick}
                dataTest='home-button'
            >
                <I18n
                    langKey='signup-journey.verification-failed.home-btn'
                    defaultText='Home'
                />
            </HomeButton>
        </VerificationFailedWrapper>
    );
});
