import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

const accountNavigationHeight = 56;

interface UiPropsType {
    topOffset: number;
}

export const AccountWrapperOld = withConfig(theme => styled('aside', { label: 'AccountWrapperOld' })<UiPropsType>`
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    left: auto;
    position: fixed;
    right: 0;
    top: 0;
    width: 0;
    z-index: ${theme.star.zIndexGlobal.lhsMenu};
    @media ${theme.star.mediaQuery.desktop} {
        height: calc(100% - ${({ topOffset }): string => `${topOffset}px`});
        top: ${({ topOffset }): string => `${topOffset}px`};
        width: 280px;
        box-shadow: 0 0 30px 10px ${theme.star.accountPanel.shadowColor};
    }
`);



export const AccountContentWrapperDesktop = withConfig(theme => styled('div', { label: 'AccountContentWrapperDesktop' })`
    display: none;
    @media ${theme.star.mediaQuery.desktop} {
        background-color: ${theme.star.accountPanel.bgColor};
        color: ${theme.star.accountPanel.txtColor};
        display: flex;
        flex-flow: column nowrap;
        flex: 1 1 0%;
        min-height: calc(100% - ${accountNavigationHeight}px);
    }
`);


export const AccountContent = withConfig(theme => styled('div', { label: 'AccountContent' })`
    flex: 1 1 0%;
    overflow-y: auto;
    overscroll-behavior: contain;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
        background-color: transparent;
        width: 8px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${theme.star.customScrollBar.dark.bgColor};
        border-radius: 8px;
    }
    scrollbar-color: ${theme.star.customScrollBar.dark.bgColorSecondary} transparent;
    scrollbar-width: thin;
`);

export const ScrollbarWrapper = styled('div', { label: 'ScrollbarWrapper' })`
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    overflow-y: auto;
    min-height: 0 !important; /* override from .css */
    height: auto !important; /* override from .css */
`;

interface AccountTabsType {
    isInvisible: boolean;
    topOffset: number;
    desktopNoRightSpace: boolean;
}

export const AccountTabs = withConfig(theme => styled('div', { label: 'AccountTabs' })<AccountTabsType>`
    ${({ isInvisible }): string => (isInvisible ? 'pointer-events: none;' : '')}
    height: 100%;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: ${theme.star.zIndexGlobal.rhsMenu};
    @media ${theme.star.mediaQuery.xMobile} {
        max-width: 375px;
    }
    @media ${theme.star.mediaQuery.desktop} {
        height: ${({ topOffset }): string => `calc(100vh - ${topOffset}px)`};
        top: ${({ topOffset }): string => `${topOffset}px`};
        right: ${({ desktopNoRightSpace }): string => (desktopNoRightSpace === true ? '0' : ' 280px')};
    }
`);

interface AccountOverlayPropsType {
    isVisible: boolean;
}

export const AccountOverlay = withConfig(theme => styled('div', { label: 'AccountOverlay' })<AccountOverlayPropsType>`
    background-color: ${theme.star.sidebar.bgColorSecondary};
    bottom: 0;
    cursor: pointer;
    left: 0;
    opacity: ${({ isVisible }): string => (isVisible ? '1' : '0')};
    position: fixed;
    right: 0;
    top: 0;
    transition: 0.2s ease opacity, 0.2s ease visibility;
    visibility: ${({ isVisible }): string => (isVisible ? 'visible' : 'hidden')};
    z-index: ${theme.star.zIndexGlobal.base};
`);

interface TabsWrapperPropsType {
    animationDuration: number;
    isEntering: boolean;
    isLeaving: boolean;
}

export const TabsWrapper = styled('div', { label: 'TabsWrapper' })<TabsWrapperPropsType>`
    backface-visibility: hidden;
    display: flex;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    flex-direction: row;
    ${({ isEntering, isLeaving, animationDuration }): string => {
        if (isEntering === true) {
            return `
                transition: transform ${animationDuration}s ease;
                transform: translateX(100%);
                flex-direction: row-reverse;
            `;
        }
        if (isLeaving === true) {
            return `
                transition: transform ${animationDuration}s ease;
                transform: translateX(-100%);
            `;
        }
        return `
            transition: transform 0s ease;
            transform: translateX(0);
        `;
    }}
`;

export const TabWrapper = withConfig(theme => styled('div', { label: 'TabWrapper' })`
    background-color: ${theme.star.accountDrawer.bgColor};
    color: ${theme.star.accountDrawer.txtColor};
    display: flex;
    flex-direction: column;
    flex: 0 0 100%;
    height: 100%;
    width: 100%;
    min-width: 100vw; /* prevent Safari bug - SP-4067 */
    @media ${theme.star.mediaQuery.xMobile} {
        min-width: 0;
    }
`);

export const EmptyTab = styled(TabWrapper, { label: 'EmptyTab' })`
    opacity: 0;
`;
