import * as React from 'react';
import { getWindowInnerWidth } from 'src_common/common/mobx-utils/Services/window';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { Search } from 'src/domains/layouts/webview/components/search/Search';
import { RenderPopularSportsAndPromotedEvents } from './NavigationSidebar/PopularSportSection';
import { NavigationSidebarSectionSupport } from './NavigationSidebar/NavigationSidebarSectionSupport';
import { NavigationSidebarSectionAZ } from './NavigationSidebar/NavigationSidebarSectionAZ';
import { NavigationSidebarSectionCasino } from './NavigationSidebar/NavigationSidebarSectionCasino';
import { NavigationSidebarSectionRecentlyViewed } from './NavigationSidebar/NavigationSidebarSectionRecentlyViewed';
import {
    HeaderMainLinksSidebarDefault,
    MainNavigationWrapper,
    SidebarCloseIconWrapper,
    NavigationSidebarContent,
    HamburgerMenuTitle,
    SidebarSlideIconWrapper,
    SidebarSearchWrapper,
    StickyNavigationWrapper,
} from './NavigationSidebar/NavigationSidebar.style';
import { useState } from 'react';
import { computed, makeObservable } from 'mobx';
import { SportsList } from 'src/domains/layouts/state/sportsList/SportsList';
import { PopularSportState } from 'src/domains/layouts/state/popularSportState/PopularSportState';
import { SidebarLoaderComponent } from 'src/domains/layouts/webview/modules/layoutSkeleton/NavigationSidebar/sidebarLoaderComponent/SidebarLoaderComponent';
import { RouteViewType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { CollectionType } from 'src/domains/casino/shared/Types';
import { NavigationSidebarLanguage } from './NavigationSidebar/NavigationSidebarLanguage';

export { SidebarCloseIconWrapper, SidebarSlideIconWrapper };

class NavigationSidebarSectionState {
    public sportsList: SportsList;
    public popularSportState: PopularSportState;

    public constructor(
        private readonly allSportsLength: () => number,
        sportsList: SportsList,
        popularSportState: PopularSportState
    ) {
        makeObservable(this);
        this.sportsList = sportsList;
        this.popularSportState = popularSportState;
    }

    @computed public get notEmptyportsLength(): number {
        return this.sportsList.translatedSports.length;
    }

    @computed public get isLoading(): boolean {
        return this.allSportsLength() === 0 || this.notEmptyportsLength <= this.allSportsLength() / 2;
    }
}

const showSidebarMainLinks = (currentView: RouteViewType): React.ReactNode => {
    const widthInner = getWindowInnerWidth();
    return widthInner !== null && widthInner < 1200 ? (
        <HeaderMainLinksSidebarDefault
            languageWidth={0}
            onSidebar
            currentView={currentView}
        />
    ) : null;
};

interface CasinoNavigationSidebarPropsType {
    navigationType: CollectionType;
}

const NavigationSidebarCasino = observer(
    'NavigationSidebarCasino',
    ({ navigationType }: CasinoNavigationSidebarPropsType) => (
        <NavigationSidebarContent>
            <NavigationSidebarSectionCasino navigationType={navigationType} />
            <NavigationSidebarSectionSupport withWrapper={false} />
        </NavigationSidebarContent>
    )
);

const NavigationSidebarNonCasino = observer('NavigationSidebarNonCasino', () => {
    const { appLayoutsState } = useAppStateContext();
    const { getAllSportsLength, sportsList, popularSportState } = appLayoutsState;

    const [state] = useState(
        () => new NavigationSidebarSectionState(getAllSportsLength, sportsList, popularSportState)
    );

    if (state.isLoading && sportsList.loading) {
        return <SidebarLoaderComponent />;
    }

    return (
        <NavigationSidebarContent>
            <RenderPopularSportsAndPromotedEvents />
            <NavigationSidebarSectionRecentlyViewed />
            <NavigationSidebarSectionAZ />
            <NavigationSidebarSectionSupport withWrapper={true} />
            <NavigationSidebarLanguage />
        </NavigationSidebarContent>
    );
});

interface PropsType {
    currentView: RouteViewType;
    isSearchActive: boolean;
    navigationType: CollectionType | 'global';
}

export const NavigationSidebar = observer(
    'NavigationSidebar',
    ({ currentView, isSearchActive, navigationType }: PropsType) => {
        const { appLayoutsState } = useAppStateContext();
        const { breakpointsState, searchState } = appLayoutsState;

        const isLargeDesktop = breakpointsState.largeDesktop.isBiggerOrEq;
        const isOpen = appLayoutsState.overlay.isMenuOpen;
        const isSearchBarDesktopOpened = searchState.isShow;
        return (
            <MainNavigationWrapper isOpen={isOpen}>
                <StickyNavigationWrapper data-test='sticky-navigation-wrapper'>
                    {isSearchActive === false ? (
                        <HamburgerMenuTitle>
                            {isLargeDesktop === false ? (
                                <>
                                    Menu
                                    <SidebarCloseIconWrapper onClick={appLayoutsState.overlay.toggleMenu} />
                                </>
                            ) : null}
                        </HamburgerMenuTitle>
                    ) : (
                        <SidebarSearchWrapper isSearchBarDesktopOpened={isSearchBarDesktopOpened}>
                            {isLargeDesktop === false ? (
                                <SidebarSlideIconWrapper
                                    position='left'
                                    onClick={searchState.handleCloseAndRedirect}
                                />
                            ) : null}
                            <Search />
                        </SidebarSearchWrapper>
                    )}
                    {showSidebarMainLinks(currentView)}
                    {navigationType === 'global' ? (
                        <NavigationSidebarNonCasino />
                    ) : (
                        <NavigationSidebarCasino navigationType={navigationType} />
                    )}
                </StickyNavigationWrapper>
            </MainNavigationWrapper>
        );
    }
);
