import { Common } from 'src/domains/common/Common';
import { ConfigType } from './types';
import { LocalStorageState } from 'src/domains/layouts/state/localStorage/LocalStorageState';
import { AmountPrecision } from 'src_common/common/amount/AmountPrecision';
import { AutoWeakMap } from 'src_common/common/mobx-utils/AutoWeakMap';

export class ConfigComponents {
    public static get = AutoWeakMap.create((common: Common) => new ConfigComponents(common));
    public constructor(private readonly common: Common) {}

    public get config(): ConfigType {
        if (this.common.isBrowser) {
            const localStorageState = LocalStorageState.get(this.common);

            return {
                ...this.common.features,
                ...localStorageState.debugPanelConfig.getValue(),
            };
        } else {
            return this.common.features;
        }
    }

    public get precision(): AmountPrecision {
        return new AmountPrecision(2);
    }
}
