import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { validateRequireCheckbox } from 'src/domains/players/webview/components/ValidatorsNew';
import { FormModel, Result } from 'src_common/common/mobx-utils/Form2/FormModel';
import { ContactPreferencesType } from 'src/domains/layouts/config/features/types';
import { createAccountPrimary } from 'src_server/trpc/types';

type ContactPreferencesInnerType = createAccountPrimary.CreateAccountInputType['contactPreferences'];
type OffersContactPreferencesInputValuesType = 'yes' | 'no' | '';

const checkIsContactPreferenceRequired = (
    preference: ContactPreferencesType,
    contactPreferences: ContactPreferencesType[]
): boolean => {
    return contactPreferences.includes(preference);
};

const isRequired =
    (isRequired: boolean) =>
    (value: 'yes' | 'no' | ''): Result<OffersContactPreferencesInputValuesType> => {
        if (isRequired) {
            return validateRequireCheckbox(value);
        }

        return Result.createOk(value);
    };

interface OffersContactPreferencesType {
    email: OffersContactPreferencesInputValuesType;
    sms: OffersContactPreferencesInputValuesType;
    phone: OffersContactPreferencesInputValuesType;
    whatsApp: OffersContactPreferencesInputValuesType;
}

const initialCheckboxData: OffersContactPreferencesInputValuesType = '';

export class OffersContactPreferencesState {
    public emailState: FormInputState<OffersContactPreferencesInputValuesType, OffersContactPreferencesInputValuesType>;

    public smsState: FormInputState<OffersContactPreferencesInputValuesType, OffersContactPreferencesInputValuesType>;

    public phoneState: FormInputState<OffersContactPreferencesInputValuesType, OffersContactPreferencesInputValuesType>;

    public whatsappState: FormInputState<
        OffersContactPreferencesInputValuesType,
        OffersContactPreferencesInputValuesType
    >;

    public contactPreferencesGroup: FormModel<OffersContactPreferencesType>;
    public formatFormModel: FormModel<ContactPreferencesInnerType>;

    public constructor(private contactPreferences: ContactPreferencesType[]) {
        this.emailState = FormInputState.new(initialCheckboxData).map(
            isRequired(checkIsContactPreferenceRequired('email', this.contactPreferences))
        );
        this.smsState = FormInputState.new(initialCheckboxData).map(
            isRequired(checkIsContactPreferenceRequired('sms', this.contactPreferences))
        );
        this.phoneState = FormInputState.new(initialCheckboxData).map(
            isRequired(checkIsContactPreferenceRequired('phone', this.contactPreferences))
        );
        this.whatsappState = FormInputState.new(initialCheckboxData).map(
            isRequired(checkIsContactPreferenceRequired('whatsApp', this.contactPreferences))
        );

        this.contactPreferencesGroup = FormModel.group({
            email: this.emailState,
            sms: this.smsState,
            phone: this.phoneState,
            whatsApp: this.whatsappState,
        });

        this.formatFormModel = this.contactPreferencesGroup.map(
            (value: OffersContactPreferencesType): Result<ContactPreferencesInnerType> => {
                const out: ContactPreferencesInnerType = [];

                if (value.email === 'yes') {
                    out.push(createAccountPrimary.ContactPreferenceEnum.EMAIL);
                }

                if (value.sms === 'yes') {
                    out.push(createAccountPrimary.ContactPreferenceEnum.SMS);
                }

                if (value.phone === 'yes') {
                    out.push(createAccountPrimary.ContactPreferenceEnum.PHONE);
                }

                if (value.whatsApp === 'yes') {
                    out.push(createAccountPrimary.ContactPreferenceEnum.WHATSAPP);
                }

                return Result.createOk(out);
            }
        );
    }
}
