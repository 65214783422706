import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    ButtonSpinnerWrapper,
    ButtonUniversalWrapper,
} from 'src/domains/layouts/webview/components/common/button/Button.style';

export type ButtonVersionType = 'primary' | 'secondary-light' | 'secondary-dark';

interface PropsType {
    children: React.ReactNode;
    className?: string;
    dataTest?: string;
    disabled?: boolean;
    isLoading?: boolean;
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    size: 'large' | 'medium' | 'small' | 'xs';
    type?: 'button' | 'reset' | 'submit';
    version?: ButtonVersionType;
    id?: string;
}

export const Button = observer(
    'Button',
    ({
        children,
        className,
        dataTest,
        disabled = false,
        isLoading = false,
        onClick,
        size = 'xs',
        type = 'button',
        version,
    }: PropsType) => {
        return (
            <ButtonUniversalWrapper
                className={className}
                data-test={dataTest}
                data-test-button-disabled={disabled}
                disabled={disabled}
                isLoading={isLoading}
                onClick={onClick}
                size={size}
                type={type}
                version={version}
            >
                {isLoading ? <ButtonSpinnerWrapper size={size} /> : children}
            </ButtonUniversalWrapper>
        );
    }
);
