import * as t from 'io-ts';
import { z } from 'zod';

/* UNIVERSE TYPE */
export const UniverseIO = t.union([
    //star template
    t.literal('akbets'),
    t.literal('betzone'),
    t.literal('bresbet'),
    t.literal('dragonbet'),
    t.literal('energy'),
    t.literal('mcbookie'),
    t.literal('nebet'),
    t.literal('planetsportbet'),
    t.literal('rhino'),
    t.literal('star'),
    t.literal('gentlemanjim'),
    t.literal('pricedup'),
    t.literal('betwright'),
]);

/* UNIVERSE TYPE ZOD*/
export const UniverseZod = z.union([
    z.literal('akbets'),
    z.literal('betzone'),
    z.literal('bresbet'),
    z.literal('dragonbet'),
    z.literal('energy'),
    z.literal('mcbookie'),
    z.literal('nebet'),
    z.literal('planetsportbet'),
    z.literal('rhino'),
    z.literal('star'),
    z.literal('gentlemanjim'),
    z.literal('pricedup'),
    z.literal('betwright'),
]);

export type UniverseType = t.TypeOf<typeof UniverseIO>;

export const getAllUniverse = (): Array<UniverseType> => {
    return [
        'akbets',
        'betzone',
        'bresbet',
        'dragonbet',
        'energy',
        'mcbookie',
        'nebet',
        'planetsportbet',
        'rhino',
        'star',
        'gentlemanjim',
        'pricedup',
        'betwright',
    ];
};

/**
 * @deprecated - Please use the getAllUniverse function
 */
export const getAllUniverseStr = (): Array<string> => {
    return getAllUniverse();
};
