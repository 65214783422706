import { slug } from 'src/utils/deburr';
import DEFAULT from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/assets/logos/default-nfl-team.png';

import ArizonaCardinals from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/assets/logos/arizona-cardinals.png';
import AtlantaFalcons from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/assets/logos/atlanta-falcons.png';
import BaltimoreRavens from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/assets/logos/baltimore-ravens.png';
import BuffaloBills from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/assets/logos/buffalo-bills.png';
import CarolinaPanthers from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/assets/logos/carolina-panthers.png';
import ChicagoBears from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/assets/logos/chicago-bears.png';
import CincinnatiBengals from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/assets/logos/cincinnati-bengals.png';
import ClevelandBrowns from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/assets/logos/cleveland-browns.png';
import DallasCowboys from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/assets/logos/dallas-cowboys.png';
import DenverBroncos from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/assets/logos/denver-broncos.png';
import DetroitLions from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/assets/logos/detroit-lions.png';
import GreenBayPackers from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/assets/logos/green-bay-packers.png';
import HoustonTexans from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/assets/logos/houston-texans.png';
import IndianaPolisColts from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/assets/logos/indianapolis-colts.png';
import JacksonVilleJaguars from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/assets/logos/jacksonville-jaguars.png';
import KansasCityChiefs from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/assets/logos/kansas-city-chiefs.png';
import LasVegasRaiders from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/assets/logos/las-vegas-raiders.png';
import LosAngelesRams from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/assets/logos/los-angeles-rams.png';
import LosAngelesChargers from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/assets/logos/los-angeles-chargers.png';
import MiamiDolphins from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/assets/logos/miami-dolphins.png';
import MinnesotaVikings from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/assets/logos/minnesota-vikings.png';
import NewEnglandPatriots from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/assets/logos/new-england-patriots.png';
import NewOrleansSaints from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/assets/logos/new-orleans-saints.png';
import NewYorkGiants from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/assets/logos/new-york-giants.png';
import NewYorkJets from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/assets/logos/new-york-jets.png';
import PhiladelphiaEagles from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/assets/logos/philadelphia-eagles.png';
import PittsburghSteelers from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/assets/logos/pittsburgh-steelers.png';
import SanFrancisco from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/assets/logos/san-francisco-49ers.png';
import SeattleSeahawks from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/assets/logos/seattle-seahawks.png';
import TampaBayBuccaneers from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/assets/logos/tampa-bay-buccaneers.png';
import TennesseeTitans from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/assets/logos/tennessee-titans.png';
import Washington from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/assets/logos/washington.png';
import WashingtonCommander from 'src/domains/sportsbook/webview/components/eventsList/eventRowNFL/assets/logos/washington-commanders.png';

export const getTeamLogo = (name: string | null): string => {
    if (name !== null) {
        const slugName = slug(name);
        switch (slugName) {
            case 'arizona-cardinals':
                return ArizonaCardinals;
            case 'atlanta-falcons':
                return AtlantaFalcons;
            case 'baltimore-ravens':
                return BaltimoreRavens;
            case 'buffalo-bills':
                return BuffaloBills;
            case 'carolina-panthers':
                return CarolinaPanthers;
            case 'chicago-bears':
                return ChicagoBears;
            case 'cincinnati-bengals':
                return CincinnatiBengals;
            case 'cleveland-browns':
                return ClevelandBrowns;
            case 'dallas-cowboys':
                return DallasCowboys;
            case 'denver-broncos':
                return DenverBroncos;
            case 'detroit-lions':
                return DetroitLions;
            case 'green-bay-packers':
                return GreenBayPackers;
            case 'houston-texans':
                return HoustonTexans;
            case 'indianapolis-colts':
                return IndianaPolisColts;
            case 'jacksonville-jaguars':
                return JacksonVilleJaguars;
            case 'kansas-city-chiefs':
                return KansasCityChiefs;
            case 'las-vegas-raiders':
                return LasVegasRaiders;
            case 'los-angeles-rams':
                return LosAngelesRams;
            case 'los-angeles-chargers':
                return LosAngelesChargers;
            case 'miami-dolphins':
                return MiamiDolphins;
            case 'minnesota-vikings':
                return MinnesotaVikings;
            case 'new-england-patriots':
                return NewEnglandPatriots;
            case 'new-orleans-saints':
                return NewOrleansSaints;
            case 'new-york-giants':
                return NewYorkGiants;
            case 'new-york-jets':
                return NewYorkJets;
            case 'philadelphia-eagles':
                return PhiladelphiaEagles;
            case 'pittsburgh-steelers':
                return PittsburghSteelers;
            case 'san-francisco-49ers':
                return SanFrancisco;
            case 'seattle-seahawks':
                return SeattleSeahawks;
            case 'tampa-bay-buccaneers':
                return TampaBayBuccaneers;
            case 'tennessee-titans':
                return TennesseeTitans;
            case 'washington-football-team':
                return Washington;
            case 'washington-commanders':
                return WashingtonCommander;
        }
    }

    return DEFAULT;
};
