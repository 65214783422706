import { action, computed, observable, makeObservable } from 'mobx';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { WithdrawalsListState } from 'src/domains/players/state/WithdrawalsListState';
import { GoogleTagManagerState } from 'src/domains/layouts/state/googleState/GoogleTagManagerState';
import { PaymentMethodDropdownState } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/PaymentMethodDropdown.state';
import { UsersState } from 'src/domains/players/state/UsersState';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { Amount } from 'src_common/common/amount/Amount';
import {
    validateAmountRequire,
    validateAmountPattern,
    validateMinAmountNew,
} from 'src/domains/players/webview/components/ValidatorsNew';
import { WithdrawPaysafeSteps, WithdrawPaysafeStepsType } from './WithdrawPaysafeJourney';
import { TrpcClient } from 'src/appState/TrpcClient';
import { paysafeBillingInfoFieldsValidation } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/helpers/billingInfoFiledsValidation/paysafeBillingInfoFieldsValidation';
import { amountViewToValue } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/helpers/amountViewToValue';

export class WithdrawPaysafeFormState {
    @observable public withdrawErrorMessage: string | null = null;
    @observable public isWithdrawFormSubmitting = false;

    public stepsState: WithdrawPaysafeSteps;

    public amountState: FormInputState<string, Amount>;

    public constructor(
        private readonly trpcClient: TrpcClient,
        private readonly usersState: UsersState,
        private readonly language: LanguagesState,
        private readonly withdrawalsListState: WithdrawalsListState,
        private readonly googleTagManager: GoogleTagManagerState,
        private readonly paymentMethodDropdownState: PaymentMethodDropdownState,
        private readonly minWithdrawAmount: Amount
    ) {
        makeObservable(this);
        this.stepsState = new WithdrawPaysafeSteps();
        this.amountState = FormInputState.new('')
            .map(validateAmountRequire)
            .map(validateAmountPattern)
            .map(
                validateMinAmountNew(
                    this.minWithdrawAmount,
                    this.language.getTranslation(
                        'errors.min-withdraw-new',
                        'Minimum withdraw amount is {currencySymbol}{minValue}',
                        { currencySymbol: this.usersState.moneySymbol, minValue: this.minWithdrawAmount.value }
                    )
                )
            );
    }

    @computed public get showBalance(): string | null {
        const withdrawableBalance = this.usersState.walletData.valueReady?.withdrawableBalance;

        if (withdrawableBalance !== undefined) {
            return this.usersState.money(new Amount(withdrawableBalance));
        }
        return null;
    }

    public handleAmountInputChange = (event: React.SyntheticEvent<HTMLInputElement>): void => {
        const value = event.currentTarget.value;
        const formatValue = amountViewToValue(value);
        this.amountState.setValue(formatValue);
    };

    public setAmount = (): void => {
        const amount = parseFloat(this.amountState.value);
        if (isNaN(amount) === true || amount === 0) {
            return this.amountState.setValue('');
        }
        return this.amountState.setValue(amount.toFixed(2));
    };

    public handlePaymentAdditionsChange = (amount: Amount): void => {
        this.amountState.setValue(amount.value);
        this.amountState.setAsVisited();
    };

    @action public submitWithdrawForm = async (): Promise<void> => {
        this.amountState.setAsVisited();

        this.isWithdrawFormSubmitting = true;
        this.withdrawErrorMessage = null;

        if (
            this.paymentMethodDropdownState.selectedPaymentMethod === undefined ||
            this.amountState.result.value.type === 'error'
        ) {
            this.isWithdrawFormSubmitting = false;
            return;
        }

        try {
            const customer = await this.usersState.basicData.fetch();
            const billingInfo = paysafeBillingInfoFieldsValidation(customer);

            if (billingInfo?.type === 'error' || billingInfo?.data === undefined) {
                this.isWithdrawFormSubmitting = false;
                this.stepsState.redirectToFailureView('serverIssue');
                return;
            }

            const date = new Date().toUTCString();
            const withdrawalResponse = await this.trpcClient.client.paysafeRouter.withdrawalsPaysafe.mutate({
                merchantRefNum: `${billingInfo.data.merchantCustomerId.toString()}-${date}`,
                amount: this.amountState.result.value.data.value,
                currencyCode: billingInfo.data.currency,
                paymentHandleToken: this.paymentMethodDropdownState.selectedPaymentMethod.providerReference ?? '',
                description: 'Withdrawal',
                paymentMethod: this.paymentMethodDropdownState.selectedPaymentMethod,
            });

            if (withdrawalResponse.responseStatus === 'error') {
                this.isWithdrawFormSubmitting = false;
                this.stepsState.redirectToFailureView('serverIssue');
                return;
            }

            this.googleTagManager.addWithdrawnTag(this.amountState.result.value.data.value);
            await this.withdrawalsListState.refresh();
            this.amountState.reset();
            this.isWithdrawFormSubmitting = false;
            this.stepsState.redirectToPending();
        } catch (e) {
            this.isWithdrawFormSubmitting = false;
            this.stepsState.redirectToFailureView('serverIssue');
        }
    };

    @computed public get currentStep(): WithdrawPaysafeStepsType {
        const methods = this.paymentMethodDropdownState.savedPaymentMethods;
        const status = this.paymentMethodDropdownState.savedPaymentMethodResource.get();

        const isCardNoSaved = (methods === null || methods.length === 0) && status.type === 'ready';

        if (isCardNoSaved) {
            return {
                type: 'failure-view',
                failureType: 'noPaymentMethods',
            };
        }

        return this.stepsState.step;
    }

    @computed public get isBtnDisabled(): boolean {
        const withdrawableBalance = this.usersState.walletData.valueReady?.withdrawableBalance;
        if (withdrawableBalance === undefined) {
            return true;
        }

        if (this.amountState.result.value.type === 'error') {
            return true;
        }
        const withdrawableBalanceAmount = new Amount(withdrawableBalance);

        if (this.amountState.result.value.data.isGreaterThan(withdrawableBalanceAmount)) {
            return true;
        }

        if (this.paymentMethodDropdownState.selectedPaymentMethod === undefined) {
            return true;
        }

        return false;
    }
}
