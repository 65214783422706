import React, { useState } from 'react';
import { DateRangeForm } from 'src/domains/players/webview/components/Account/common/dateRangeForm/DateRangeForm';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useAppStateContext } from 'src/appState/AppState';
import { observer } from 'src/utils/mobx-react';
import { HistoryFiltersNew } from 'src/domains/players/webview/components/Account/common/historyFilters/HistoryFilters';
import { DateRangeFormWrapper } from 'src/domains/players/webview/components/Account/betHistoryTab/BetHistoryTab.style';
import { BetsList } from 'src/domains/players/webview/components/Account/betHistoryTab/betsList/BetsList';
import { BetHistoryTabState, TimeRangeBetHistoryTabState } from './BetHistoryTab.state';
import { useCommon } from 'src/domains/common/Common';

export const BetHistoryTab = observer('BetHistoryTab', () => {
    const { appLayoutsState, appPlayersState } = useAppStateContext();
    const {
        languagesState,
        configComponents: { config },
        sdkCustomer,
    } = appLayoutsState;
    const { usersState } = appPlayersState;
    const { userId } = sdkCustomer.session;
    const common = useCommon();

    if (userId === null) {
        return null;
    }

    const [state] = useState(() => new BetHistoryTabState(usersState, userId, common));
    const [timeRangeFiltersState] = useState(() => new TimeRangeBetHistoryTabState(state, languagesState));
    const isDateFilter = timeRangeFiltersState.betHistoryFilterType === 'date-range';

    return (
        <>
            <HistoryFiltersNew
                filters={timeRangeFiltersState.filters}
                selected={timeRangeFiltersState.betHistoryFilterType}
                skin={config.filtersVersion.transactionHistory}
            />
            {isDateFilter === true ? (
                <DateRangeFormWrapper>
                    <DateRangeForm
                        key='date-range'
                        label={
                            <I18n
                                langKey='account.bet-history.date-range.label'
                                defaultText='Date Range'
                            />
                        }
                        state={state.formState}
                        onSubmit={state.submitRange}
                        status={state.userBetsRequestStatus}
                    />
                </DateRangeFormWrapper>
            ) : null}
            <BetsList
                betsResult={state.userBets}
                bonuses={state.userBonuses}
                status={state.userBetsRequestStatus}
            />
        </>
    );
});
