import * as React from 'react';
import { UniverseType } from 'src_common/common/universe';
import { registerNetworRequest } from 'src_common/utils/Idle';
import { Resource } from 'src_common/common/mobx-utils/Resource';
import { assertNever } from 'src_common/common/assertNever';
import { observer } from 'src/utils/mobx-react';
import { AutoMap } from 'src_common/common/mobx-utils/AutoMap';
import { featuresDev } from './universesFeatureDev';
import { UniverseModuleType } from 'src/domains/common/universes.type';
import { useCommon } from './Common';
import { ConfigFeaturesType } from './configFeatures/ConfigFeatures';

const imports: Record<UniverseType, () => Promise<{ universeModule: UniverseModuleType }>> = {
    akbets: () => import('./universes/akbets/akbets'),
    betzone: () => import('./universes/betzone/betzone'),
    bresbet: () => import('./universes/bresbet/bresbet'),
    dragonbet: () => import('./universes/dragonbet/dragonbet'),
    energy: () => import('./universes/energy/energy'),
    gentlemanjim: () => import('./universes/gentlemanjim/gentlemanjim'),
    mcbookie: () => import('./universes/mcbookie/mcbookie'),
    nebet: () => import('./universes/nebet/nebet'),
    planetsportbet: () => import('./universes/planetsportbet/planetsportbet'),
    rhino: () => import('./universes/rhino/rhino'),
    star: () => import('./universes/star/star'),
    pricedup: () => import('./universes/pricedup/pricedup'),
    betwright: () => import('./universes/betwright/betwright'),
};

export type ThemeColorsStarType = UniverseModuleType['themeColors'];

export const getUniverseModule = async (
    universe: UniverseType,
    isProdMode: boolean
): Promise<Omit<UniverseModuleType, 'features'>> => {
    const load = imports[universe];
    const module = (await load()).universeModule;

    if (isProdMode) {
        return module;
    }

    return module;
};

//This function can only be called once, on the server side
export const getUniverseFeatures = async (universe: UniverseType, isProdMode: boolean): Promise<ConfigFeaturesType> => {
    const load = imports[universe];
    const module = (await load()).universeModule;
    const features = module.features;

    if (isProdMode) {
        return features;
    }

    const featuresDevUniverse = featuresDev[universe];

    return {
        ...features,
        ...featuresDevUniverse,
    };
};

class ModuleLoading {
    private readonly data: AutoMap<[UniverseType, boolean], Resource<Omit<UniverseModuleType, 'features'>>>;

    public constructor() {
        this.data = new AutoMap(
            ([universe, isProdMode]) =>
                new Resource(async () => {
                    const unregister = registerNetworRequest();
                    try {
                        const module = await getUniverseModule(universe, isProdMode);
                        unregister();
                        return module;
                    } catch (error) {
                        unregister();
                        throw error;
                    }
                })
        );
    }

    public getModule(universe: UniverseType, isProdMode: boolean): Omit<UniverseModuleType, 'features'> {
        const result = this.data.get([universe, isProdMode]).get();

        switch (result.type) {
            case 'ready':
                return result.value;
            case 'loading':
                throw result.whenReady;
            case 'error':
                throw Error(`module loading error for universe=${universe}`);
            default:
                return assertNever('ModuleLoading.getModule', result);
        }
    }
}

const state = new ModuleLoading();

const useUniverseModuleContext = (): Omit<UniverseModuleType, 'features'> => {
    const common = useCommon();
    const env = common.envVariables;
    const module = state.getModule(env.universe, env.isProdMode);
    return module;
};

interface UniverseComponentPropsType {
    children: (UniverseModule: Omit<UniverseModuleType, 'features' | 'themeColors'>) => React.ReactElement;
}

export const UniverseComponent = observer('UniverseComponent', (props: UniverseComponentPropsType) => {
    const { children } = props;

    const InnerComponent = (): React.ReactElement => {
        const universeModule = useUniverseModuleContext();
        return children(universeModule);
    };

    return (
        <React.Suspense>
            <InnerComponent />
        </React.Suspense>
    );
});
