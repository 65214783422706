import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { DepositLimitType } from 'src/domains/players/webview/components/RollingDepositLimitPopup/RollingDepositLimitPopup.state';
import { Popup } from 'src/domains/players/webview/ui/Popup';
import { ButtonsGroup, Container, Header, Heading, Text } from './DeleteCardPopup.style';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { DeleteCardPopupState } from './DeleteCardPopup.state';

interface DeleteCardPopupPropType {
    deleteCardPopupState: DeleteCardPopupState;
}

export const DeleteCardPopup = observer(
    'DeleteCardPopup',
    ({ deleteCardPopupState }: DeleteCardPopupPropType): JSX.Element | null => {
        if (deleteCardPopupState.removePaymentMethodCardPopup.isOpen === false) {
            return null;
        }

        return (
            <Popup maxWidth='288px'>
                <Container data-test='delete-card-popup'>
                    <Header>
                        <Heading data-test='delete-card-popup-header'>
                            <I18n
                                langKey='delete-card-popup.header'
                                defaultText='Remove card '
                            />
                            {deleteCardPopupState.removePaymentMethodCardPopup.label}
                            <Text data-test='delete-card-popup-text'>
                                <I18n
                                    langKey='delete-card-popup.text'
                                    defaultText='This card will be permanently deleted from your account.'
                                />
                            </Text>
                        </Heading>
                    </Header>

                    <ButtonsGroup>
                        <Button
                            size='medium'
                            onClick={deleteCardPopupState.handleRemoveCard}
                            isLoading={deleteCardPopupState.isLoading}
                            dataTest='delete-card-popup-remove-button'
                        >
                            <I18n
                                langKey='delete-card-popup.button.remove.text'
                                defaultText='Remove card'
                            />
                        </Button>
                        <Button
                            size='medium'
                            disabled={deleteCardPopupState.isLoading}
                            onClick={deleteCardPopupState.handleClosePopup}
                            version='secondary-light'
                            dataTest='delete-card-popup-cancel-button'
                        >
                            <I18n
                                langKey='delete-card-popup.button.cancel.text'
                                defaultText='Cancel'
                            />
                        </Button>
                    </ButtonsGroup>
                </Container>
            </Popup>
        );
    }
);

export { DepositLimitType };
