import { autorun } from 'mobx';
import { AppState } from 'src/appState/AppState';
import { WebsocketV1 } from 'src/domains/layouts/state/websocketV1/WebsocketV1';

const TIMEOUT = 1000;

export const debounce = (callbackOriginal: () => void): (() => void) => {
    let timer: NodeJS.Timeout | null = null;

    return (): void => {
        if (timer !== null) {
            clearTimeout(timer);
        }

        timer = setTimeout(() => {
            callbackOriginal();
            timer = null;
        }, TIMEOUT);
    };
};

export const initWebsocketV1 = (appState: AppState): void => {
    const refreshInfoAboutAccount = async (): Promise<void> => {
        await appState.appPlayersState.accountState.account?.refreshAll();
    };

    const subscriptionForUser: Set<number> = new Set();

    const runSubscriptionForUser = (userId: number): void => {
        if (subscriptionForUser.has(userId)) {
            return;
        }
        subscriptionForUser.add(userId);

        console.info(`Run subscribe to websocketV1 for id ${userId}`);
        const websocketV1 = WebsocketV1.get(appState.common);
        websocketV1.subscribe(
            `${userId}:Wallet`,
            debounce(async () => {
                await refreshInfoAboutAccount();
            })
        );
    };

    autorun(() => {
        const { userId } = appState.common.session;
        if (userId !== null) {
            runSubscriptionForUser(userId);
        }
    });
};
