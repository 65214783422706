import { SerializedStyles, css } from '@emotion/react';
import styled from '@emotion/styled';
import { BannersBoxState } from 'src/domains/layouts/state/bannersBoxState/BannersBoxState';
import { EmotionTheme } from 'src/domains/layouts/state/environmentState/EnvironmentState';
import { withConfig } from 'src/withConfig';
import { assertNever } from 'src_common/common/assertNever';

export type AnimationType = 'top' | 'right' | 'bottom' | 'left' | 'dialog';

const getPosition = (visible: boolean, dock: AnimationType): SerializedStyles => {
    switch (dock) {
        case 'top':
            return visible
                ? css`
                      transform: translate(0, 0);
                  `
                : css`
                      transform: translate(0, -100%);
                  `;
        case 'right':
            return visible
                ? css`
                      transform: translateX(0);
                  `
                : css`
                      transform: translateX(100%);
                  `;
        case 'bottom':
            return visible
                ? css`
                      transform: translate(0, 0);
                  `
                : css`
                      transform: translate(0, 100%);
                  `;
        case 'left':
            return visible
                ? css`
                      transform: translateX(0);
                  `
                : css`
                      transform: translateX(-100%);
                  `;
        case 'dialog':
            return visible
                ? css`
                      transform: scale(1);
                  `
                : css`
                      transform: scale(0);
                  `;
        default:
            return assertNever('Missing dock: ', dock);
    }
};

interface LayerWrapperPropsType {
    css: SerializedStyles;
}

export const LayerWrapper = styled('div', { label: 'LayerWrapper' })<LayerWrapperPropsType>`
    ${({ css }): SerializedStyles => css};
`;

interface LayerContentPropsType {
    animation: AnimationType;
    visible: boolean;
    css: SerializedStyles;
}

export const LayerContent = withConfig(theme => styled('div', { label: 'LayerContent' })<LayerContentPropsType>`
    height: 100%;
    pointer-events: auto;
    position: absolute;
    transition: transform 0.3s cubic-bezier(0.22, 1, 0.36, 1);
    width: 100%;
    @media ${theme.star.mediaQuery.desktop} {
        transition-duration: 0.4s;
    }
    ${({ css }): SerializedStyles => css}
    ${({ visible, animation }): SerializedStyles => getPosition(visible, animation)};
`);

export const modalRhsMenu = (bannersBoxState: BannersBoxState, theme: EmotionTheme): SerializedStyles => css`
    inset: 0 0 0 auto;
    overflow: hidden;
    pointer-events: none;
    position: fixed;
    width: 100%;
    z-index: ${theme.star.zIndexGlobal.rhsMenu};

    @media ${theme.star.mediaQuery.xMobile} {
        width: 375px;
    }

    @media ${theme.star.mediaQuery.desktop} {
        right: 280px;
        top: ${bannersBoxState.boxHeight}px;
    }
`;
