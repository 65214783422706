import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useAppStateContext } from 'src/appState/AppState';
import { TopUpLimitsProcedureItem } from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/responsibleGambling/rollingNetDepositLimit/RollingNetDepositLimitItem';
import {
    RollingDepositLimitForm,
    TextSection,
    SectionHeader,
    SectionHeadline,
    FaqLink,
    InfoIconWrapper,
} from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/responsibleGambling/rollingNetDepositLimit/RollingNetDepositLimit.style';
import { SignupRollingNetDepositLimitState } from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/responsibleGambling/rollingNetDepositLimit/RollingNetDepositLimit.state';
import { LanguageTokenType } from 'src/domains/layouts/state/languagesState/LanguagesUtils';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { useCommon } from 'src/domains/common/Common';
import { renderMessage } from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/responsibleGambling/rollingNetDepositLimit/RollingNetDepositLimit';
import { PopupInfo, SignupPopupState } from 'src/domains/players/state/SignupPopupState';

class DepositLimitPopupState {
    public constructor(
        private readonly signupPopupState: SignupPopupState,
        private readonly language: LanguagesState
    ) {}

    public renderDepositLimitPopup = (): void => {
        const popUpTitle = this.language.getTranslation(
            'signup-journey.popups.deposit-limit-calendar-popup-title',
            'Deposit Limit'
        );
        const popUpText1 = this.language.getTranslation(
            'signup-journey.popups.deposit-limit-calendar-popup-text-line1',
            'If you wish, you can set limits for your net deposits (deposits minus withdrawals) for a calendar day, week or month.'
        );
        const popUpText2 = this.language.getTranslation(
            'signup-journey.popups.deposit-limit-calendar-popup-text-line2',
            'Whichever limit is reached first in any given period will bring the restriction into effect.'
        );

        const popup = new PopupInfo(popUpTitle, false, popUpText1, popUpText2);
        this.signupPopupState.show(popup);
    };
}

interface PropsType {
    state: SignupRollingNetDepositLimitState;
}

export const NetDepositLimitCalendar = observer('NetDepositLimitCalendar', ({ state }: PropsType) => {
    const common = useCommon();
    const {
        appPlayersState: { cmsDrawerPagesState, signupPopupState },
    } = useAppStateContext();
    const languagesState = LanguagesState.get(common);

    const [popupState] = React.useState(() => new DepositLimitPopupState(signupPopupState, languagesState));

    const limitsDescription = (): JSX.Element => {
        return languagesState.translateTokens(
            languagesState.getTranslation(
                'signup-journey.net-deposit-limits-calendar.description',
                'You can reduce your daily and monthly net deposit limits or set the weekly net deposit limit below. More information about the limits and options to increase them can be found in our [FAQ].'
            ),

            (singleParam: LanguageTokenType) => {
                if (singleParam.tag === 'FAQ') {
                    return (
                        <FaqLink
                            onClick={cmsDrawerPagesState.showHelpPage}
                            dataTest='rolling-net-deposit-limits-faq'
                        >
                            FAQ
                        </FaqLink>
                    );
                }
            }
        );
    };

    if (state.isLoading) {
        return (
            <I18n
                langKey='signup-journey.contact-preferences.loading'
                defaultText='Loading...'
            />
        );
    }

    return (
        <RollingDepositLimitForm>
            <SectionHeader
                isUppercase={false}
                data-test='rolling-net-deposit-limit-form-header'
            >
                <SectionHeadline>
                    <I18n
                        langKey='signup-journey.rolling-net-deposit-limits-calendar.responsible-gambling.rolling-header'
                        defaultText='Net deposit limits'
                    />
                </SectionHeadline>
                <InfoIconWrapper onClick={popupState.renderDepositLimitPopup} />
            </SectionHeader>
            <TextSection data-test='rolling-net-deposit-limits-content'>{limitsDescription()}</TextSection>

            <TopUpLimitsProcedureItem
                state={state.daily}
                label={
                    <I18n
                        langKey='signup-journey.net-deposit-limits-calendar.daily-limit.label'
                        defaultText='Daily'
                    />
                }
                dataTest='deposit-limit-1-day'
            />

            <TopUpLimitsProcedureItem
                state={state.weekly}
                label={
                    <I18n
                        langKey='signup-journey.net-deposit-limits-calendar.weekly-limit.label'
                        defaultText='Weekly'
                    />
                }
                dataTest='deposit-limit-7-days'
            />

            <TopUpLimitsProcedureItem
                state={state.monthly}
                label={
                    <I18n
                        langKey='signup-journey.net-deposit-limits-calendar.monthly-limit.label'
                        defaultText='Monthly'
                    />
                }
                dataTest='deposit-limit-30-days'
            />

            {renderMessage(state.infoMessage)}
        </RollingDepositLimitForm>
    );
});
