import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { Input } from 'src/domains/players/webview/components/form/input/Input';
import { withConfig } from 'src/withConfig';

export const ButtonSubmit = styled(Button, { label: 'ButtonSubmit' })`
    display: block;
    margin: 4px 0 16px 0;
    width: 100%;
`;

export const TopUpLimitsInput = styled(Input, { label: 'TopUpLimitsInput' })`
    margin: 0;
`;

export const RollingDepositLimitForm = styled('form', { label: 'RollingDepositLimitForm' })`
    margin: 0;
`;

export const TextSection = styled('p', { label: 'TextSection' })`
    line-height: 1.33;
    margin: 8px 0 32px;
`;

interface WrapperType {
    version?: 'beta' | 'default';
}
export const TPWrapper = withConfig(theme => styled('div', { label: 'TPWrapper' })<WrapperType>`
    display: flex;
    font-size: ${({ version }): string => version === 'beta' ? theme.star.fontSize.xSmall : theme.star.fontSize.small};
    justify-content: space-between;
    margin: ${({ version }): string => version === 'beta' ? '8px 0 16px 0' : '12px 0 24px 0'};
    line-height: ${({ version }): string => version === 'beta' ? '1.4' : '1.3333334'};
`);

interface SectionHeaderType {
    isUppercase: boolean;
}

export const SectionHeader = withConfig(theme => styled('h4', { label: 'SectionHeader' }) <SectionHeaderType>`
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.bold};
    margin: 0;
    text-transform: ${({ isUppercase }): string => isUppercase ? 'uppercase' : 'none'};
`);

export const AmountInputDescription = styled('div', { label: 'AmountInputDescription' })`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;
