import { AppState } from 'src/appState/AppState';
import queryString from 'query-string';
import { autorun } from 'mobx';
import { isResponseReferralSocket } from 'src/domains/sportsbook/betting/betting/getReferredBetSlipTypes';
import { starAppStateInitAddToBetslip } from './starAppStateInitAddToBetslip';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { WebsocketV1 } from 'src/domains/layouts/state/websocketV1/WebsocketV1';

const run = (callback: () => void): void => {
    callback();
};

export function mapBetsToAmount<T>(configComponents: ConfigComponents, bets: T): T {
    const elementsToTransform = [
        'potentialReturns',
        'stakePerLine',
        'totalStake',
        'operatorTotalStake',
        'operatorStakePerLine',
        'maxBet',
    ];
    // @ts-expect-error
    bets = bets.map((bet) => {
        for (const key of elementsToTransform) {
            let element = bet[key];
            console.log('mapBetsToAmount-element', element);
            if (element !== undefined && element !== null) {
                element = configComponents.precision.newFromAnything(element).value;
                bet[key] = element;
            }
        }
        return bet;
    });

    return bets;
}

export const starAppStateInit = (appState: AppState): void => {
    const urlParams = appState.env.isBrowser ? queryString.parse(window.location.search) : null;

    starAppStateInitAddToBetslip(appState, urlParams);

    run(() => {
        autorun((reaction) => {
            const { userId } = appState.common.session;
            const websocketV1 = WebsocketV1.get(appState.common);

            if (userId !== null) {
                websocketV1.subscribeOldVersionToDelete(`${userId}:RefBet`, async (data: unknown): Promise<void> => {
                    await appState.appPlayersState.accountState.account?.refreshAll();
                    // Temporary solution - to remove after ws v1

                    const referredBetsWithNewStakePerLine = mapBetsToAmount(
                        appState.appLayoutsState.configComponents,
                        // @ts-expect-error
                        data.body.referredBetslip.bets
                    );

                    const betsWithNewStakePerLine = mapBetsToAmount(
                        appState.appLayoutsState.configComponents,
                        // @ts-expect-error
                        data.body.bets
                    );
                    // @ts-expect-error
                    data.body.referredBetslip.bets = referredBetsWithNewStakePerLine;
                    // @ts-expect-error
                    data.body.bets = betsWithNewStakePerLine;
                    if (isResponseReferralSocket(data)) {
                        appState.appSportsBookState.betSlipState.referralState.handleBetReferralData(data);
                    } else {
                        // @ts-expect-error
                        appState.appSportsBookState.betSlipState.referralState.handleBetReferralData(data);
                    }
                    reaction.dispose();
                });
            }
        });
    });

    run(() => {
        let prevUser: number | null = null;
        autorun(() => {
            const userId = appState.common.session.userId;

            if (prevUser !== null && userId === null) {
                appState.appPlayersState.messageBox.runActionNotLogged();
            }

            prevUser = userId;
        });
    });

    run(() => {
        const dispose = autorun(() => {
            const isStatic = appState.appLayoutsState.isStaticForView;
            if (isStatic) {
                document.documentElement.classList.add('is-static');
            } else {
                document.documentElement.classList.remove('is-static');
            }
        });

        return (): void => {
            dispose();
        };
    });
};
