import styled from '@emotion/styled';
import Select from 'react-select';
import { paymentDropDown } from 'src/domains/players/webview/components/WithdrawAndDeposit/components/paymentMethodDropdown/paymentDropDown.style';
import { withConfig } from 'src/withConfig';
import { TrashIcon } from 'src/domains/layouts/shared/assets/icons/TrashIcon';

export const SelectWrapper = withConfig(theme => styled(Select, { label: 'SelectWrapper' })`
    /* External library payment custom drop down */
    ${paymentDropDown(theme)}
`);

interface SelectInputContainerPropsType {
    hasPrefixes?: boolean;
}

export const SelectInputContainer = styled('div', { label: 'SelectInputContainer' })<SelectInputContainerPropsType>`
    margin: 24px 0 0;
`;

export const SelectInputLabel = withConfig(theme => styled('label', { label: 'SelectInputLabel' })`
    color: ${theme.star.form.light.txtColor};
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.medium};
    margin-top: 16px;
`);

export const ExpiresContainer = withConfig(theme => styled('div', { label: 'ExpiresContainer' })`
    font-size: ${theme.star.fontSize.xSmall};
    display: flex;
    align-items: center;
`);

export const StyledTrashIcon = styled(TrashIcon, { label: 'StyledTrashIcon' })`
    fill: currentcolor;
    margin-left: 8px;
    width: 12px;
    height: 12px;
    cursor: pointer;
`;
