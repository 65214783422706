import * as React from 'react';

import {
    BackButton,
    BackButtonWrapper,
    EventNavigation,
} from 'src/domains/layouts/webview/components/eventNavigation/CarouselSelection.style';
import { CarouselEventNavigation } from 'src/domains/layouts/webview/components/eventNavigation/CarouselEventNavigation';
import { EventsTableMobX, NewRacingPage } from 'src/domains/sportsbook/shared/Components';
import {
    getHeaderOptions,
    getDefaultHeaderOptions,
} from 'src/domains/layouts/webview/components/sports/general/config';
import { LayoutSkeleton } from 'src/domains/layouts/webview/modules/LayoutSkeleton';
import { observer } from 'src/utils/mobx-react';
import { SportEventsList } from 'src/domains/layouts/webview/components/sportEventsList/SportEventsList';
import { SportHeaderStar } from 'src/domains/layouts/webview/components/sportHeader/SportHeader';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import {
    PaddingWrapper,
    PremierLeagueIframe,
    PremierLeagueIframeWrapper,
    SectionWrapper,
    Wrapper,
} from './SportPage.style';

import { useAppStateContext } from 'src/appState/AppState';
import { SportRouteType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { RightHandSideViewType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';
import { SeoPagesContent } from 'src/domains/layouts/webview/components/seoPages/SeoPages';

interface SportPageGeneralPropsType {
    sportId: string;
    headerStyleLevel: 'sportLevel' | 'competitionLevel';
    isAmericanFootball: boolean;
    premierLeagueIframe: boolean;
}

const specialCompetitionSlug = 'premier-league';

const SportPageGeneral = observer('SportPageGeneral', (props: SportPageGeneralPropsType): JSX.Element | null => {
    const { sportId, headerStyleLevel, isAmericanFootball, premierLeagueIframe } = props;
    const optionItem = getHeaderOptions();
    const options = optionItem[sportId] ?? getDefaultHeaderOptions();

    if (sportId === 'football') {
        return (
            <section className='sport-general'>
                <SportHeaderStar
                    options={options}
                    sportId={sportId}
                    isCompetitionPage={false}
                    competitionId={null}
                />
                <EventsTableMobX
                    competitionId={null}
                    sportId={sportId}
                />
            </section>
        );
    } else if (sportId === specialCompetitionSlug && premierLeagueIframe) {
        return (
            <section className='sport-general'>
                <PaddingWrapper>
                    <PremierLeagueIframeWrapper>
                        <PremierLeagueIframe
                            title='premier-league-iframe'
                            src='https://statistics.rhino.bet/'
                        />
                    </PremierLeagueIframeWrapper>
                </PaddingWrapper>
            </section>
        );
    } else {
        return (
            <section className='sport-general'>
                <SportHeaderStar
                    options={options}
                    sportId={sportId}
                    isCompetitionPage={false}
                    competitionId={null}
                />
                <SportEventsList
                    key='sport-events-list'
                    sport={sportId}
                    headerStyleLevel={headerStyleLevel}
                    isAmericanFootball={isAmericanFootball}
                />
            </section>
        );
    }
});

const renderSportPageNavigation = (
    starRouter: StarRouter,
    currentView: SportRouteType,
    premierLeagueIframe: boolean
): React.ReactNode => {
    const hideCompetitions = currentView.id === specialCompetitionSlug && premierLeagueIframe;
    if (hideCompetitions) {
        return null;
    }
    return (
        <EventNavigation>
            <BackButtonWrapper onClick={starRouter.redirectToHomepage}>
                <BackButton position='left' />
            </BackButtonWrapper>
            <CarouselEventNavigation currentView={currentView} />
        </EventNavigation>
    );
};

interface SportPagePropsType {
    currentView: SportRouteType;
    accountView: RightHandSideViewType | null;
    headerStyleLevel: 'sportLevel' | 'competitionLevel';
    isSearchActive: boolean;
    isAmericanFootball: boolean;
}

export const SportPage = observer('SportPage', (props: SportPagePropsType) => {
    const {
        appLayoutsState: { starRouter, configComponents },
        appSportsBookState,
    } = useAppStateContext();
    const {
        config: { premierLeagueIframe },
    } = configComponents;
    const { currentView, accountView, headerStyleLevel, isSearchActive, isAmericanFootball } = props;

    if (currentView.nameType === 'races') {
        return (
            <SectionWrapper>
                <LayoutSkeleton
                    currentView={currentView}
                    accountView={accountView}
                    isSearchActive={isSearchActive}
                    navigationType='global'
                >
                    <Wrapper>
                        <NewRacingPage
                            key={currentView.id}
                            sport={currentView.id}
                            filterType={currentView.type}
                            eventId={appSportsBookState.models.id.getEventIdOption(currentView.event?.id ?? null)}
                        />
                    </Wrapper>
                </LayoutSkeleton>
            </SectionWrapper>
        );
    }

    return (
        <SectionWrapper>
            <LayoutSkeleton
                currentView={currentView}
                accountView={accountView}
                isSearchActive={isSearchActive}
                navigationType='global'
            >
                <Wrapper>
                    {renderSportPageNavigation(starRouter, currentView, premierLeagueIframe)}
                    <SportPageGeneral
                        sportId={currentView.id}
                        headerStyleLevel={headerStyleLevel}
                        isAmericanFootball={isAmericanFootball}
                        premierLeagueIframe={premierLeagueIframe}
                    />
                    <SeoPagesContent pageSlug={currentView.id} />
                </Wrapper>
            </LayoutSkeleton>
        </SectionWrapper>
    );
});
