import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import styled from '@emotion/styled';
import { CloseIcon } from 'src/domains/layouts/shared/assets/icons/CloseIcon';
import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import { css, SerializedStyles } from '@emotion/react';
import { HeaderMainLinks } from 'src/domains/layouts/webview/modules/layoutSkeleton/Header';
import { withConfig } from 'src/withConfig';

interface NavigationSidebarPropsType {
    isOpen: boolean;
}

interface SidebarSearchWrapperType {
    isSearchBarDesktopOpened: boolean;
}

export const SidebarSearchWrapper = withConfig(theme => styled('div', { label: 'SidebarSearchWrapper' })<SidebarSearchWrapperType>`
    background: ${theme.star.searchBar.bgColorQuaternary};
    padding: 4px 8px 4px 48px;
    position: relative;
    width: 100%;
    z-index: ${theme.star.zIndexLevelBase.baseUp};
    @media ${theme.star.mediaQuery.largeDesktop} {
        left: 0;
        padding: 0;
        position: absolute;
        top: 0;
        transition: width .2s ease;
        will-change: width;
        ${({ isSearchBarDesktopOpened }): string => isSearchBarDesktopOpened ? `
            width: calc(100vw - 280px);
            max-height: 348px;
    ` : ''};
    }
`);

type MainNavigationWrapperType = Pick<NavigationSidebarPropsType, 'isOpen'>;

export const MainNavigationWrapper = withConfig(theme => styled('aside', { label: 'MainNavigationWrapper' })<MainNavigationWrapperType>`
    -webkit-overflow-scrolling: touch;
    background: ${theme.star.lhsSidebar.bgColor};
    bottom: 0;
    height: auto;
    left: ${({ isOpen }): string => (isOpen ? '0' : '-100%')};
    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior-y: contain;

    padding: 0;
    position: fixed;
    top: 0;
    transition: left 0.2s ease;
    width: 100%;
    z-index: ${theme.star.zIndexLevelBase.hightLevel};
    @media ${theme.star.mediaQuery.mobile} {
        width: 80%;
    }
    @media ${theme.star.mediaQuery.largeDesktop} {
        background: ${theme.star.lhsSidebar.bgColorSecondary};
        bottom: initial;
        flex: 0 0 200px;
        left: initial;
        min-height: 100%;
        overflow: initial;
        padding: 40px 0 10px;
        position: relative;
        top: initial;
        transform: none;
        transition: none;
        width: 200px;
        z-index: ${theme.star.zIndexGlobal.above};
    }
`);


export const StickyNavigationWrapper = withConfig(theme => styled('div', { label: 'StickyNavigationWrapper' })`
    @media ${theme.star.mediaQuery.largeDesktop} {
        position: static;
    }
`);

export const HamburgerMenuTitle = withConfig(theme => styled('h2', { label: 'HamburgerMenuTitle' })`
    background-color: ${theme.star.headerMain.bgColor};
    color: ${theme.star.headerMain.txtColor};
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.5;
    margin: 0;
    padding: 12px 16px;
    text-transform: uppercase;
`);

export const HeaderMainLinksSidebarDefault = HeaderMainLinks;

export const StarEventsLink = withConfig(theme => styled(Link, { label: 'StarEventsLink' })`
    position: relative;
    color: ${theme.star.lhsNavigation.txtColor};
    display: block;
    font-size: 12px;
    line-height: 1.3;
    overflow: hidden;
    padding: 12px 10px 12px 0;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media ${theme.star.mediaQuery.largeDesktop} {
        color: ${theme.star.lhsNavigation.txtColorSecondary};
        margin-top: 8px;
        padding: 0;
        &:hover {
            color: ${theme.star.lhsNavigation.txtColorTertiary};
        }
    }
`);

export const NavigationSidebarContent = withConfig(theme => styled('div', { label: 'NavigationSidebarContent' })`
    overflow-y: auto;
    padding: 0 16px;
    @media ${theme.star.mediaQuery.largeDesktop} {
        overflow: visible;
    }
`);

const setHamburgerMenuCloseIcon = (): SerializedStyles => css`
    cursor: pointer;
    height: 48px;
    left: 0;
    padding: 14px;
    position: absolute;
    top: 0;
    width: 48px;
`;

export const SidebarCloseIconWrapper = styled(CloseIcon, { label: 'SidebarCloseIconWrapper' })`
    ${(): SerializedStyles => setHamburgerMenuCloseIcon()}
    fill: currentcolor;
    left: initial;
    right: 0;
`;

export const SidebarSlideIconWrapper = withConfig(theme => styled(ChevronIcon, { label: 'SidebarSlideIconWrapper' })`
    ${(): SerializedStyles => setHamburgerMenuCloseIcon()}
    fill: ${theme.star.lhsSidebar.bgColorQuaternary};
`);

export const HelpSectionWrapper = styled('div', { label: 'HelpSectionWrapper' })`
    margin-top: 8px;
`;
