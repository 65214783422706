import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { BreadcrumbsPanel } from 'src/domains/players/webview/components/SignUp/signupPrimary/signUpViews/breadcrumbs/breadcrumbsPanel/BreadcrumbsPanel';
import { SignupCloseIconWrapper } from 'src/domains/players/webview/components/SignUp/signupParts/Signup.style';
import { useAppStateContext } from 'src/appState/AppState';
import { AccountHelperInfo } from 'src/domains/players/webview/components/Account';
import { SignUpState } from 'src/domains/players/webview/components/SignUp/signupPrimary/signupState/SignUp.state';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';

interface LayoutSignUpPropsType {
    stepsCounter: boolean;
    footer?: boolean;
    closeIcon?: boolean;
    children: React.ReactElement;
    signUpState: SignUpState;
}

export const LayoutSignUp = observer('LayoutSignUp', (props: LayoutSignUpPropsType) => {
    const { stepsCounter, footer, closeIcon, children, signUpState } = props;
    const { common } = useAppStateContext();
    const {
        config: { signupPrivacyPolicyLink },
    } = ConfigComponents.get(common);

    const { step } = signUpState.currentView;
    const isWelcomePage = step.type === 'create-account' && step.state.currentStage === 'welcome';

    return (
        <>
            {stepsCounter ? <BreadcrumbsPanel signUpState={signUpState} /> : null}
            {closeIcon !== undefined && closeIcon === false ? null : (
                <SignupCloseIconWrapper
                    onClick={signUpState.toggleAside}
                    isWelcomePage={isWelcomePage}
                />
            )}
            {children}
            {footer === undefined ? null : (
                <AccountHelperInfo
                    version='secondary'
                    privacyPolicy={signupPrivacyPolicyLink}
                />
            )}
        </>
    );
});
