import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { sortBy, groupBy } from 'lodash';
import { Selection } from 'src/domains/sportsbook/webview/components/Selection';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { ExpandButton } from 'src/domains/sportsbook/webview/components/expandButton/ExpandButton';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { EventId, MarketId } from 'src_common/common/websocket2/id/WebsocketId';
import { sortSelectionsByCriteria } from 'src_common/utils/sport/sort';
import {
    Empty,
    MarketLoader,
    MarketLoaderWrapper,
    SelectionGroupListVersion,
    SelectionsGroup,
    SelectionsGroupLiItem,
    SelectionsGroupList,
    SelectionsGroupName,
    SelectionsGroupWrap,
} from 'src/domains/sportsbook/webview/components/selectionsGroup/SelectionGroup.style';

const SELECTION_ORDER: Record<string, Array<string>> = {
    'race-to': ['A', 'N', 'H'],
    'both-teams-to-score': ['N', 'Y'],
    'away-to-win-to-nil': ['N', 'Y'],
    'home-to-win-to-nil': ['N', 'Y'],
    'home-clean-sheet': ['N', 'Y'],
    'away-clean-sheet': ['N', 'Y'],
    'red-card-in-match': ['N', 'Y'],
    overtime: ['N', 'Y'],
    'will-runs-be-scored-by-first-ball': ['N', 'Y'],
    'first-over-runs-odd-even': ['E', 'O'],
    'double-chance': ['H-A', 'A-D', 'H-D'],
    'time-of-1st-goal-over-under': ['0-30', 'NG', '31-90'],
    'time-of-2nd-goal-over-under': ['0-50', 'NG', '51-90'],
    'time-of-3rd-goal-over-under': ['0-70', 'NG', '71-90'],
    'fight-end': ['D', 'KO', 'PNTS'],
    'total-frames': ['U', 'Ext', 'O'],
    default: ['A', 'D', 'H'],
};

const MULTIPLES = [
    'correct-score',
    'half-time-full-time',
    'half-time-correct-score',
    'match-result-and-both-to-score',
    'match-result-and-goals-over-under',
    /^set-correct-score-[1-9]$/,
];

const ONE_COLUMN = ['match-goals', /^time-of-[1-9][a-z]{2}-goal$/];

const COLUMNS: Record<string, number> = {
    'one-column': 1,
    'two-column': 2,
    'three-column': 3,
};

const test = (market: string): ((id: string | RegExp) => boolean) => {
    return (id: string | RegExp): boolean => {
        if (id instanceof RegExp) {
            return id.test(market);
        } else {
            return id === market;
        }
    };
};

const getTemplateId = (selection: SelectionModel): string => {
    const marketTemplateId = selection.templateMarketId;
    // Get market display template and if is 1-3 column then create a single group
    const marketDisplayTemplate = selection.marketDisplayTemplate;
    // Use selection template when no identifier was found
    let templateId = selection.identifier ?? selection.templateId;
    // Create selections groups based on what display template was specified
    // otherwise fallback to default behavior
    if (templateId === undefined) {
        return '';
    }

    if (marketDisplayTemplate === undefined) {
        return templateId;
    }

    if (marketDisplayTemplate !== '' && Object.keys(COLUMNS).includes(marketDisplayTemplate)) {
        return 'single';
    }

    if (marketDisplayTemplate !== '' && marketDisplayTemplate !== '-') {
        return templateId.charAt(0);
    }

    if (templateId === 'T' || templateId === '??') {
        //TODO: temporary solution for anyOtherScore templateId ='??' -> should be fixed on the BE
        templateId = 'D';
    }

    if (marketTemplateId === undefined) {
        return templateId;
    }
    if (templateId === 'unnamed-favourite' && marketTemplateId !== 'race-winner') {
        return 'player';
    } // TODO: remove after new-trading release -> temporary solution for old trading - New Trading will Fix That

    if (marketTemplateId !== '' && MULTIPLES.some(test(marketTemplateId))) {
        return templateId.charAt(0);
    } else if (marketTemplateId !== '' && ONE_COLUMN.some(test(marketTemplateId))) {
        return 'a';
    } else {
        return templateId;
    }
};

const getOrder = (selection: SelectionModel): number => {
    const marketTemplateId = selection.templateMarketId;
    if (marketTemplateId === undefined) {
        return 0; //recordGet(SELECTION_ORDER, 'default'); in case of problems uncomment
    }

    const list = SELECTION_ORDER[marketTemplateId] ?? SELECTION_ORDER.default ?? [];
    return list.indexOf(getTemplateId(selection));
};
//eslint-disable-next-line
const groupSelections = (selections: Array<SelectionModel>) => {
    let selectionsArrayNew: SelectionModel[] = [];
    for (const selection of selections) {
        selectionsArrayNew.push(selection);

        selectionsArrayNew.filter(
            (sel: SelectionModel) =>
                !(
                    sel.marketDisplayTemplate !== undefined &&
                    //eslint-disable-next-line
                    sel.marketDisplayTemplate.match(/home-away/) &&
                    sel.identifier !== undefined &&
                    //eslint-disable-next-line
                    sel.identifier.match(/D/gi)
                )
        );

        selectionsArrayNew = sortBy(selectionsArrayNew, (selection) => selection.displayOrder);
        selectionsArrayNew = sortBy(
            selectionsArrayNew,
            (selection) => selection.marketDisplayOrder !== undefined && -selection.marketDisplayOrder
        );
        selectionsArrayNew = sortBy(selectionsArrayNew, (selection) => -getOrder(selection));
    }

    // // Group selections by template and keep the order of the groups found
    return groupBy(selectionsArrayNew, getTemplateId);
};

const selectionsByPropsNew = (selections: Record<string, SelectionModel[]>): SelectionModel[] => {
    const selectionsList: Record<number, SelectionModel[]> = {};

    for (const key of Object.keys(selections)) {
        const singleSelection = selections[key] ?? [];
        //@ts-expect-error
        selectionsList[key] = singleSelection.map((selection: SelectionModel | null) => {
            //eslint-disable-next-line
            return (selection = selection && selection.id2.getModel());
        });
    }

    const selectionsListValues = Object.values(selectionsList);
    return selectionsListValues[0] ?? [];
};

interface SelectionGroupPropsType {
    isHomepage?: boolean;
    eventId: EventId | null;
    marketIdMain: MarketId;
    selections: Record<string, SelectionModel[]>;
}

export const SelectionGroup = observer('SelectionGroup', (props: SelectionGroupPropsType) => {
    const [isExpanded, setIsExpanded] = React.useState(false);
    const appState = useAppStateContext();

    const getSelectionsGroup = (
        selections: SelectionModel[],
        limitRowToDisplay: number,
        key: string
    ): JSX.Element | null => {
        const selectionsToRender = isExpanded === true ? selections : selections.slice(0, limitRowToDisplay);
        const { eventId } = props;
        const { appLayoutsState } = appState;
        const { languagesState } = appLayoutsState;
        const eventModel = eventId === null ? null : eventId.getEventModel();

        if (eventModel === null) {
            return null;
        }

        return (
            <React.Fragment key={key}>
                {selectionsToRender.map((selection) => {
                    return (
                        <SelectionsGroupList key={selection.id}>
                            <SelectionsGroupLiItem key={selection.id}>
                                <SelectionsGroupName title={languagesState.getSelectionTranslationName(selection)}>
                                    {languagesState.getSelectionTranslationName(selection)}
                                </SelectionsGroupName>

                                <Selection
                                    borderBottom={true}
                                    borderLeft={true}
                                    borderRight={true}
                                    selectionId={selection.id2}
                                />
                            </SelectionsGroupLiItem>
                        </SelectionsGroupList>
                    );
                })}
            </React.Fragment>
        );
    };

    const getLargeSelectionsGroup = (
        selections: SelectionModel[],
        limitRowToDisplay: number,
        displayTemplate: string | undefined
    ): JSX.Element | null => {
        const selectionsToRender = isExpanded ? selections : selections.slice(0, limitRowToDisplay);

        const { eventId } = props;
        const { appLayoutsState } = appState;
        const { languagesState } = appLayoutsState;
        const eventModel = eventId === null ? null : eventId.getEventModel();

        if (eventModel === null) {
            return null;
        }

        return (
            <>
                {selectionsToRender.map((selection) => {
                    return (
                        <SelectionsGroupLiItem
                            key={selection.id}
                            isTwoColumn={displayTemplate === 'two-column'}
                        >
                            <SelectionsGroupName title={languagesState.getSelectionTranslationName(selection)}>
                                {languagesState.getSelectionTranslationName(selection)}
                            </SelectionsGroupName>

                            <Selection
                                borderBottom={true}
                                borderLeft={true}
                                borderRight={true}
                                selectionId={selection.id2}
                            />
                        </SelectionsGroupLiItem>
                    );
                })}
            </>
        );
    };

    const toggleExpand = (e: React.MouseEvent): void => {
        e.preventDefault();
        setIsExpanded(!isExpanded);
    };

    const limitRowToDisplay = 4;
    const marketIdMain = props.marketIdMain;
    const eventMarket: MarketModel | null = marketIdMain.getModel();
    const selections = selectionsByPropsNew(props.selections);
    const newAllSelectionsProps = groupSelections(selections);
    const selectionSize = selections.length;
    const { isHomepage } = props;
    let limitDisplayBasedOnColumns;
    const newAllSelectionsPropsValuesList = Object.values(newAllSelectionsProps);
    const newAllSelectionsPropsListValuesItem = newAllSelectionsPropsValuesList[0];
    if (newAllSelectionsPropsListValuesItem !== undefined) {
        const selectionItem = newAllSelectionsPropsListValuesItem[0];
        if (selectionItem?.marketDisplayTemplate === 'one-column') {
            limitDisplayBasedOnColumns = newAllSelectionsPropsListValuesItem.length;
        }
        if (selectionItem?.marketDisplayTemplate === 'two-column') {
            limitDisplayBasedOnColumns = newAllSelectionsPropsListValuesItem.length / 2;
        }
        if (selectionItem?.marketDisplayTemplate === 'three-column') {
            limitDisplayBasedOnColumns = newAllSelectionsPropsListValuesItem.length / 3;
        }

        limitDisplayBasedOnColumns = newAllSelectionsPropsListValuesItem.length;
    }

    // Get group with most selections used for expandButton
    // if there are more then the limit to display
    const newAllSelectionsPropsKeysList = Object.keys(newAllSelectionsProps);
    const newAllSelectionsPropsListKeysItem = newAllSelectionsPropsKeysList[0];
    const biggestGroup =
        newAllSelectionsPropsListKeysItem === 'single'
            ? limitDisplayBasedOnColumns
            : newAllSelectionsPropsValuesList.reduce(
                  (biggest: number, items: SelectionModel[]) => (biggest < items.length ? items.length : biggest),
                  0
              );

    const sectionClass = `selections-group ${Object.keys(newAllSelectionsProps).length > 3 ? 'selections-group--multiple' : ''}`;

    const selectionsToRender = Object.entries(newAllSelectionsProps);
    const isLoading = eventMarket === null;

    const itShouldDisplayExpandButton = (): boolean => {
        if (biggestGroup !== undefined) {
            if (newAllSelectionsPropsListValuesItem !== undefined) {
                const displayTemplate = newAllSelectionsPropsListValuesItem[0]?.marketDisplayTemplate;

                if (displayTemplate !== undefined) {
                    const template = COLUMNS[displayTemplate];
                    if (template !== undefined) {
                        if (newAllSelectionsPropsListKeysItem === 'single') {
                            return biggestGroup / template > limitRowToDisplay;
                        }
                    }
                }
            }
            return biggestGroup > limitRowToDisplay;
        }
        return false;
    };

    return (
        <SelectionsGroupWrap>
            <SelectionsGroup className={sectionClass}>
                <Empty
                    className='empty'
                    selectionsCount={selectionsToRender.length}
                >
                    {selectionsToRender.length === 0 ? (
                        <MarketLoaderWrapper>{isLoading ? <MarketLoader version='dark' /> : null}</MarketLoaderWrapper>
                    ) : (
                        selectionsToRender.map(([key, itemsIn]: [string, SelectionModel[]]) => {
                            const selection = itemsIn[0];
                            if (selection !== undefined) {
                                const displayTemplate = selection.marketDisplayTemplate;
                                const displayOrder = selection.marketSelectionOrdering;
                                const order =
                                    displayOrder !== undefined && displayOrder !== '-'
                                        ? displayOrder
                                        : 'by-display-order';
                                // Sort selections withing a group
                                const items = sortSelectionsByCriteria(itemsIn, order);

                                if (selectionSize > 0 && selectionSize < limitRowToDisplay) {
                                    return getSelectionsGroup(items, limitRowToDisplay, key);
                                } else {
                                    // Force [n] columns if it's only one template
                                    const columns = displayTemplate === undefined ? 1 : COLUMNS[displayTemplate] ?? 1;
                                    const columnRows =
                                        eventMarket !== null && isExpanded
                                            ? selectionSize
                                            : limitRowToDisplay * columns;

                                    return (
                                        <SelectionGroupListVersion
                                            templateName={displayTemplate}
                                            key={key}
                                            className={`test-${order}`}
                                        >
                                            {getLargeSelectionsGroup(items, columnRows, displayTemplate)}
                                        </SelectionGroupListVersion>
                                    );
                                }
                            }
                        })
                    )}
                </Empty>
            </SelectionsGroup>
            {itShouldDisplayExpandButton() === true ? (
                <ExpandButton
                    isExpanded={isExpanded}
                    toggleExpand={toggleExpand}
                    isHomepage={isHomepage}
                />
            ) : undefined}
        </SelectionsGroupWrap>
    );
});
