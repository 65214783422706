import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface PropsType {
    className?: string;
    onClick?: () => void;
}

export const SortIcon = observer(
    'SortIcon',
    ({ className, onClick }: PropsType): JSX.Element => (
        <SvgElement
            className={className}
            onClick={onClick}
            width='32'
            height='32'
            viewBox='0 0 32 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M5.33398 22.6665H13.334'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M5.33398 16H17.334'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M24 14.6665L24 25.3332'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M28 21.3335L24 25.3335L20 21.3335'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M5.33398 9.3335H21.334'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </SvgElement>
    )
);
