import { computed, observable, action, makeObservable } from 'mobx';
import { CashOutItemType } from 'src/api/config/betting/cashOutsDataDecode';
// eslint-disable-next-line
import { apiCommon } from 'src/api/ApiCommon';
import { RetrieveCashOutErrorModelType } from 'src/api/config/betting/retrieveCashOutDecode';
import { BetLegType, BetListType } from 'src/domains/sportsbook/betting/state/BetsListState';

import { OpenapiProxyCustomerAllBetsResponse200Type as AllBetsType } from 'src/api_openapi/generated/openapi_proxy_customer_all_bets';

type BetHistoryType = AllBetsType['results'] extends Array<infer Model> ? Model : never;

import { TypeFromArray } from 'src_common/common/type_from_array';
import { Amount } from 'src_common/common/amount/Amount';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { Common } from 'src/domains/common/Common';
import { BasicDataModel } from 'src/domains/players/state/BasicDataModel';

type BetsBonusesApiType = TypeFromArray<BetHistoryType['transaction']['tags']['bonuses']>;
type BetsBonusesType = {
    amount: Amount;
    id: number;
    type?: string | null | undefined;
};

type BetsFreeBetsType = TypeFromArray<BetHistoryType['transaction']['tags']['freebetCredits']>;

const convertBetsBonuses = (configComponents: ConfigComponents, item: BetsBonusesApiType): BetsBonusesType => ({
    amount: configComponents.precision.newFromAnything(item.amount),
    id: item.id,
    type: item.type,
});

const convertAmountInMessage = (
    msg: string,
    moneyFn: (amount: Amount | null | undefined, withoutZeros?: boolean) => string
): string => {
    return msg
        .split(' ')
        .map((elem) => {
            if (isNaN(parseInt(elem))) {
                return elem;
            } else {
                const amount = new Amount((parseInt(elem) / 100).toString());
                return moneyFn(amount);
            }
        })
        .join(' ');
};

export class SingleBetItemState {
    public readonly getBetById: () => BetListType | null;
    public readonly getCashOutById: () => CashOutItemType | null;

    @observable private isSelectionOpenInner: boolean;
    @observable private isConfirmInner: boolean;
    @observable private isShowSuccessCashOutInner: boolean;
    @observable private isCshOutLoadingInner: boolean;
    @observable private cashOutErrorsInner: RetrieveCashOutErrorModelType | null;

    public constructor(
        private readonly configComponents: ConfigComponents,
        private readonly common: Common,
        getBetById: () => BetListType | null,
        getCashOutById: () => CashOutItemType | null
    ) {
        makeObservable(this);
        this.getBetById = getBetById;
        this.getCashOutById = getCashOutById;
        this.isSelectionOpenInner = true;
        this.isConfirmInner = false;
        this.isShowSuccessCashOutInner = false;
        this.isCshOutLoadingInner = false;
        this.cashOutErrorsInner = null;
    }

    @computed
    public get bet(): BetListType | null {
        return this.getBetById();
    }

    @computed
    public get id(): number | null {
        return this.bet?.id ?? null;
    }

    @computed
    public get type(): string | null {
        return this.bet?.type ?? null;
    }

    @computed
    public get cashOutItem(): CashOutItemType | null {
        return this.getCashOutById();
    }

    @computed
    public get legs(): Array<BetLegType> {
        return this.bet?.legs ?? [];
    }

    @computed
    public get freeBetCredits(): Array<BetsFreeBetsType> {
        return this.bet?.transaction.tags.freebetCredits ?? [];
    }

    @computed
    public get bonuses(): Array<BetsBonusesType> {
        return (this.bet?.transaction.tags.bonuses ?? []).map((item) =>
            convertBetsBonuses(this.configComponents, item)
        );
    }

    @computed
    public get payout(): Amount | null {
        const payout = this.bet?.payout ?? null;
        return payout === null ? null : this.configComponents.precision.newFromAnything(payout);
    }

    @computed
    public get potentialReturns(): Amount | null {
        const potentialReturns = this.bet?.potentialReturns ?? null;
        return potentialReturns === null ? null : this.configComponents.precision.newFromAnything(potentialReturns);
    }

    @computed
    public get totalStake(): Amount | null {
        const totalStake = this.bet?.totalStake ?? null;
        return totalStake === null ? null : this.configComponents.precision.newFromAnything(totalStake);
    }

    @computed
    public get status(): string {
        return this.bet?.status ?? '';
    }

    @computed
    public get isAnyBP(): boolean {
        return this.legs.some((leg) => leg.priceType === 'bp');
    }

    @computed
    public get bogBonus(): BetsBonusesType | null {
        return this.bonuses.find((bonus) => bonus.type === 'best-odds-guaranteed') ?? null;
    }

    @computed
    public get resultTypes(): Array<string> {
        return this.legs.map((leg) => leg.result?.type ?? '');
    }

    @computed
    public get isAllTypeVoid(): boolean {
        return this.resultTypes.every((val) => val === 'void');
    }

    @computed
    public get cashOut(): boolean {
        return this.bet?.cashOut ?? false;
    }

    @computed
    public get isConfirm(): boolean {
        return this.isConfirmInner;
    }

    @computed
    public get isLoadingCashOut(): boolean {
        return this.isCshOutLoadingInner;
    }

    @computed
    public get isShowSuccessCashOut(): boolean {
        return this.isShowSuccessCashOutInner;
    }

    @action public onConfirmCashOut = (): void => {
        if (this.isConfirmInner === false) {
            setTimeout(() => {
                this.isConfirmInner = false;
            }, 3000);
        }

        this.isConfirmInner = true;
    };

    @action public onRetrieveCashOut = async (): Promise<void> => {
        const betId = this.bet?.id ?? null;
        const value = this.cashOutItem?.value ?? null;

        if (betId !== null && value !== null && this.isCshOutLoadingInner === false) {
            this.isCshOutLoadingInner = true;

            const resp = await apiCommon.retrieveCashOut.run({
                betId: betId,
                value: value,
            });

            this.isCshOutLoadingInner = false;

            if (resp.responseType === 'success') {
                this.isShowSuccessCashOutInner = true;
                setTimeout(() => {
                    this.isShowSuccessCashOutInner = false;
                }, 3000);
            }

            if (resp.responseType === 'error') {
                this.cashOutErrorsInner = resp.json;
            }
        }
    };

    @computed public get cashOutErrors(): Array<string> {
        if (this.cashOutErrorsInner === null) {
            return [];
        }

        if (typeof this.cashOutErrorsInner.message === 'string' && this.cashOutErrorsInner.errors.length === 1) {
            const basicDataModel = BasicDataModel.get(this.common);
            return [convertAmountInMessage(this.cashOutErrorsInner.message, basicDataModel.money)];
        }

        return this.cashOutErrorsInner.errors.map((elem) => `${elem.resource}:${elem.code}`);
    }

    @action public toggleSelectionOpen = (): void => {
        this.isSelectionOpenInner = !this.isSelectionOpenInner;
    };

    @computed
    public get isSelectionOpen(): boolean {
        return this.isSelectionOpenInner;
    }
}
