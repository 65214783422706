import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { DateInputState, DateInput } from 'src/domains/players/webview/components/form/DateInput';
import { DateInputWrapper, InputDescription } from './DateOfBirth.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';

interface PropsType {
    dateState: DateInputState;
}

export const DateOfBirth = observer('DateOfBirth', (props: PropsType) => {
    const { dateState } = props;

    return (
        <DateInputWrapper>
            <DateInput
                label={
                    <I18n
                        langKey='signup.your-details.birth-date'
                        defaultText='Date of birth'
                    />
                }
                state={dateState}
            />
            <InputDescription data-test='input-description'>
                <I18n
                    langKey='signup.age-restriction'
                    defaultText='Age restriction: It is an offence to gamble if you are under 18'
                />
            </InputDescription>
        </DateInputWrapper>
    );
});
