import { z } from 'zod';

export const InputSchema = z.object({
    cardId: z.string(),
});

export type DeleteCardPaysafeInputType = z.infer<typeof InputSchema>;

export const SuccessResponseSchema = z.object({
    data: z.object({}),
});

type TsuccessResponseType = z.infer<typeof SuccessResponseSchema>;

export const ErrorResponseSchema = z.object({
    errors: z.array(
        z.object({
            code: z.string().optional().nullable(),
            message: z.string().optional().nullable(),
        })
    ),
});

type TerrorResponseType = z.infer<typeof ErrorResponseSchema>;

export type TresponseType =
    | { responseStatus: 'success'; response: TsuccessResponseType }
    | { responseStatus: 'error'; data: TerrorResponseType };
