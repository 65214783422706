import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { SportTilesGrid } from 'src/domains/layouts/webview/components/sportTilesGrid/SportTilesGrid';
import {
    HomepageContentTabs,
    NextRacesHomePage,
    EventsInPlayOrUpcoming,
} from 'src/domains/sportsbook/shared/Components';
import { CasinoGamesOnHomepage, VirtualGamesOnHomepage } from 'src/domains/casino/shared/Components';
import { LayoutSkeleton } from 'src/domains/layouts/webview/modules/LayoutSkeleton';
import { HeroWidget } from 'src/domains/layouts/webview/components/heroEvents/HeroWidget';
import { PriceChangeLegend } from 'src/domains/layouts/webview/components/priceChangeLegend/PriceChangeLegend';
import { DesktopWrapper, GridWrapper, MainWrapper } from 'src/domains/layouts/layouts/nebet/homePage/HomePage.style';
import { CarouselPromo } from 'src/domains/layouts/webview/components/homePageCarouselPromos/carouselPromo/CarouselPromo';
import { HomeRouteType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { RightHandSideViewType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';
import { SeoPagesContent } from 'src/domains/layouts/webview/components/seoPages/SeoPages';
import { useCommon } from 'src/domains/common/Common';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';

const RenderHomePageGameLayout = observer('RenderHomePageGameLayout', () => {
    const { appCasinoState } = useAppStateContext();

    return (
        <>
            <CasinoGamesOnHomepage appCasinoState={appCasinoState} />
            <VirtualGamesOnHomepage appCasinoState={appCasinoState} />
        </>
    );
});

interface NeBetHomePagePropsType {
    currentView: HomeRouteType;
    headerStyleLevel: 'sportLevel' | 'competitionLevel';
    isSearchActive: boolean;
    accountView: RightHandSideViewType | null;
}

export const NeBetHomePage = observer(
    'HomePageLayout',
    ({ currentView, accountView, headerStyleLevel, isSearchActive }: NeBetHomePagePropsType) => {
        const common = useCommon();
        const { nextRacesNew } = ConfigComponents.get(common).config;
        const renderMobile = (
            <GridWrapper>
                <SportTilesGrid />
            </GridWrapper>
        );

        const renderDesktop = (
            <DesktopWrapper>
                <CarouselPromo modeType='homepage' />
                <HeroWidget />
                {nextRacesNew ? <NextRacesHomePage /> : <HomepageContentTabs />}
                <EventsInPlayOrUpcoming
                    showPriceLegend={false}
                    headerStyleLevel={headerStyleLevel}
                />
                <RenderHomePageGameLayout />
                <PriceChangeLegend isRace={false} />
                <SeoPagesContent pageSlug='homepage' />
            </DesktopWrapper>
        );

        return (
            <MainWrapper className='homepage'>
                <LayoutSkeleton
                    currentView={currentView}
                    accountView={accountView}
                    isSearchActive={isSearchActive}
                    navigationType='global'
                >
                    <>
                        {renderDesktop}
                        {renderMobile}
                    </>
                </LayoutSkeleton>
            </MainWrapper>
        );
    }
);
