import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { AppCasinoState } from 'src/domains/casino/state/AppCasino.state';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { GameInfoStatistic } from './sidebar-game-info/GameInfoStatistic';
import {
    HeartFilledIconWrapper,
    HeartOutlinedIconWrapper,
    FavouriteBar,
    GameImageWrapper,
    GameImgWrapperLazy,
    GameInfoBtnWrapper,
    GameInfoDescription,
    GameInfoStatisticsWrapper,
    GameInfoWrapper,
    LikeBtn,
    FavouriteBarOverlay,
    PlayBtn,
    PlayDemoBtn,
    LikeMessage,
    CheckIconWrapper,
} from './sidebar-game-info/SidebarGameInfo.style';
import {
    HeaderCloseIcon,
    HeaderWrapper,
    TitleWrapper,
} from 'src/domains/casino/webview/CasinoGames/SidebarGameInfo.style';
import { Amount } from 'src_common/common/amount/Amount';
import { useCommon } from 'src/domains/common/Common';
import { BasicDataModel } from 'src/domains/players/state/BasicDataModel';

interface SidebarGameInfoPropsType {
    appCasinoState: AppCasinoState;
}

export const SidebarGameInfo = observer('SidebarGameInfo', (props: SidebarGameInfoPropsType): JSX.Element | null => {
    const { appCasinoState } = props;
    const { gameStoreExt, casinoSidebarGameInfoState } = appCasinoState;
    const common = useCommon();
    const basicData = BasicDataModel.get(common);

    const casinoGameId = casinoSidebarGameInfoState.sidebarGameId ?? 0;
    const selectedGame = appCasinoState.gameStoreExt.getGameModel(casinoGameId)?.data;

    const gameName = selectedGame === null ? '' : selectedGame?.name;
    const gameId = selectedGame?.id ?? 0;
    const isDemo = selectedGame?.demo ?? false;
    const gameShortDesc = selectedGame?.description ?? '';
    const gameImgRectangle = selectedGame?.image ?? '';
    const gameImgSquare = selectedGame?.image_square ?? '';
    const gameImg = gameImgSquare === '' ? gameImgRectangle : gameImgSquare;

    const gameMinBet =
        selectedGame === null || selectedGame === undefined ? new Amount('0') : new Amount(selectedGame.min_bet);
    const gameMaxBet =
        selectedGame === null || selectedGame === undefined ? new Amount('0') : new Amount(selectedGame.max_bet);
    const gameMaxWinLines =
        selectedGame === null || selectedGame === undefined ? new Amount('0') : new Amount(selectedGame.max_win);
    const gameReturnToPlayer =
        selectedGame === null || selectedGame === undefined
            ? new Amount('0')
            : new Amount(selectedGame.return_to_player);

    const gameModel = gameStoreExt.getGameModel(gameId);

    if (gameModel === null) {
        return null;
    }

    const isFavourite = gameModel.isFavourite;
    const isAddedToFavouritesConfirmation = gameModel.isAddedToFavouritesConfirmation;

    const showFavouriteBar = (): JSX.Element => (
        <FavouriteBar>
            <FavouriteBarOverlay />
            {isAddedToFavouritesConfirmation ? (
                <LikeMessage data-test='game-added-to-favourites-info'>
                    <CheckIconWrapper />
                    <I18n
                        langKey='casino.games.game-added-to-favourites'
                        defaultText='Game added to your Favourites'
                    />
                </LikeMessage>
            ) : isFavourite ? (
                <LikeBtn
                    data-test='remove-game-from-favourites-button'
                    onClick={gameModel.handleLikeAction}
                >
                    <HeartFilledIconWrapper />
                    <I18n
                        langKey='casino.games.remove-game-from-favourites'
                        defaultText='Remove Game from Favourites'
                    />
                </LikeBtn>
            ) : (
                <LikeBtn
                    data-test='add-game-to-favourites-button'
                    onClick={gameModel.handleLikeAction}
                >
                    <HeartOutlinedIconWrapper />
                    <I18n
                        langKey='casino.games.add-game-to-favourites'
                        defaultText='Add Game to Favourites'
                    />
                </LikeBtn>
            )}
        </FavouriteBar>
    );

    const showActionButtons = (): JSX.Element => (
        <GameInfoBtnWrapper>
            <PlayBtn
                size='medium'
                onClick={(): void => gameModel.startGame()}
                dataTest='sidebar-play-button'
            >
                <I18n
                    langKey='casino.games.play-now'
                    defaultText='Play Now'
                />
            </PlayBtn>

            {isDemo ? (
                <PlayDemoBtn
                    dataTest='play-demo-button'
                    size='medium'
                    onClick={(): void => gameModel.startGame({ mode: 'demo' })}
                >
                    <I18n
                        langKey='casino.games.try-for-free'
                        defaultText='Try For Free'
                    />
                </PlayDemoBtn>
            ) : null}
        </GameInfoBtnWrapper>
    );

    return (
        <GameInfoWrapper>
            <HeaderWrapper>
                <TitleWrapper data-test='sidebar-game-title'>{gameName}</TitleWrapper>
                <HeaderCloseIcon onClick={appCasinoState.redirectState.redirectWithoutAccountParam} />
            </HeaderWrapper>

            <GameImageWrapper>
                <GameImgWrapperLazy
                    alt={`Image of game ${gameName ?? '.'}`}
                    imgFit='fill'
                    imgBase={{
                        src: gameImg,
                        optimizeOptions: {
                            width: 414,
                            quality: 85,
                        },
                    }}
                    placeholder={true}
                />
                {showFavouriteBar()}
            </GameImageWrapper>
            {showActionButtons()}

            {gameShortDesc === '' ? null : <GameInfoDescription>{gameShortDesc}</GameInfoDescription>}

            <GameInfoStatisticsWrapper>
                {gameMinBet.isGreaterThanZero() ? (
                    <GameInfoStatistic
                        value={basicData.money(gameMinBet, true)}
                        label='Min Bet'
                    />
                ) : null}

                {gameMaxBet.isGreaterThanZero() ? (
                    <GameInfoStatistic
                        value={basicData.money(gameMaxBet, true)}
                        label='Max Bet'
                    />
                ) : null}
                {gameMaxWinLines.isGreaterThanZero() ? (
                    <GameInfoStatistic
                        value={basicData.money(gameMaxWinLines, true)}
                        label='Max Win'
                    />
                ) : null}
                {gameReturnToPlayer.isGreaterThanZero() ? (
                    <GameInfoStatistic
                        value={`${gameReturnToPlayer.value}%`}
                        label='RTP'
                    />
                ) : null}
            </GameInfoStatisticsWrapper>
        </GameInfoWrapper>
    );
});
