import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { DepositLimitUpdatePopupState } from 'src/domains/players/state/accountState/DepositLimitsUpdatePopups/DepositLimitUpdatePopup/DepositLimitUpdatePopup.state';
import {
    DepositLimitUpdateContainer,
    DepositLimitUpdateOverlay,
    PopupTitle,
    PopupParagraph,
    PopupParagraphBold,
    PopupParagraphSmall,
    BoxWrapper,
    BoxTitle,
    LinkWrapper,
    SubmitButton,
} from 'src/domains/players/state/accountState/DepositLimitsUpdatePopups/DepositLimitUpdatePopup/DepositLimitUpdatePopup.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useCommon } from 'src/domains/common/Common';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { UpdateLimitsComponent } from 'src/domains/players/state/accountState/DepositLimitsUpdatePopups/DepositLimitUpdatePopupWithInput/UpdateLimitsComponent/UpdateLimitsComponent';

export const PopupView = observer('PopupView', (): JSX.Element | null => {
    const common = useCommon();
    const configComponents = ConfigComponents.get(common);
    const {
        config: { accountHelperMail, operatorFullName },
    } = configComponents;

    const [state] = useState(() => new DepositLimitUpdatePopupState(common));

    return (
        <DepositLimitUpdateOverlay>
            <DepositLimitUpdateContainer version='tertiary'>
                <PopupTitle>Deposit Limit Update</PopupTitle>
                <PopupParagraph>Hi {state.userBasicData?.firstName},</PopupParagraph>
                <PopupParagraph>
                    We are improving our deposit limits to give our clients a better user experience and extra control
                    of their wagering. Gross Deposit Limits have been replaced by Net Deposit Limits.
                </PopupParagraph>
                <PopupParagraph>
                    Currently, Deposit Limits are calculated on the total amount deposited within a selected
                    calendar-based period.
                </PopupParagraph>
                <PopupParagraph>
                    With Net Deposit Limits, the amount is calculated on the total amount deposited minus the total
                    amount withdrawn within the selected calendar-based period.
                </PopupParagraph>
                <PopupParagraphBold>
                    Net Deposits = (total of all deposits - total of all withdrawals) within the selected period
                </PopupParagraphBold>
                <BoxWrapper>
                    <BoxTitle>Example</BoxTitle>
                    <PopupParagraphSmall>You set a net deposit limit of £100 per month.</PopupParagraphSmall>
                    <PopupParagraphSmall>You deposit £40</PopupParagraphSmall>
                    <PopupParagraphSmall>You can then deposit a further £60 (£100 - £40 = £60)</PopupParagraphSmall>
                    <PopupParagraphSmall>You subsequently withdraw £40. </PopupParagraphSmall>
                    <PopupParagraphSmall>
                        You can then deposit a further £100 (£100 - £40 + £40 = £100)
                    </PopupParagraphSmall>
                </BoxWrapper>
                <PopupParagraph>
                    We recommend you review your limits and consider whether they are at the right levels for you.
                </PopupParagraph>
                <PopupParagraph>
                    If you understand our new improved net deposit limits and are happy with your current limits, shown
                    below, you can click the Accept button and continue to safely enjoy everything {operatorFullName}{' '}
                    has to offer.
                </PopupParagraph>
                <UpdateLimitsComponent configComponents={configComponents} />
                <PopupParagraph>
                    If you do not understand the new deposit limit system please do not click the Accept button, but{' '}
                    <LinkWrapper to={`mailto:${accountHelperMail}`}>
                        <I18n
                            langKey='account.deposit-update.contact-us.email'
                            defaultText='contact us'
                            params={{ accountHelperMail }}
                        />
                    </LinkWrapper>{' '}
                    for help.
                </PopupParagraph>
                <PopupParagraph>For more information, please visit our FAQ and Safer Gambling pages.</PopupParagraph>
                <SubmitButton
                    size='large'
                    onClick={state.onSubmitRequest}
                    isLoading={state.isLoading}
                    disabled={state.isLoading}
                >
                    <I18n
                        langKey='deposit-limit-update.button.accept'
                        defaultText='Accept'
                    />
                </SubmitButton>
            </DepositLimitUpdateContainer>
        </DepositLimitUpdateOverlay>
    );
});
