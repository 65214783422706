import styled from '@emotion/styled';
import { CloseIcon } from 'src/domains/layouts/shared/assets/icons/CloseIcon';
import { withConfig } from 'src/withConfig';

interface SignUpWrapperType {
    shouldBeMoved: boolean;
}

export const SignUpWrapperOld = withConfig(theme => styled('aside', { label: 'SignUpWrapperOld' })<SignUpWrapperType>`
    -webkit-overflow-scrolling: touch;
    background: ${theme.star.signUp.bgColor};
    color: ${theme.star.signUp.txtColor};
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    right: 0;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    scrollbar-color: rgba(2, 13, 22, 0.2) rgba(255, 255, 255, .2);
    scrollbar-width: thin;
    width: 100%;
    z-index: ${theme.star.zIndexGlobal.rhsMenu};
    &::-webkit-scrollbar {
        background-color: rgba(2, 13, 22, .2);
        width: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, .2);
        border-radius: 4px;
    }
    @media ${theme.star.mediaQuery.xMobile} {
        width: 375px;
    }
    @media ${theme.star.mediaQuery.desktop} {
        ${({ shouldBeMoved }): string => shouldBeMoved ? 'right: 280px;' : ''};
        height: inherit;
    }
`);

export const SignUpWrapper = withConfig(theme => styled('aside', { label: 'SignUpWrapper' })`
    -webkit-overflow-scrolling: touch;
    background: ${theme.star.signUp.bgColor};
    color: ${theme.star.signUp.txtColor};
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    scrollbar-color: rgba(2, 13, 22, 0.2) rgba(255, 255, 255, .2);
    scrollbar-width: thin;
    width: 100%;
    &::-webkit-scrollbar {
        background-color: rgba(2, 13, 22, .2);
        width: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, .2);
        border-radius: 4px;
    }
`);

export const SignupOverlay = withConfig(theme => styled('div', { label: 'SignupOverlay' })`
    background-color: ${theme.star.overlay.bgColor};
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: ${theme.star.zIndexGlobal.above};
`);

interface SignUpIconPropsType {
    isWelcomePage: boolean;
}

export const SignupCloseIconWrapper = styled(CloseIcon, { label: 'SignupCloseIconWrapper' })<SignUpIconPropsType>`
    cursor: pointer;
    fill: currentcolor;
    padding: 14px;
    position: absolute;
    right: 0;
    top: ${({ isWelcomePage }): string => isWelcomePage === true ? '0' : '16px'};
    width: 48px;
`;

export const PageSectionWrapper = withConfig(theme => styled('section', { label: 'PageSectionWrapper' })`
    color: ${theme.star.signUp.txtColor};
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 0%;
    padding: 0 16px;
`);
