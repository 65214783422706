import { autorun, computed, makeObservable } from 'mobx';
// eslint-disable-next-line
import { apiCommon } from 'src/api/ApiCommon';
import { AccountCashOutErrorModelType, CashOutMainDataModelType } from 'src/api/config/betting/cashOutsDataDecode';
import { ApiResponseWrapper } from 'src_common/common/mobx-utils/ApiResponseWrapper';
import { BetsListState, BetsListType, BetListType } from 'src/domains/sportsbook/betting/state/BetsListState';
import { OpenapiProxyCustomerAllBetsResponse200Type } from 'src/api_openapi/generated/openapi_proxy_customer_all_bets';
import { BasicDataModel } from 'src/domains/players/state/BasicDataModel';
import { Common } from 'src/domains/common/Common';
import { AutoWeakMap } from 'src_common/common/mobx-utils/AutoWeakMap';
import { EventId, MarketId, SelectionId } from 'src_common/common/websocket2/id/WebsocketId';

interface AllMarketsIdAndEventsIdType {
    markets: Array<MarketId>;
    events: Array<EventId>;
    selections: Array<SelectionId>;
}

export class CashoutDataState {
    public readonly data: ApiResponseWrapper<CashOutMainDataModelType | AccountCashOutErrorModelType | null>;
    private readonly betsListState: BetsListState;

    public static get = AutoWeakMap.create((common: Common) => new CashoutDataState(common));

    private constructor(private readonly common: Common) {
        makeObservable(this);
        this.betsListState = BetsListState.get(this.common);
        this.data = new ApiResponseWrapper(
            'CashoutData',
            null,
            async (): Promise<CashOutMainDataModelType | AccountCashOutErrorModelType> => {
                return await apiCommon.cashOutsData.run({
                    betsIds: this.getBetsIdsForCashOut,
                });
            },
            (refresh) => {
                const dispose = autorun(() => {
                    const all = this.allMarketsIdAndEventsId;
                    for (const selectionId of all.selections) {
                        const selection = selectionId.getModel();
                        const price = selection?.price;
                        // Do not remove the following line. It is needed for the price to be updated.
                        console.trace('Subscription on the price', price);
                    }

                    refresh();
                });

                window.addEventListener('online', () => refresh);

                return (): void => {
                    dispose();
                    window.removeEventListener('online', refresh);
                };
            }
        );
    }

    @computed private get allBetsResource(): OpenapiProxyCustomerAllBetsResponse200Type | null {
        const basicData = BasicDataModel.get(this.common).basicData;
        if (basicData.valueReady !== null) {
            return this.betsListState.list;
        }
        return null;
    }

    @computed private get betsList(): BetsListType {
        if (this.allBetsResource !== null) {
            return this.allBetsResource.results;
        }
        return [];
    }

    @computed private get getBetsIdsForCashOut(): Array<number> {
        const bets = this.betsList.filter(
            (bet) =>
                bet.transaction.tags.freebetCredits.length === 0 && (bet.status === 'open' || bet.status === 'parked')
        );
        return bets.map((elem) => elem.id);
    }

    @computed private get betsListMap(): Map<number, BetListType> {
        const betsListMap: Map<number, BetListType> = new Map();

        for (const bet of this.betsList) {
            betsListMap.set(bet.id, bet);
        }

        return betsListMap;
    }

    private getBetById = (id: number): BetListType | null => {
        const bet = this.betsListMap.get(id);
        return bet ?? null;
    };

    @computed.struct private get allMarketsIdAndEventsId(): AllMarketsIdAndEventsIdType {
        const markets: Array<MarketId> = [];
        const events: Array<EventId> = [];
        const selections: Array<SelectionId> = [];

        for (const betId of this.getBetsIdsForCashOut) {
            const bet = this.getBetById(betId);

            if (bet !== null) {
                for (const leg of bet.legs) {
                    const marketId = leg.market?.id ?? null;
                    const eventId = leg.event.id ?? null;
                    const selectionId = leg.selection?.id ?? null;

                    if (selectionId !== null && eventId !== null && marketId !== null) {
                        const selectionIdV2 = this.common.models.id.getSelectionId(
                            parseInt(eventId),
                            marketId,
                            selectionId
                        );
                        selections.push(selectionIdV2);
                    }

                    if (marketId !== null && eventId !== null) {
                        const marketIdV2 = this.common.models.id.getMarketId(parseInt(eventId), marketId);
                        markets.push(marketIdV2);
                    }

                    if (eventId !== null) {
                        const eventIdV2 = this.common.models.id.getEventId(parseInt(eventId));
                        events.push(eventIdV2);
                    }
                }
            }
        }

        return {
            markets,
            events,
            selections,
        };
    }

    public get(): CashOutMainDataModelType | AccountCashOutErrorModelType | null {
        return this.data.data;
    }
}
