import { computed, makeObservable } from 'mobx';
import { Resource } from 'src_common/common/mobx-utils/Resource';
// TODO: extract method to trpc client
// eslint-disable-next-line
import { apiCommon } from 'src/api/ApiCommon';
import { StreamModelType } from 'src/api/config/streaming/checkBetForEvent';
import { StreamingState } from 'src/domains/sportsbook/state/streamingState/StreamingState';
import { StreamType } from 'src_common/common/websocket2/modelsApi/Event';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { AccountState } from 'src/domains/players/shared/Types';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { BetsState } from 'src/domains/sportsbook/betting/state/BetsState';
import { Common } from 'src/domains/common/Common';
import { SdkCustomer } from 'src/domains/layouts/state/customer';

const intentionallyUnusedVariable = <T>(_variable: T): void => {};
export class RaceStreamButtonState {
    public constructor(
        private readonly event: EventModel,
        private readonly streamingState: StreamingState,
        private readonly language: LanguagesState,
        private readonly accountState: AccountState,
        private readonly betsState: BetsState,
        private readonly shouldPlaceBetToWatchStream: boolean,
        private readonly common: Common
    ) {
        makeObservable(this);
    }

    @computed public get streamResponse(): Resource<StreamModelType> {
        //this computed depends only on the eventId and the number of bets
        const _bets = this.betsState.betsList.length;
        intentionallyUnusedVariable(_bets);
        const eventId = this.event.id;

        return new Resource(() => {
            return apiCommon.checkBetForEvent.run({ eventId });
        });
    }

    @computed public get stream(): StreamType | null {
        return this.streamingState.getModel(this.event.id).stream[0] ?? null;
    }

    @computed public get isAllowedForIreland(): boolean {
        if (this.event.tagsCountry === 'IE') {
            const response = this.streamResponse.get();
            if (response.type === 'ready') {
                if (!this.shouldPlaceBetToWatchStream) {
                    return true;
                }
                if (response.value.stake >= 100) {
                    return true;
                }
            }
            return false;
        }
        return true;
    }

    @computed public get isAllowedForUkForSis(): boolean {
        if (
            this.event.tagsCountry === 'GB' &&
            this.stream?.provider === 'SIS' &&
            this.event.sport === 'greyhoundracing'
        ) {
            const response = this.streamResponse.get();
            if (response.type === 'ready') {
                if (!this.shouldPlaceBetToWatchStream) {
                    return true;
                }

                if (response.value.stake >= 100) {
                    return true;
                }
            }
            return false;
        }
        return true;
    }

    @computed public get buttonText(): string {
        const loginToWatchText = this.language.getTranslation('eventStream.loginToWatch', 'Login to Watch Live');
        const watchingText = this.language.getTranslation('eventStream.watching', 'Watching');
        const watchLiveText = this.language.getTranslation('eventStream.watchLive', 'Watch Live');
        const watchLiveShortlyBefore = this.language.getTranslation(
            'eventStream.watchShortlyBefore',
            'Watch Live Shortly Before the Jump'
        );
        const placeMinBet = this.language.getTranslation('eventStream.placeMinBet', 'Place min £1 bet to watch stream');
        const notAllowedInCountry = this.language.getTranslation(
            'eventStream.notAllowedInCountry',
            'Live Stream is not Available in Your Country'
        );
        const isAllowedInUserCountry = this.isAllowInCountry();

        if (this.accountState.account === null) {
            return loginToWatchText;
        }

        if (isAllowedInUserCountry === false) {
            return notAllowedInCountry;
        }

        const shouldBeWatchingText =
            this.streamingState.openEventId !== null && this.streamingState.openEventId === this.event.id;

        const isPlacedMinBet = this.isAllowedForIreland && this.isAllowedForUkForSis;

        const isLive = this.stream?.live;
        if (isPlacedMinBet === false && this.shouldPlaceBetToWatchStream === true) {
            return placeMinBet;
        }

        if (isLive === true) {
            return shouldBeWatchingText ? watchingText : watchLiveText;
        }

        return watchLiveShortlyBefore;
    }

    @computed public get isDisabled(): boolean {
        return (
            this.accountState.account === null ||
            this.streamingState.getModel(this.event.id).stream.length === 0 ||
            this.stream?.live === false ||
            this.isAllowedForIreland === false ||
            this.isAllowedForUkForSis === false ||
            this.isAllowInCountry() === false
        );
    }

    @computed public get onClickHandler(): (() => void) | undefined {
        return this.isDisabled || this.streamingState.openEventId === this.event.id
            ? undefined
            : (): void => this.streamingState.openStream(this.event.id);
    }

    public isAllowInCountry = (): boolean => {
        const currentUserCountry = SdkCustomer.get(this.common).session.userIpCountry;
        if (this.stream === null || currentUserCountry === null) {
            return false;
        }

        if (this.accountState.account !== null && currentUserCountry === 'AA') {
            return true;
        }
        const streamRuleType = this.stream.geoRuleType;
        const streamCountryList = this.stream.geoRuleCountries.split(' ');

        if (streamRuleType === 'ALLOW') {
            if (streamCountryList.includes(currentUserCountry)) {
                return true;
            }
        }

        if (streamRuleType === 'DENY') {
            if (streamCountryList.includes(currentUserCountry) === false) {
                return true;
            }
        }

        return false;
    };
}
